import { Pipe, PipeTransform } from '@angular/core';
/*
 * Truncate a string to a number of characters with an ellipse at the end.
 * Takes a length argument that defaults to 25.
 * Usage:
 *   value:string | truncate:number
 * Example:
 *   {{ The quick brown fox jumped over the lazy dog | truncate:13 }}
 *   formats to: "The quick bro…"
*/
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length = 25): string {
    if (Boolean(value)) {
      const v = String(value);
      return v.length - 1 > length ? `${v.substring(0, length).trim()}…` : v.toString();
    }
  }
}
