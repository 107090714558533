import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const NUMERIC_REGEX = /^\s*(\d|\.\d)[\d,\.]*\s*$/;
const ALL_COMMA_SPACE_QUOTE = /[, ']+/g;
@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})

export class OptionsComponent implements OnInit {
  @Input() optionData: any;
  @Output() valueChange = new EventEmitter();

  constructor(public el: ElementRef, private toastr: ToastrService) { }
  ngOnInit() {
    console.log('optionData', this.optionData);
  }

  checkboxClick($event) {
    this.optionData.value = $event ? 1 : 0; // Collapse the boolean to a number
    this.valueChange.emit(this.optionData);
  }

  numericalUpdate() {
    if (NUMERIC_REGEX.test(this.optionData.value)) {
      if (typeof this.optionData?.value === 'string') {
        // Remove any commas or spaces
        this.optionData.value = this.optionData.value.replace(ALL_COMMA_SPACE_QUOTE, '');

        // Keep only the least significant decimal point
        const numberParts = this.optionData.value.split('.');
        if (numberParts?.length > 2) {
          const decimalValue = numberParts.pop();
          this.optionData.value = numberParts.join('').concat('.', decimalValue);
        }
      }

      console.log('Numeric', this.optionData);
      this.valueChange.emit(this.optionData);
    } else {
      this.toastr.error('Please enter valid numeric value', 'Error', {
        disableTimeOut: true,
        closeButton: true
      });
    }
  }

  quantityUpdate() {
    if (this.optionData.quantity == null) {
      this.optionData.quantity = 0;
    }
    console.log('Numeric', this.optionData);
    this.valueChange.emit(this.optionData);
  }

  stringUpdate() {
    console.log(`String with ${this.optionData.textValue}`);
    this.valueChange.emit(this.optionData);
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
