import { Component, Input, OnInit } from '@angular/core';
import { ProductService, SaveDataRCresponse } from '@cpq-app/services/product.service';
import { environment } from '@cpq-environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VisualizeService } from '../visualize/visualize.service';

const SHARE_3D_LINK = environment.B2CConfigs.redirectUrl + '/shared-cdsview/';

export enum ModalEvents {
  On3DShareLinkActivate = 'On3DShareLinkActivate',
  On3DShareLinkDeActivate = 'On3DShareLinkDeActivate'
}
@Component({
  selector: 'app-share3d',
  templateUrl: './share3d.component.html',
  styleUrls: ['./share3d.component.scss']
})
export class Share3dComponent implements OnInit {
  @Input() data: SaveDataRCresponse;
  shareLink: string;
  isShare3DLinkActive = true;
  constructor(public modal: NgbActiveModal,
    private toastr: ToastrService,
    private visualizeService: VisualizeService
  ) { }

  ngOnInit(): void {
  }

  copy3dLink() {
    navigator.clipboard.writeText(this.shareLink).then(() => {
      this.toastr.success('Value copied to clipboard');
    });
  }

  generate3DLink() {
    this.shareLink = this.data.link;
    return this.shareLink;
  }

  deactivate3DLink() {
    this.isShare3DLinkActive = !this.isShare3DLinkActive;

    const resultObj = { success: ModalEvents.On3DShareLinkDeActivate };
    this.modal.close(resultObj);

    // this.visualizeService.deleteCadData(this.data.Id).subscribe({
    //   next: data => {
    //     this.toastr.success('3D Link is deactivated successfully');
    //   },
    //   error: err => {
    //     this.toastr.error('There is fatal error while deactivating 3D Link', 'Error', {
    //       disableTimeOut: true,
    //       closeButton: true
    //     });
    //     this.isShare3DLinkActive = !this.isShare3DLinkActive;
    //   },
    // });
  }

  toggle3DShareMode(chkEvent: Event) {
    const deactivate3DShareCb = chkEvent.target as HTMLInputElement;
    if (deactivate3DShareCb.checked) {
      this.activate3Dlink();
    }
    else {
      this.deactivate3DLink();
    }
  }

  activate3Dlink() {
    const resultObj = { success: ModalEvents.On3DShareLinkActivate };
    this.modal.close(resultObj);
  }
}
