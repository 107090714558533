import { Component } from '@angular/core';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { CursorCssClass, DatatableColumn, DataTableEvent, PageHeaderInformation, PageName, RowActions as TableRowActions } from '@cpq-app/shared/datatable/datatable';
import { CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { VwsCpqQuote } from '../../VWS.interfaces.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CpqOperations } from '../../cpqOperations.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditLibraryComponent } from '../edit-library/edit-library.component';
const DATE_FMT = 'yyyy-MM-dd';
const internal = 'Internal'
const external = 'External'
@Component({
  selector: 'app-manage-library-job',
  templateUrl: './manage-library-job.component.html',
  styleUrls: ['./manage-library-job.component.scss'],
  providers: [CurrencyPipe]
})


export class ManageLibraryJobComponent {

  productsData;
  colData$: BehaviorSubject<DatatableColumn<VwsCpqQuote>[]>;
  dataLoading = false;
  customerViewSubscription: Subscription;
  pageHeaderInformation: PageHeaderInformation = {
    title: PageName.preconfigured
  }

  constructor(
    private cartService: CartService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private vwsCpqOperations: CpqOperations,
    private userService: UsersService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.fetchLibraryQuotes();
  }

  fetchLibraryQuotes() {
    this.dataLoading = true;
    this.spinner.show()
    this.cartService.getProducts().subscribe({
      next: (res: any) => {
        res.sort((a, b) => {
          const aD = Number(a.Sequence__c);
          const bD = Number(b.Sequence__c);
          return Number(aD > bD) - Number(aD < bD);
        });
        res.forEach(x=>{
          if(!x.Scope__c){
            x["InternalOnly"] = null;
          }
          else{
            x["InternalOnly"] = x.Scope__c?.indexOf("External") === -1 ? true : false;
          }          
        })
        this.productsData = res;
        this.spinner.hide();
      },
      complete: () => {
        this.dataLoading = false;
      }
    })
    const colData = this.getColData();
    this.colData$ = new BehaviorSubject<DatatableColumn<VwsCpqQuote>[]>(colData);
    this.subscribeToCustomerView();
  }

  getColData(customerView = false): DatatableColumn<VwsCpqQuote>[] {
    const colData: DatatableColumn<VwsCpqQuote>[] = [
      {
        field: 'Name', header: 'Pre-Configured Product Name', isPrimary: true,
        visibility: true, sortable: true, filterable: true,
        isEditable: true,
        value: (record: VwsCpqQuote) => record?.Name,
        // handler: (record: VwsCpqQuote, name) => this.onClickEdit(record, name),
        // className: (record: VwsCpqQuote) => CursorCssClass.ALLOW_EDIT,
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => a?.Name?.localeCompare(b?.Name),
      },
      {
        field: 'InternalOnly', control: 'toggle', header: 'Internal Scope',
        visibility: true, sortable: true,
        value: (record: VwsCpqQuote) => false,        
        handler: (record: VwsCpqQuote, isChecked) => this.onClickOfScope(record, isChecked),
        //        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => Number(a.IsPrimary > b.IsPrimary) - Number(a.IsPrimary < b.IsPrimary)
      },
      {
        field: 'CreatedTime', header: 'Created Time',
        initialSort: true, sortable: true, visibility: true,
        value: (record: VwsCpqQuote) => this.datePipe.transform(record.CreatedTime, DATE_FMT),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => {
          const aD = new Date(a.CreatedTime);
          const bD = new Date(b.CreatedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'ModifiedTime', header: 'Last Modified',
        initialSort: true, sortable: true, visibility: true,
        value: (record: VwsCpqQuote) => this.datePipe.transform(record.ModifiedTime, DATE_FMT),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => {
          const aD = new Date(a.ModifiedTime);
          const bD = new Date(b.ModifiedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'TotalAmount', header: 'Total Amount',
        visibility: !customerView, sortable: true,
        value: (record: VwsCpqQuote) => this.currencyPipe.transform(record.TotalAmount),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => { return (Number(a.TotalAmount) - Number(b.TotalAmount)) },
      },
      {
        field: 'actions', header: 'Actions',
        visibility: true,
        value: (quote) => {
          const actionsArray: DataTableEvent[] = [];
          //  actionsArray.push({ action: TableRowActions.SINGLE_FIELD_EDIT, tooltip: 'edit quote name' });
          actionsArray.push({ action: TableRowActions.EDIT, tooltip: 'edit quote', handler: () => this.editPopup(quote) });
          actionsArray.push({ action: TableRowActions.DELETE, handler: () => this.openDeleteModel(quote) });

          return actionsArray;
        }
      }
    ];
    return colData;
  }

  onClickOfScope(record, isChecked) {
    this.spinner.show();
    let scopeValue;
    if (isChecked) {
      scopeValue = JSON.stringify([internal]);
    } else {
      scopeValue = JSON.stringify([internal, external]);
    }
    const index = this.productsData.findIndex((x) => x.Id === record.Id);
    this.cartService.updatePreconfiguredQuotes(record.Id, {
      Scope__c: scopeValue
    }).subscribe({
      next: (id) => {
        if (index !== -1) {
          this.productsData[index].Scope__c = scopeValue;
          this.productsData[index]['InternalOnly'] = scopeValue?.indexOf("External") === -1 ? true : false;
        }
        this.toastr.success("Scope updated successfully");
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.toastr.error(
          'There was a fatal error while changing the Primary Revision, please try again', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  editPopup(quote: VwsCpqQuote) {
    const instance = this.modalService.open(EditLibraryComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'editPreconfigured'
    });
    const quoteDetail = {
      name: quote.Name,
      id: quote.Id,
      sequence: Number(quote?.Sequence__c) ?? null,
      scope: quote['InternalOnly'],
      length: this.productsData.length
    }
    instance.componentInstance.quote = JSON.stringify(quoteDetail);
    instance.result.then((outcome) => {}, (dismiss) => {
      if (dismiss?.Id) {
        const index = this.productsData.findIndex((x) => x.Id === dismiss.Id);
        this.productsData[index]['InternalOnly'] = dismiss?.Scope__c.indexOf("External") === -1 ? true : false;
        this.toastr.success('Quote updated successfully');
        this.fetchLibraryQuotes();
      }
    }
    );
  }


  /**
* @param id - pass quote ID to update the products information
*/
  openDeleteModel(quote: VwsCpqQuote) {
    const message = `Do you really want to delete pre-configured product '${quote?.Name}'?`;
    const data = {
      message,
      recordID: quote.Id,
    };
    const instance = this.vwsCpqOperations.openDeleteModelDialog(data);
    instance.result.then((outcome) => {
      if (outcome === quote.Id) {
        this.onClickDelete(quote)
      }
    })
  }

  onClickDelete(quote: VwsCpqQuote) {
    this.spinner.show();
    this.cartService.deletePreconfiguredQuotes(quote.Id).subscribe({
      next: () => {
        // this.getRevisionData();
        this.toastr.success('Quote deleted successfully');
        this.fetchLibraryQuotes();
      },
      error: (err) => {
        this.toastr.error(
          'There is fatal error while deleting Quote', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  onClickEdit(record: VwsCpqQuote, name) {
    if (name) {
      this.spinner.show();
      this.cartService.updateObjectById(CpqObjects.Quote, record.Id, {
        Name: name
      }).subscribe({
        next: res => {
          this.toastr.success('Quote Name updated successfully')
          this.spinner.hide();
          this.fetchLibraryQuotes();
        }
      })
    }
  }

  passEditClass(quote: VwsCpqQuote): string {
    // if (this.cartService.getVWSOpportunityStatus(this.opportunityData) === JOB_STATUS.SUBMITTED) {
    //   return CursorCssClass.DISABLE_POINTER;
    // }
    return CursorCssClass.ALLOW_CLICK;
  }
  subscribeToCustomerView() {
    this.customerViewSubscription = this.userService.getCustomerView()
      .subscribe({
        next: isActive => {
          const colData = this.getColData(isActive);
          this.colData$.next(colData);
        },
        complete: () => this.subscribeToCustomerView()
      });
  }

  ngOnDestroy() {
    this.customerViewSubscription?.unsubscribe();
  }
}
