import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-option',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() optionData: any[];
  @Input() groupName: string;
  @Output() valueChange = new EventEmitter();
  selectedValue: number;

  constructor() { }

  ngOnInit() {
    this.setSelectedValue(); // Set default value to select box
  }

  setSelectedValue() {
    const [selectedItem] = this.optionData.filter(item => item.selected === 1);
    this.selectedValue = selectedItem?.id;
  }

  onSelectionChange(event) {
    const [option] = this.optionData.filter(item => item.id.toString() === this.selectedValue);
    option.selected = 1;
    this.valueChange.emit(option);
  }
}
