<div class="modal-header preview-header">
    <div class="header">Preview</div>
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true"><i class="fa fa-times mr-2" aria-hidden="true"></i> Close</span>
    </button>
</div>

<div class="modal-body preview" *ngIf="quoteData">
    <div class="modal-body row preview-job">
        <h4 class="sub-header">Job Details</h4>
        <table class="table">
            <tr class="tunnel-options">
                <td class="tunnel-options">Power Supply (Voltage) : <strong
                        class="tunnel-output ml-2">{{quoteData?.PowerSupply__c}}</strong></td>
                <td class="tunnel-options">Building Length (feet) : <strong
                        class="tunnel-output ml-2">{{quoteData?.BuildingLength__c}}</strong></td>
            </tr>
        </table>
        <div class="wd-container" *ngFor="let categoryObj of quoteData?.PricingGroups.category">
            <ng-container *ngIf="categoryObj?.Groups?.length > 0">
                <div class="internal-header">{{categoryObj.Name}}</div>
                <table class="table">
                    <thead>
                        <tr>
                            <th><strong>Components</strong></th>
                            <th><strong>Details</strong></th>
                            <th *hideFromCustomer><strong>Amount</strong></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let productObj of categoryObj.Groups">
                        <tr class="categories">
                            <td colspan="3"><strong class="categories-text">{{productObj?.Name}}</strong></td>
                        </tr>
                        <ng-container *ngFor="let itemObj of productObj?.Items">
                            <tr>
                                <td><strong>{{itemObj?.OptionsValues?.aProductName || itemObj?.Name || ''}}</strong>
                                </td>
                                <td class="summary">{{itemObj?.OptionsSummary}}</td>
                                <td *hideFromCustomer>{{itemObj?.UnitListPrice | currency}}</td>
                            </tr>
                            <ng-container *ngIf="itemObj?.GrandTotalDiscountsAmount > 0">
                                <tr>
                                    <td></td>
                                    <td>{{itemObj?.OptionsValues?.aDiscountName}}</td>
                                    <td *hideFromCustomer>({{itemObj?.GrandTotalDiscountsAmount | currency}})</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                    <tr *hideFromCustomer class="top-border">
                        <td class="subtotal">Sub Total</td>
                        <td></td>
                        <td class="subtotal">{{categoryObj?.UnitListPrice| currency}}</td>
                    </tr>
                </table>
            </ng-container>
        </div>
    </div>
    <table *hideFromCustomer class="table totals">
        <tr *ngIf="quoteData.TaxAmount__c > 0">
            <td>Sales Tax</td>
            <td class="wd-bottom-container"></td>
            <td>{{quoteData.TaxAmount__c | currency}}</td>
        </tr>
        <tr *ngIf="quoteData.TotalDiscountsAmount > 0">
            <td>Equipment Discounts</td>
            <td class="wd-bottom-container"></td>
            <td>({{quoteData.TotalDiscountsAmount | currency}})</td>
        </tr>
        <!-- <tr *ngIf="quoteInfo.SpecialDiscountAmount > 0">
            <td>Special Discount</td>
            <td class="wd-bottom-container"></td>
            <td>({{quoteInfo.SpecialDiscountAmount | currency}})</td>
        </tr> -->
        <tr>
            <td class="bottom-totals">Grand Total</td>
            <td class="wd-bottom-container"></td>
            <td class="bottom-totals">{{(+quoteData.TotalAmount + +quoteData.TaxAmount__c)| currency}}</td>
        </tr>
    </table>
</div>