
export interface IStates {
  id: string;
  name: string;
  country_id: string;
}

export interface ICity {
  id: string;
  name: string;
  state_id: string;
}

export interface ICountry {
  id: string;
  name: string;
  sortname: string;
}

export interface Permission {
  CREATE: boolean,
  READ: boolean,
  UPDATE: boolean,
  DELETE: boolean
}

export interface ApiResponse {
  statusCode: number;
  message: string;
  data: any;
}

export interface CpqRoles {
  Id: number;
  Name: string;
}

export interface Pager {
  totalItems?: number,
  currentPage?: number,
  pageSize?: number,
  totalPages?: number,
  startPage?: number,
  endPage?: number,
  startIndex?: number,
  endIndex?: number,
  pages?: number[],
  pageNumber?: number,
  searchText?: string,
  sort?: string,
  organizationList?: string[]
  currentPageItems?: number;
  opcoName?: string;
}

export const HTTP_RETRY_COUNT = 3;

export const PERMISSION_OPTIONS = {
  CREATE: false,
  READ: false,
  UPDATE: false,
  DELETE: false,
};

export enum Variables {
  PERSONA = 'persona',
  USER_TYPE = 'userType',
  ORGANIZATION = 'organization',
  ORGANIZATION_ID = 'organizationId',
  ORGANIZATIONS = 'organizations',
  ORGANIZATION_TYPE = 'organizationType',
  EDIT_ORG_DATA = 'orgInEdit',
  EDIT_USER_DATA = 'userInEdit',
  ORG_COUNT = 'orgCount',
  DISTRIBUTOR = 'distributor',
  INTERNAL = 'internal',
  PROVIDER = 'provider',
  GUEST = 'guest',
  CUSTOMER_VIEW = 'isCustomerViewOn',
  DISABLE_3D = 'disable3d',
  ADMIN = "Admin",
  EXTERNAL = "External",
}

export enum OPCO {
  VWS = 'VWS',
  TWG = 'TWG',
  FTS = 'FTS',
}

export interface IOpco {
  id: number,
  name: string
}

export const OPCOS = [
  {
    id: 1,
    name: 'VWS'
  },
  {
    id: 1,
    name: 'TWG'
  },
  {
    id: 1,
    name: 'FTS'
  }
]

export interface IPersonas {
  id: string,
  personaName: string,
  alias: string
}

export interface OrganizationsResponse {
	statusCode: 	number;
	message: 			string;
	data: 				IPersonas[];
}

	