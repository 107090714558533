<ngx-spinner [fullScreen]="false" type="cog" size="large" [name]="LOGIN_SPINNER">
  <p class="login-spinner">Getting things ready...</p>
</ngx-spinner>
<!--top link section-->
<app-breadcrumbs>
  <div>
    <a [routerLink]="'/'">HOME</a>
  </div>
</app-breadcrumbs>
<!--top Header-->
<h1 class="textcapitalize cpq-page-header">Configure Home</h1>
<!--top configuration section-->
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
  <div fxLayout="column" class="landing-rectangle-large" fxFlex="40" fxLayoutAlign="space-between start">
    <div class="landing-page-box-text">Start a New Configuration</div>
    <div class="box-description-text" fxFlex="grow">Answer a few questions to have products recommended to you.
    </div>
    <div><button mat-flat-button color="accent" class="textcapitalize cpq-button-text"
        (click)="startNewConfiguration()">START</button></div>
  </div>
  <div fxLayout="column" class="landing-rectangle-large" fxFlex="40" fxLayoutAlign="space-between start">
    <div class="landing-page-box-text">Product Search</div>
    <div class="box-description-text">
      Know the product name or what you’re looking for?
    </div>
    <div class="landing-page_search">
      <i class="fa fa-search fa-lg"></i>
      <input type="text" (keydown.enter)="startProductSearchConfiguration();" [(ngModel)]="searchString" placeholder="">
    </div>
    <div><button mat-flat-button color="accent" (click)="startProductSearchConfiguration()"
        class="textcapitalize cpq-button-text">SEARCH</button></div>
  </div>

  <div fxLayout="column" fxFlex fxLayoutGap="15px">
    <button class="landing-rectangle-small" fxLayoutAlign="center center" (click)="openModal('video')">
      <div class="icon_align">
        <img src="assets/images/video.svg">
        <div class=" landing-vertical-box">Video Tutorials</div>
      </div>
    </button>
    <button class="landing-rectangle-small" fxLayoutAlign="center center" (click)="openModal('faq')">
      <div class="icon_align">
        <img src="assets/images/faqs-1.svg">
        <div class=" landing-vertical-box">Frequently Asked Questions</div>
      </div>
    </button>
  </div>
</div>
<!--Cart section-->
<app-cart [quoteId]="quoteID" [opportunityId]='opportunityId' (startNewConfiguration)="startNewConfiguration()"
  *ngIf="showChildren"></app-cart>
<!--Quote section-->
<app-quote [quoteId]="quoteID" *ngIf="showChildren" (startNewConfiguration)="startNewConfiguration()"></app-quote>