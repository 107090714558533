import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  group;
  @Input() groupData: any;
  @Input() showGroup = true;
  @Output() optionChanged = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  groupTracker(index: number, group: any) {
    // hash as the index+name
    return group.name;
  }

  optionTracker(index: number, option: any) {
    return option.name;
  }

  changeDetected(optionData) {
    console.log('change Detected', optionData);
    this.optionChanged.emit(optionData);
  }

  applyFlexClass(data): boolean {
    let returnValue = false;
    if (data.maximum == 1) {
      returnValue = true;
    }
    return returnValue;
  }

  renderRadioGroup(data) {
    return (
      data.selectionType == 'Radio' &&
      (data.classname == 'Preselect_Group' ||
        data.classname == 'PreselectOptions') &&
      data.CustomView != 'ImportantChoice' &&
      data.CustomView != 'LogicalChoice'
    );
  }

  renderRadioButton(data) {
    return (
      data.selectionType == 'Radio' &&
      (data.classname == 'Groups' || data.classname == 'Options' || data.classname == 'GlobalOptions')
    );
  }

  displayButtonGroups(data) {
    return (
      data.maximum == 1 &&
      (data.CustomView == 'ImportantChoice' ||
        data.CustomView == 'LogicalChoice')
    );
  }

  showDescription(groupData) {
    return (
      groupData.classname == 'Preselect_Group' &&
      groupData.subGroups.length == 0
    );
  }

  renderLabel(data) {
    return data.selectionType == 'Label';
  }

  renderSelectBox(data) {
    return data.uiView == 'SingleSelect';
  }
}
