
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DebounceDirective } from '@cpq-app/sharedDirectives/debounce-directive.directive';
import { ButtonGroupComponent } from '@cpq-app/sharedDirectives/group/button-group/button-group.component';
import { DropdownComponent } from '@cpq-app/sharedDirectives/group/dropdown/dropdown.component';
import { GroupComponent } from '@cpq-app/sharedDirectives/group/group.component';
import { LabelComponent } from '@cpq-app/sharedDirectives/group/label/label.component';
import { RadioButtonComponent } from '@cpq-app/sharedDirectives/group/radio-button/radio-button.component';
import { RadioComponent } from '@cpq-app/sharedDirectives/group/radio/radio.component';
import { OptionsComponent } from '@cpq-app/sharedDirectives/options/options.component';
import { MaterialModule } from './material-module';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        GroupComponent,
        OptionsComponent,
        DropdownComponent,
        RadioComponent,
        LabelComponent,
        RadioButtonComponent,
        DebounceDirective,
        ButtonGroupComponent

    ],
    exports: [
        GroupComponent,
        OptionsComponent,
        DropdownComponent,
        RadioComponent,
        LabelComponent,
        RadioButtonComponent,
        DebounceDirective,
        ButtonGroupComponent
    ]
})

export class SharedModule { }

// function routes(routes: any): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
//     throw new Error('Function not implemented.');
// }
