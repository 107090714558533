import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material-module';
import { BreadcrumbsModule } from 'src/app/shared/breadcrumbs/breadcrumbs.module';
import { NgxSpinnerModule } from 'ngx-spinner';


import { LandingPageComponent } from './landing-page/landing-page.component';
import { CartComponent } from './cart/cart.component';
import { QuoteComponent } from './quote/quote.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxSpinnerModule,
        BreadcrumbsModule,
        RouterModule ],
    declarations: [
        LandingPageComponent,
        CartComponent,
        QuoteComponent
    ],
    providers: [
    ]
  })
  export class TWGModule { }