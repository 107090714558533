import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { CpqProfiles, Country, CPQ_EXPORT_STATUS } from '@cpq-app/tenants/Cpq.interfaces.service';
import { environment } from '@cpq-environments/environment';
import { empty, Observable, Observer, of, ReplaySubject, Subject, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, expand, map, mergeMap, switchMap, tap, concatMap } from 'rxjs/operators';
import { Family } from '../models/family.model';
import { LoginService } from './login.service';


export interface QueryResult<T> {
  done: boolean;
  records: Array<T>;
  size: number;
  cursorid?: string;
}

export interface OppAccountResult {
  Id?: string;
  ExternalId?: string;
  Account?: CpqAccount;
}

export interface NestedQueryResult<T> {
  records: Array<T>;
  size: number;
}
export interface CpqVisibility<T> {
  size: number;
  records: Array<T>;
  success: boolean;
  canShare: boolean;
}
export interface CpqApiResult {
  success: boolean;
  results: any[];
}

interface CpqObjectParams {
  withAcl?: string;
  resolveNames?: string;
  refreshAvailableProducts?: boolean;
}

interface FpxApiDeleteResult {
  success: boolean;
  id: string;
}

export interface CpqSaveConfigResult {
  productId: string;
  quoteProductId: string;
}

export interface CpqReOpenConfigResult {
  productId: string;
  configId: string;
  readOnly: boolean;
  currency: string;
}

interface CpqMessages {
  code: string;
  level?: string;
  context?: any; // Dynamic return
}

interface CpqChanges {
  id: string;
  op: string;
  attributeChanges?: any;
}

export interface CpqObjectUpdateResult {
  Id?: string;
  messages?: CpqMessages[];
  changes?: CpqChanges[];
}

export interface CpqAddFavoritesResult {
  productIds?: string[];
  messages?: CpqMessages[];
  changes?: CpqChanges[];
}

export class CpqAccount {
  static queryFields = ['Id', 'Name', 'ExternalId', 'AccountNumber'];
  Id: string;
  Name: string;
  ExternalId: string;
  AccountNumber: string;
}

export class CpqProposal {
  static queryFields = ['Id', 'Name'];
  Id: string;
  Name: string;

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Proposal`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Proposals)`;
  }
}

export class CpqQuoteline {
  static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'CreatedDate',
    'FormattedId', 'Note', 'ExpirationDate', 'ProductId',
  ];

  static queryFields = CpqQuoteline.guestQueryFields.concat(
    ['Selling_Price__c', 'TotalSellingPrice', 'UnitSellingPrice',
    ]);


  // tslint:disable: variable-name
  Id: string;
  Name: string;
  Quantity: number;
  WorkflowStatus: string;
  CreatedDate: string | Date;
  FormattedId: string;
  Note: string;
  ExpirationDate: string | Date;
  ProductId: string;
  Selling_Price__c?: number;
  TotalSellingPrice?: number;
  UnitSellingPrice?: number;
  // tslint:enable: variable-name

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Quoteline`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Quotelines)`;
  }
}

export enum QuoteWorkflowStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Completed = 'Completed',
  AutoApproved = 'AutoApproved',
  NoApprovalRequired = 'NoApprovalRequired',
  Waiting = 'Waiting',
  None = 'None',
  Invalid = 'Invalid',
  Canceled = 'Canceled',
  Aborted = 'Aborted',
  ObjectDeleted = 'ObjectDeleted',
  Rejected = 'Rejected',
}

export enum CpqGrantToObjects {
  User = 'user',
  Partner = 'partner',
  Role = 'role',
  Group = 'group'
}
//TO BE REMOVED
export enum CpqQueryObjects {
  Opportunity = 'opportunities',
  Opportunities = 'opportunities',
  Quote = 'quotes',
  Quotes = 'quotes',
  Account = 'accounts',
  Accounts = 'accounts',
  Partners = 'partners',
  Roles = 'roles',
  Users = 'users',
  Profiles = 'profiles',
  Favorites = 'favorites',
  OpportunityQuotes = 'opportunityquotes',
  QuoteProducts = 'quoteproducts'
}


export enum OpcoType {
  VWS = 'VWS',
};


export enum CpqObjects {
  Opportunity = 'opportunity',
  Opportunities = 'opportunity',
  Quote = 'quote',
  Quotes = 'quote',
  Account = 'account',
  Accounts = 'account',
  Partners = 'partner',
  Partner = 'partner',
  Roles = 'role',
  Users = 'user',
  User = 'user',
  Profiles = 'profile',
  Favorites = 'favorite',
  QuoteLine = 'quoteline',
  QuoteLineEntry = 'QUOTELINEENTRY'
}


export enum CpqObjectType {
  Quote = 'quote',
  Opportunity = 'opportunity',
  Account = 'account',
  Partner = 'partner',
  Role = 'role',
  User = 'user',
  Profile = 'profile',
  Favorite = 'favorite',
  QuoteProduct = 'quoteproduct',
  Product = 'quoteproduct',
  QuoteLine = 'quoteline',
}

enum Tenants {
  VWSPortal = 'VWS'
}

/**
 * VWS Job status
 */
export enum JOB_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed'
};



export class CpqQuote {
  static queryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
    'FormattedId', 'Note', 'Selling_Price__c', 'ExpirationDate',
    CpqProposal.getNestedQueryString(),
  ];

  static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
    'FormattedId', 'Note', 'ExpirationDate',
    CpqProposal.getNestedQueryString(),
  ];

  // tslint:disable: variable-name
  Id: string;
  Name: string;
  WorkflowStatus: QuoteWorkflowStatus;
  CreatedDate: string;
  FormattedId: string;
  Note: string;
  ExpirationDate: string | Date;

  // These fields may not be available to guests
  Selling_Price__c?: string;
  // tslint:enable: variable-name

  // These are nested objects
  QuoteLines: QueryResult<CpqQuoteline>;
  quoteLines?: CpqQuoteline[];

  QuoteConfiguredProducts?: QueryResult<any>;
  products?: any[];

  Proposals?: QueryResult<CpqProposal>;
  proposal?: CpqProposal;


  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Quote`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Quotes)`;
  }

  /**
   * Retruns `true` if the quote object has a closed workflow state
   * @param quote as `CpqQuote`
   */
  static checkIsClosed(quote: CpqQuote) {
    switch (quote?.WorkflowStatus) {
      case QuoteWorkflowStatus.None:
      case QuoteWorkflowStatus.NoApprovalRequired:
      case QuoteWorkflowStatus.InProgress:
      case QuoteWorkflowStatus.Aborted:
      case QuoteWorkflowStatus.Canceled:
      case QuoteWorkflowStatus.Invalid:
      case QuoteWorkflowStatus.Rejected:
        return false;

      case QuoteWorkflowStatus.Completed:
      case QuoteWorkflowStatus.Approved:
      case QuoteWorkflowStatus.Waiting:
      case QuoteWorkflowStatus.AutoApproved:
      default:
        return true;
    }
  }

  constructor(maybeQuote?: CpqQuote) {
    // console.log('%c*** Made a CpqQuote', 'background-color:red');
    this.Id = maybeQuote?.Id;
    this.Name = maybeQuote?.Name;
    this.WorkflowStatus = maybeQuote?.WorkflowStatus;
  }

  get id() {
    return this.Id;
  }

  get name() {
    return this.Name;
  }

  get workflowStatus() {
    return this.WorkflowStatus;
  }

  get isClosed(): boolean {
    switch (this.WorkflowStatus) {
      case QuoteWorkflowStatus.None:
      case QuoteWorkflowStatus.NoApprovalRequired:
      case QuoteWorkflowStatus.InProgress:
      case QuoteWorkflowStatus.Aborted:
      case QuoteWorkflowStatus.Canceled:
      case QuoteWorkflowStatus.Invalid:
      case QuoteWorkflowStatus.Rejected:
        return false;

      case QuoteWorkflowStatus.Completed:
      case QuoteWorkflowStatus.Approved:
      case QuoteWorkflowStatus.Waiting:
      case QuoteWorkflowStatus.AutoApproved:
      default:
        return true;
    }
  }
}


interface CpqOpenConfigResult {
  configAlias: string;
  datasetGUID: string;
  datasetPublishedDateTime: string;
  success: true;
  configId: string;
  currency: string;
  openConnections: number;
  maxConnections: number;
}

export interface CpqProductUpdate {
  update?: boolean;
  status?: CpqProductUpdateStatus;
  type?: 'PRODUCTS_UPDATE';
  products?: string[];
}

export interface CpqProgress {
  messageTemplates: any;
  progress: number;
  messages: CpqProgressMessage[];
  type: CpqProcessType;
  isCancelable: boolean;
  lastLogid: number;
  status: CpqProductUpdateStatus;
}

export enum CpqMessageType {
  NORMAL = 'NORMAL',
  ALERT = 'ALERT',
}

export enum CpqProcessType {
  PRODUCT_UPDATE = 'PRODUCTS_UPDATE',
  CONFIG_RESAVE = 'CONFIG_RESAVE',
}

export interface CpqProgressMessage extends CpqMessages {
  logId: number;
  message: string;
  type: CpqMessageType;
  timestamp: number;
}

export enum CpqProductUpdateStatus {
  WAITING = 'waiting',
  WORKING = 'working',
  CANCELING = 'canceling',
  CANCELED = 'canceled',
  COMPLETE = 'complete',
  FATAL_ERROR = 'fatal',
}

export class CpqOpportunity {
  Id: string;
  Name: string;
  quotes?: CpqQuote[];

  constructor(mayOpp?: CpqOpportunity) {
    this.Id = mayOpp?.Id;
    this.Name = mayOpp?.Name;
    this.quotes = mayOpp?.quotes?.map(q => new CpqQuote(q));
  }

  // camelCase accessors
  get id(): string { return this.Id; }
  set id(newId: string) { this.Id = newId; }
  get name(): string { return this.Name; }
  set name(newName: string) { this.Name = newName; }

}

class QueryCpqOpportunity {
  static queryFields = ['Id', 'Name', CpqQuote.getNestedQueryString()];

  Id: string;
  Name: string;
  Quotes?: NestedQueryResult<CpqQuote>;

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Opportunity`;
  }

  getOpportunity(): CpqOpportunity {
    const opp = new CpqOpportunity();
    opp.Id = this.Id;
    opp.Name = this.Name;
    opp.quotes = this.Quotes?.records?.map(q => new CpqQuote(q));

    return opp;
  }
}

export interface CpqUser {
  Id: string;
  Username: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsActive: boolean;
  ProfileId: string;
  UserRoleId?: string;
  PartnerId?: string;
  IsSharedAnonymous: boolean;
  IsTemplateAnonymous: boolean;
}
interface CpqQueryOptions {
  Id?: string;
  addonFields?: string;
  filters?: string;
  sort?: string;
  limit?: string;
  batchsize?: string;
  resolveNames?: string;
  quotes_addonFields?: string;
  quoteLines_addonFields?: string;
  quoteProducts_addonFields?: string;
}

const CPQ_API = {
  ADD_FAVORITES: 'addFavorites',
  SAVE_FAVORITE: 'saveFavorite',
  CRM: 'crm',
  EXPORT: 'export',
  PROPOSAL: 'proposal',
  PRODUCT:'product',
  CATEGORIES: 'categories',
  CONFIG: 'CONFIG',
  OPTIONS: 'OPTIONS'
};

// Public API to get all available countries
const COUNTRIES_API = 'https://restcountries.com/v2/all';
// get only required fields to lighten the response data
const COUNTRIES_FIELDS_TO_FETCH = ['name', 'alpha3Code'];

interface QueryContainer {
  url: string;
  params: HttpParams;
  dataObserver: Observer<any>;
}


@Injectable({
  providedIn: 'root'
})
export class CartService {
  // Local Storage symbols
  readonly USER_ID = 'userId';
  readonly USERNAME = 'username';

  oppurtunityId: string;
  quoteId: string;
  rfst = this.loginService.getRfst();
  correlationId: string;
  configId: string;

  private sharedGuid = new Subject();
  currentGuidData = this.sharedGuid.asObservable();

  public accountsUpdated$ = new Subject();
  private backendUrl = environment.B2CConfigs.BackendURL;

  public placeOrderOnRevision$ = new Subject<void>();
  public updateJobFromStepper$: Subject<void> = new Subject();
  public updateQuoteFromStepper$: Subject<void> = new Subject();
  public selectedHeaderInfo$: Subject<string> = new Subject();
  public orderPlaced$: Subject<boolean> = new Subject();

  readonly quoteFields = [
    'Id',
    'CreatedDate',
    'FormattedId',
    'Name',
    'Note',
    'Selling_Price__c',
    'ExpirationDate',
  ];

  readonly productFields = [
    'NodeIndex',
    'OpportunityId',
    'graphicName__c',
    'Model_Code__c',
    'Proposal_Notes__c',
    'Actual_Unit_List_Price__c',
    'TotalList'
  ];

  readonly quoteLineFields = [
    'ProductId',
    'Description',
    'ExtendedDescription',
    'Name',
    'Quantity',
    'TotalSellingPrice',
    'UnitSellingPrice'
  ];

  readonly guestQuoteLineFields = [
    'ProductId',
    'Description',
    'ExtendedDescription',
    'Name',
    'Quantity',
  ];

  quoteSubjects = new Map<string, ReplaySubject<CpqQuote>>();
  quoteUpdateInFlight = new Map<string, Observable<any>>();

  constructor(
    private http: HttpClient,
    private guestService: GuestConfigService,
    private loginService: LoginService,
    private msalService: MSALService,
    private zone: NgZone
  ) {
  }

  tenantName = this.msalService.getTenantName();

  store(key, value) {
    sessionStorage.setItem(key, value);
  }
  retrive(key) {
    return sessionStorage.getItem(key);
  }
  remove(key) {
    sessionStorage.removeItem(key);
  }

  /**
   * Asynchronously obtains a collection of the available product families (aka datasets).
   * @return an `Observable` for the `Array` of `Family` objects
   */
  fetchFamilies(quoteId: string): Observable<Family[]> {
    const url = this.cpqUrl('cpaas', 'ui', 'datasets');
    const params = new HttpParams().set('quoteId', quoteId);

    return new Observable<Family[]>(observer => {
      const subscription = this.http
        .get<CpqApiResult>(url, { params, withCredentials: true })
        .subscribe(resp => {
          if (resp.success && resp.results) {
            const families = resp.results;
            observer.next(families);
            observer.complete();
          } else {
            observer.error('There were no valid families');
          }
        });

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  publicationForQuoteWithProducts(quoteId: string): Subject<CpqQuote> {
    if (!this.quoteSubjects.has(quoteId)) {
      this.quoteSubjects.set(quoteId, new ReplaySubject<CpqQuote>(1));
      // Subscribe to the update in order to trigger the subject creation
      // however the resulting subscription itself is unneeded.
      this.updateQuoteData(quoteId).subscribe();
    }

    return this.quoteSubjects.get(quoteId);
  }

  updateQuoteData(quoteId: string): Observable<void> {
    let returnObservable: Observable<void>;

    if (!this.quoteUpdateInFlight.has(quoteId)) {
      returnObservable = this.fetchCpqQuoteWithProducts(quoteId).pipe(
        map(data => {
          this.quoteSubjects.get(quoteId).next(data);
          this.quoteUpdateInFlight.delete(quoteId);
        }),
        tap({ error: () => console.log(`%cError getting quote data`, 'color:red') }),
      );

      this.quoteUpdateInFlight.set(quoteId, returnObservable);

    } else {
      console.log(`Update in flight for ${quoteId}`);
      returnObservable = this.quoteUpdateInFlight.get(quoteId);
    }

    return returnObservable;
  }

  /**
   * //FIX ME : DO NOT use as it may deprecate since its TWG specific;
   * Asynchronously obtains a Quote with it's collection of Products.
   * @return an `Observable` for the `Quote` object.
   */
  fetchCpqQuoteWithProducts(quoteId: string): Observable<CpqQuote> {
    const loggedInUser = this.guestService.getGuestUserDetails();
    const quoteFields = loggedInUser.isGuest ? CpqQuote.guestQueryFields : CpqQuote.queryFields;
    const quoteLineFields = loggedInUser.isGuest ? this.guestQuoteLineFields : this.quoteLineFields;
    const url = this.cpqUrl('deepobject', 'quotes', quoteId, 'products');
    const params = new HttpParams()
      .set('addonFields', `${quoteFields.join(',')}`)
      .set('qL_addonFields', `${quoteLineFields.join(',')}`)
      .set('qP_addonFields', `${this.productFields.join(',')}`);

    return new Observable<any>(observer => {
      const subscription = this.http.get<any>(url, { params, withCredentials: true }).subscribe(
        rawQuotes => {
          console.log(`%cDATA%c for quotes`, 'background-color:black; color:white;', 'color:black', rawQuotes);
          const rawQuote = rawQuotes;
          const quote = {
            quoteLines: rawQuote.QuoteLines?.records || [],
            products: rawQuote.QuoteConfiguredProducts?.records || [],
            proposal: rawQuote.Proposals?.size ? rawQuote.Proposals.records[0] : undefined,
          };

          quoteFields.forEach(field => {
            quote[field] = rawQuote[field];
          });

          observer.next(quote);
          observer.complete();
        },
        err => {
          // FIXME error handling
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  private getIsoDateOffsetByMonths(numberOfMonths = 0) {
    const offsetDate = new Date();
    offsetDate.setMonth(offsetDate.getMonth() - numberOfMonths);
    return offsetDate.toISOString();
  }

  /**
   * //FIX ME : DO NOT use as it may deprecate since its TWG specific;
   * Asynchronously obtains a collection of previous quotes
   * @param numberOfMonths as `Number` defaults to 1
   * @returns an `Observable` of an `Array` of `Quote` objects
   */
  fetchQuotes(numberOfMonths = 1): Observable<CpqQuote[]> {
    const loggedInUser = this.guestService.getGuestUserDetails();
    const userId = this.retrive(this.USER_ID);
    if (!userId) {
      return throwError('No valid userId'); // FIXME better error handling
    }
    const oldestDate = this.getIsoDateOffsetByMonths(numberOfMonths);
    const quoteFields = loggedInUser.isGuest ? CpqQuote.guestQueryFields : CpqQuote.queryFields;
    const url = this.cpqUrl('object', 'quotes');
    const params = new HttpParams()
      .set('addonFields', `${quoteFields.join(',')}`)
      .set('filters', `LastModifiedDate > '${oldestDate}',CreatedById = '${userId}'`)
      .set('sort', `-CreatedDate`);

    return new Observable<CpqQuote[]>(observer => {
      const subscription = this.http.get<CpqQuote[]>(url, { params, withCredentials: true }).subscribe(
        rawQuotes => {
          console.log(`%cDATA%c for quotes`, 'background-color:black; color:white;', 'color:black', rawQuotes);
          const quotes = rawQuotes.map<CpqQuote>(q => {
            q.quoteLines = q.QuoteLines?.records || [];
            q.products = q.QuoteConfiguredProducts?.records || [];
            q.proposal = q.Proposals?.size ? q.Proposals.records[0] : undefined;
            return q;
          });

          observer.next(quotes);
          observer.complete();
        },
        err => {
          // FIXME error handling
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Asynchronous request to delete a product
   * @param productId as `String`
   * @returns an `Observable` of the request; signals success or failure.
   */
  deleteProduct(productId: string): Observable<any> {
    return this.loginService.getRfst().pipe(
      switchMap(rfst => {
        const url = this.cpqUrl('deleteObject', productId);
        const params = new HttpParams().set('rfst', rfst);
        return this.http.delete(url, { params, withCredentials: true });
      })
    );
  }

  setProductQty(productId: string, qty: number): boolean {
    // cpq.update
    return true;
  }

  createOpportunityId(): Observable<any> {
    const params = [];
    const payload = {
      type: 'Opportunity',
      Name: 'UI Cart Opportunity'
    };
    params.push(payload);
    const url = this.cpqUrl('createObjects');

    return this.http.post<any>(url, params, { withCredentials: true });
  }

  /**
   * Asynchronously returns the most recent Opportunity Id
   */
  getMostRecentOpportunity(): Observable<string> {
    const userId = this.retrive(this.USER_ID);
    const url = this.cpqUrl('object', 'opportunities');
    const params = new HttpParams()
      .set('filters', `OwnerId='${userId}'`)
      .set('sort', `-LastModifiedDate`)
      .set('limit', '1');

    return new Observable<string>(observer => {
      const subscription = this.http.get<any>(url, { params, withCredentials: true }).subscribe(
        results => {
          const opportunityId = (results.length > 0) ? results[0].Id : '';
          observer.next(opportunityId);
          observer.complete();
        },
        err => {
          // FIXME add error handling or remove this and allow it to bubble up
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }


  /**
   * Asynchronously returns the most recent Opportunity Id
   */
  getMostRecentOpportunityAndQuote(): Observable<CpqOpportunity> {
    const userId = this.retrive(this.USER_ID);
    const url = `${this.backendUrl}/cpq/getMostRecentOpportunityAndQuote`;
    const params = new HttpParams()
      .set('userId', userId);

    return new Observable<CpqOpportunity>(observer => {
      const subscription = this.http.get<QueryCpqOpportunity>(url, { params, withCredentials: true }).subscribe(
        opps => {
          let opp = new CpqOpportunity();
          if (opps) {
            opp = opps[0].getOpportunity();
          }

          observer.next(opp);
          observer.complete();
        },
        err => {
          // FIXME add error handling or remove this and allow it to bubble up
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  getMostRecentQuoteIdOnOpp(
    oppurtunityId: string
  ): Observable<CpqQuote[]> {
    const url = `${this.backendUrl}/cpq/getMostRecentQuoteIdOnOpp`;
    const params = new HttpParams()
      .set('oppId', oppurtunityId);
    return new Observable<CpqQuote[]>(observer => {
      const subscription = this.http.get<CpqQuote[]>(url, { params, withCredentials: true }).subscribe(
        results => {
          observer.next(results);
          observer.complete();
        },
        err => {
          // FIXME add error handling or remove this and allow it to bubble up
          console.log(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  createOpportunity(): Observable<CpqOpportunity> {
    throw new Error('Method Not Implemented');
    return of(new CpqOpportunity());
  }

  createQuote(oppId: string): Observable<CpqQuote> {
    throw new Error('Method Not Implemented');
    return of(new CpqQuote());
  }

  /**
   * To get the CPQ SSO Profiles
   */
  getCpqSsoProfiles(filter): Observable<any> {
    return new Observable(observer => {
      const subscription = this.getCpqObjects<CpqProfiles>(CpqQueryObjects.Profiles, filter).subscribe(
        profiles => {
          const filteredProfiles = profiles.filter((profile) => {
            return profile.PermissionSsoUser === true;
          });
          observer.next(filteredProfiles);
        },
        err => {
          console.log('Failed to fetch the profiles', err);
          observer.error('Failed to fetch the profiles');
        },
        () => {
          observer.complete();
        }
      );
      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }


  /**
   * Creates an object
   * @param objectType a `string` of the CPQ object type
   * @param object an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the resulting object id `string`
   */
  createObject(objectType: string | CpqObjects, object: any): Observable<any> {
    if (!objectType) {
      return throwError("A valid object type is required to create the Object");
    }

    if (!object) {
      return throwError("Object fields are required");
    }
    const url = this.cpqUrl("object", objectType);
    //object.type = objectType;

    return new Observable<any>((observer) => {
      const subscription = this.http
        .post<CpqObjectUpdateResult>(url, object, { withCredentials: true })
        .subscribe(
          (resp) => {
            const createdId = resp?.Id;
            if (createdId) {
              observer.next(resp);
            } else {
              observer.error(resp[0]?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to update the Object", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }


  /**
   * Method to update the CPQ object
   * @param objectId a `string` of the object id like quoteid, opportunityid, userId..etc
   * @param objectFields an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the object id `string`
   */
  updateObjectId(objectId: string, objectFields: any): Observable<string> {
    if (!objectId) {
      return throwError('A valid ID is required to update the Object');
    }
    const url = this.cpqUrl('Object', objectId, objectFields);

    return new Observable(observer => {
      const subscription = this.http.put<CpqObjectUpdateResult>(url, objectFields, { withCredentials: true }).subscribe(
        resp => {
          if (resp?.Id) {
            observer.next(resp.Id);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Updates the list of Object with lastest changes
   * @param objectParams `array` of objects to update the changes
   * @returns an `Observable` of each object id as `string`
   */
  updateObject(objectParams: any[]): Observable<string> {
    const url = this.cpqUrl('updateObjects');
    return new Observable(observer => {
      const subscription = this.http.post<CpqObjectUpdateResult[]>(url, objectParams, { withCredentials: true }).subscribe(
        results => {
          results.forEach(objResult => {
            if (objResult?.Id) {
              observer.next(objResult.Id);
            } else {
              observer.error(objResult?.messages);
            }
          });
          observer.complete();
        },
        err => {
          console.log('Failed to Update the Object Id`s', err);
          observer.error('Failed to Update the Object Id`s');
        }
      );
      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  // TO BE REMOVED : CREATED getCpqObjectByIdCDS
  getObject<T>(objectId: string): Observable<T> {
    const success = new Subject<T>();
    const url = this.cpqUrl('getObject', objectId);
    const sub = this.http.get<T>(url, { observe: 'body', withCredentials: true }).subscribe(
      res => {
        success.next(res);
      },
      (err) => {
        console.log(err);
      });

    return success;
  }

  /**
   * Deletes an array of objects
   * @param objectIds a string array of object uids to delete.
   * @returns an `Observable` of the results
   */
  deleteObjects(objectIds: string[]): Observable<CpqApiResult> {
    const url = this.cpqUrl('deleteObjects');

    return new Observable(observer => {
      const subscription = this.loginService.getRfst().pipe(
        switchMap(rfst => {
          const params = new HttpParams()
            .set('rfst', rfst)
            .set('uids', objectIds.join(','));
          return this.http.delete<FpxApiDeleteResult[]>(url, { params, withCredentials: true });
        })).subscribe({
          next: (results) => {
            observer.next({
              success: results.reduce<boolean>((acc, i) => acc && i.success, true),
              results
            });
            observer.complete();
          },
          error: err => { observer.error(err); }
        });

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });

  }

  createQuoteId(oppurtunityId: string): Observable<any> {
    const params = [];
    const payload = {
      type: 'Quote',
      OpportunityId: oppurtunityId,
      Name: 'New Quote'
    };
    params.push(payload);
    const url = this.cpqUrl('createObjects');
    return this.http.post<any>(url, params, { withCredentials: true });
  }

  /**
   * Method to create the CPQ user
   * @param userFields - Object variable has the required fields to create user.
   * @return an `Observable` for the CPQ UserId
   */
  createUserId(userFields: any): Observable<any> {
    const url = this.cpqUrl("object", CpqObjects.User);
    return new Observable((observer) => {
      const subscription = this.http
        .post<any>(url, userFields, { withCredentials: true })
        .subscribe(
          (resp) => {
            observer.next(resp);
            observer.complete();
          },
          (err) => {
            console.log("Failed to Create CPQ User", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  activatePartner(partnerId: any): Observable<any> {
    const params = { isActive: true };
    const url = this.cpqUrl('object', CpqObjects.Partner, partnerId);

    return new Observable(observer => {
      const subscription = this.http.put<any>(url, params, { withCredentials: true }).subscribe(
        resp => {
          observer.next(resp);
          observer.complete();
        },
        err => {
          console.log('Failed to activate CPQ Partner', err);
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Method to create the CPQ Account
   * @param accountFields - Object variable has the required fields to create Account.
   * @return an `Observable` for the CPQ Account Id
   */
  createAccountObject(accountFields: any): Observable<string> {
    const params = [];
    accountFields.type = 'Account';
    params.push(accountFields);
    const url = this.cpqUrl('createObjects');

    return new Observable<string>(observer => {
      const subscription = this.http.post<CpqObjectUpdateResult>(url, params, { withCredentials: true }).subscribe(
        resp => {
          const accountId = resp[0]?.Id;
          if (accountId) {
            observer.next(accountId);
            this.accountsUpdated$.next();
          } else {
            observer.error(resp[0]?.messages);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to Create CPQ Account', err);
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }
  /**
   * sync the partner object values to Account object
   * @param partnerValues - Fields of the Partner organization
   * @param partnerId - Id of the partner organization
   */
  synchPartnerToAccount(partnerValues: any, partnerId: string): void {
    const accountFields = {
      AccountNumber: '',
      Name: partnerValues.Name,
      BillingCity: partnerValues.City,
      BillingCountry: partnerValues.Country,
      BillingPostalCode: partnerValues.PostalCode,
      BillingState: partnerValues.State,
      BillingStreet: partnerValues.Street,
      Fax: partnerValues.Fax,
      Phone: partnerValues.Phone,
      CAN_Price_List__c: partnerValues.CAN_Price_List__c,
      Customer_Type__c: partnerValues.Customer_Type__c,
      ERP_Account_Number__c: partnerValues.ERP_Account_Number__c,
      FOB__c: partnerValues.FOB__c,
      Jenks_Account_Id__c: partnerValues.Jenks_Account_Id__c,
      Payment_Terms__c: partnerValues.Payment_Terms__c,
      Pricing_Tier__c: partnerValues.Pricing_Tier__c,
      Surrey_Account_Id__c: partnerValues.Surrey_Account_Id__c,
    };

    if (partnerValues.Jenks_Account_Id__c) {
      this.createUpdateAccount(partnerValues.Jenks_Account_Id__c,
        partnerId,
        accountFields).subscribe();
    }

    if (partnerValues.Surrey_Account_Id__c) {
      this.createUpdateAccount(partnerValues.Surrey_Account_Id__c,
        partnerId,
        accountFields).subscribe();
    }

  }



  /**
   * Provides the read permission of any object to a Partner.
   * @param objectId - Id of the object.
   * @param partnerId - Id of the partner to get read permission.
   * @param permissions `object` containing permissions for Read, Update, and Delete
   * @returns an `Observable` to confirm the grant access as `boolean`
   */
  grantObjectAccessToPartner(
    objectId: string, partnerIds: string[],
    permissions: { canRead?: boolean, canUpdate?: boolean, canDelete?: boolean }): Observable<boolean> {
    return this.grantVisibility(objectId, CpqGrantToObjects.Partner, partnerIds, permissions);
  }

  /**
   * Provides the read permission of sales object to a Partner/User/Role/Group
   * @param objectType  - CPQ Object Type
   * @param objectId  - Object ID on which to change the visibility
   * @param toObject - Name of the Object to change the visibility for
   * @param toObjectId - ID of the Object to change the visibility for
   * @param permissions -`object` containing permissions for Read, Update, and Delete
   * @returns an `Observable` to confirm the grant access as `boolean`
   */
  grantVisibility(
    objectId: string,
    toObject: CpqGrantToObjects,
    toObjectIds: string[],
    permissions: { canRead?: boolean, canUpdate?: boolean, canDelete?: boolean }): Observable<boolean> {
    if (!objectId) {
      return throwError('A valid CPQ Object ID on which to change the visibility is required');
    }
    if (!toObject) {
      return throwError('A valid Name of the Object to change the visibility for is required');
    }
    if (!toObjectIds.length) {
      return throwError('A valid ID of the Object to change the visibility for is required');
    }
    const url = this.cpqUrl('visibility', objectId, toObject);
    const params = [];
    toObjectIds.forEach(objID => {
      const payload = {
        CanRead: permissions?.canRead,
        CanUpdate: permissions?.canUpdate,
        CanDelete: permissions?.canDelete,
        id: objID
      };
      params.push(payload);
    });

    return new Observable(observer => {
      const subscription = this.http.put<CpqObjectUpdateResult[]>(url, params, { withCredentials: true }).subscribe(
        resp => {
          if (resp.length > 0) {
            observer.next(true);
          } else {
            observer.next(false);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to grant object access.', err);
          observer.next(false);
          observer.complete();
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Returns the visibility access of a CPQ object
   * @param objectId Object ID on which to get the principals who have access.
   * @returns an Observable of access details as CpqVisibility
   */
  getCpqObjectVisibilty<T>(objectId: string): Observable<CpqVisibility<T>> {
    const url = this.cpqUrl('visibility', objectId);
    return this.http.get<CpqVisibility<T>>(url, { withCredentials: true });
  }

  /**
   * If No Account, Create a new Account. If Account exists, updates the account.
   * @param erpAccountNumber Account number as a `string`
   * @param partnerId Id of the partner as a `string`
   * @param cpqAccount an `object` with the Account object fields.
   * @returns an `Observable` confirms create/update account and granted accees to partner as a `boolean`
   */
  createUpdateAccount(erpAccountNumber: string, partnerId: string, cpqAccount: any): Observable<boolean> {
    return this.getAccountByAccountNumber(erpAccountNumber).pipe(
      switchMap(existingAccounts => {
        cpqAccount.AccountNumber = erpAccountNumber;

        if (existingAccounts.length > 0) {
          const accountObjectFields = [];
          for (const account of existingAccounts) {
            accountObjectFields.push({ Id: account.Id, ...cpqAccount });
          }
          return this.updateObject(accountObjectFields);
        } else {
          return this.createAccountObject(cpqAccount);
        }
      }),
      mergeMap(id => this.grantObjectAccessToPartner(id, [partnerId], { canRead: true, canUpdate: false, canDelete: false })
      ),
    );
  }

  validateOpportunityId(id: string): Observable<any> {
    return of(this.oppurtunityId === id ? id : this.oppurtunityId);
  }

  validateQuoteId(id: string): Observable<any> {
    return of(this.quoteId === id ? id : this.quoteId);
  }

  validateConfigId(id: string): Observable<any> {
    return of(this.configId === id ? id : this.configId);
  }

  createCorrelationId(): Observable<any> {
    return of(this.correlationId);
  }

  validateCorrelationId(id: string): Observable<any> {
    return of(this.correlationId === id ? id : this.correlationId);
  }

  /**
   * Opens a new configuration using the first (default) dataset
   * @param oppId the Opportunity Id as a `string`
   * @param quoteId the Quote Id as a `string`
   */
  openConfigurationDefaultDataset(
    oppId: string,
    quoteId: string
  ): Observable<CpqOpenConfigResult> {
    const returnObservable = new Observable<CpqOpenConfigResult>(observer => {
      let openSubscription: Subscription;
      const familySubscription = this.fetchFamilies(quoteId).subscribe(
        families => {
          const datasetGuid = families[0]?.id; // FIXME: Temporary workarond while TWG_DEV is not promoted
          if (datasetGuid) {
            openSubscription = this.openConfiguration(
              datasetGuid,
              oppId,
              quoteId
            ).subscribe(
              data => {
                observer.next(data);
                observer.complete();
              },
              err => {
                // Bubble up error opening configuration
                observer.error(err);
              }
            );
          } else {
            observer.error(
              `There were no valid datasets for quote: ${quoteId}`
            );
          }
        },
        err => {
          // Bubble up error fetching families
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => {
          familySubscription.unsubscribe();
          openSubscription.unsubscribe();
        }
      };
    });

    return returnObservable;
  }

  openConfiguration(
    familyId: string,
    oppId: string,
    quoteId: string
  ): Observable<CpqOpenConfigResult> {
    const url = this.cpqUrl('cpaas', 'configs');
    const options: object = {
      params: {
        ds_guid: familyId,
        opp_id: oppId,
        quote_id: quoteId,
        run_sys_sels: true
      },
      withCredentials: true
    };
    return this.http.get<CpqOpenConfigResult>(url, options);
  }

  /**
   * Opens a configuration on an existing product, saves the configuration, and closes the session.
   * @param productId as `string`
   * @returns Observable of success
   */
  resaveProduct(productId: string): Observable<boolean> {
    return this.reopenConfiguration(productId).pipe(
      mergeMap(
        configData => {
          return this.editProductConfiguration(configData.configId, configData.productId)
            .pipe(
              mergeMap(x => this.closeConfiguration(configData.configId))
            );
        })
    );
  }

  reopenConfiguration(productId: string): Observable<CpqReOpenConfigResult> {
    const url = this.cpqUrl('cpaas', 'configs', productId);
    const params = new HttpParams()
      .set('run_sys_sels', 'true')
      .set('update', 'latest'); // + '?alias=newalias');

    return new Observable(observer => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          resp => {
            if (!resp?.success) {
              console.log('reopen Configuration was unsuccessful', resp);
              observer.error('reopen Configuration was unsuccessful');
            } else {
              const result: CpqReOpenConfigResult = {
                productId: resp?.productId,
                configId: resp?.configId,
                readOnly: resp?.readOnly,
                currency: resp?.currency
              };
              // console.log('reopen Configuration was successful', result);
              observer.next(result);
              observer.complete();
            }
          },
          err => {
            console.log('Failed to reopen Configuration', err);
            observer.error('Failed to reopen Configuration');
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Save a configuration for the first time.
   * @param configId required `String` as the id of the config session
   * @param quoteId required `String` as the id of the target quote to hold the saved config
   * @return an `Observable` for the `CpqSaveConfigResult`
   */
  saveConfiguration(
    configId: string,
    quoteId: string
  ): Observable<CpqSaveConfigResult> {
    if (!configId) {
      return throwError('A valid config id is required');
    }
    if (!quoteId) {
      return throwError('A valid quote id is required');
    }

    const url = this.cpqUrl('cpaas', 'configs', configId);
    const payload = {
      quoteId
    };

    return new Observable(observer => {
      const subscription = this.http.post<any>(url, payload, { withCredentials: true }).subscribe(
        resp => {
          if (!resp?.success) {
            console.log('The save was unsuccessful', resp);
            observer.error('The save was unsuccessful');
          } else {
            const result: CpqSaveConfigResult = {
              productId: resp?.productId,
              quoteProductId: resp?.quoteProductId
            };

            observer.next(result);
            observer.complete();
          }
        },
        err => {
          console.log('Failed to Save Configuration', err);
          observer.error('Failed to Save Configuration');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  closeConfiguration(configId: string): Observable<boolean> {
    return this.loginService.getRfst().pipe(
      switchMap(rfst => {
        const url = this.cpqUrl('cpaas', 'configs', configId);
        const params = new HttpParams().set('rfst', rfst);
        return this.http.delete(url, { params, withCredentials: true });
      }),
      map(x => true),
      catchError(err => {
        console.warn('Issue with saving', err);
        return of(false);
      }),
    );
  }

  editProductConfiguration(configId: string, productId: string): Observable<boolean> {
    return this.loginService.getRfst().pipe(
      switchMap(rfst => {
        const url = this.cpqUrl('cpaas', 'configs', configId, productId);
        const params = new HttpParams().set('rfst', rfst);
        const httpOptions = {
          params,
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          withCredentials: true,
        };
        return this.http.put(url, null, httpOptions);
      }),
      map(x => true),
      catchError(err => {
        console.warn('Issue with saving', err);
        return of(false);
      }),
    );
  }

  /**
   * To get the URL for QuoteSummary system proposal of format type PDF
   * @param quoteId required `String` as the id of the target quote
   */
  getSystemProposalUrl(quoteId: string) {
    const url = this.cpqUrl('proposal', 'quotesummary', 'printable');
    const proposalURL = url + '?quoteId=' + quoteId;
    return proposalURL;
  }

  /**
   * To get the URL for Quote system proposal of format type PDF
   * @param proposalId required `String` as the id of the proposal object
   */
  getProposalUrl(proposalId: string): string {
    const url = this.cpqUrl('proposal', proposalId, 'printable');
    return url;
  }

  /**
   * Starts / submits workflow for the quote.
   * @param quoteId required `string` as the id of the quote to start
   */
  startWorkflow(quoteId: any): Observable<any> {
    if (!quoteId?.trim()) {
      return throwError('Missing required quoteId');
    }

    const url = this.cpqUrl('workflowprocess');
    const params = new HttpParams()
      .set('action', 'tr_request')
      .set('uid', quoteId);
    if ((this.tenantName !== Tenants.VWSPortal)) {
      return this.http.get(url, { params, withCredentials: true }).pipe(
        switchMap(x => this.updateQuoteData(quoteId))
      );
    }
    else {
      return this.http.get(url, { params, withCredentials: true });
    }
  }

  /**
   * Trigger export for a given object
   * @param id required `string` of the object's id
   */
  triggerExport(id: string): Observable<boolean> {
    const url = this.cpqUrl('quote', 'exportworkflow');
    const params = new HttpParams()
      .set('id', id);

    return new Observable<boolean>(observer => {
      const sub = this.http.get(url, { params, observe: 'body', responseType: 'text', withCredentials: true }).subscribe(reply => {
        // The reply will always be 200 and an XML. Must check result to determine if sucessful
        // Instead of completely parsing the XML this code 'cheats' and just looks for
        // the success statement '<results success="true">'

        if (reply.includes(`<results success="true">`)) {
          observer.next(true);
          observer.complete();

        } else {
          observer.next(false);
          observer.complete();
        }

      }, err => {
        // FPX has CORS set incorrectly and the response will indicate failure, but it actually succeeded
        observer.next(true);
        observer.complete();
      });

      return {
        unsubscribe: sub.unsubscribe,
      };

    });

  }

  observeCPQProgress(id: string): Observable<any> {
    if (!Boolean(id)) {
      return throwError('A valid Object id is required to check the progress');
    }

    const source$ = this.getProgress(id, CpqProcessType.PRODUCT_UPDATE);
    return source$.pipe(
      expand(res => res.status !== CpqProductUpdateStatus.COMPLETE ?
        source$ : empty())
    );
  }

  /**
   * Causes FPX Quoteline Products under the provided Opportunity or Quote
   * to be updated, if possible.
   * @param ids an array of `string` for objects to be updated
   */
  updateProducts(ids: string[]): Observable<CpqProductUpdate> {
    const body = {
      ids,
    };
    const url = this.cpqUrl('updateproducts');

    return this.http.post<CpqProductUpdate>(url, body, { withCredentials: true });
  }
  /**
   * Returns the current progress and status of an asynchronous process
   * @param objectId Id of the object that may be associated with the process
   * @param processType type of the process
   * @returns  the progress of the process associated with an object as an observable of CpqProgress.
   */
  getProgress(objectId: string, processType: CpqProcessType): Observable<CpqProgress> {
    const url = this.cpqUrl('getprogress');
    const params = new HttpParams()
      .set('type', processType)
      .set('objectid', objectId);

    return this.http.get<CpqProgress>(url, { params, withCredentials: true });
  }

  /************ Internal Methods ********/


  cpqUrl(...args: string[]): string {
    let url = `${this.backendUrl}/cpq`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += '/' + args[i];
      }
    }

    return url;
  }

  imagePath(imagePath: string) {
    return this.cpqUrl('cpq', imagePath);
  }

  /**
   * Returns all the accounts visible to the current user
   */
  getCpqAccounts(): Observable<CpqAccount[]> {
    const url = this.cpqUrl('object', 'accounts');
    return this.http.get<CpqAccount[]>(url, { withCredentials: true });

  }
  /**
   * return the external account information of the opportunity
   * @param oppurtunityId string of opportunity id
   */
  getExternalAccountFromOpp(oppurtunityId: string): Observable<OppAccountResult[]> {
    const url = `${this.backendUrl}/cpq/getExternalAccountFromOpp`;
    const params = new HttpParams()
      .set('oppId', oppurtunityId);
    return this.http.get<OppAccountResult[]>(url, { params, withCredentials: true });

  }

  /**
   * Obtains the CPQ query results for the specfied object type
   * @param objectType - specify object type like [accounts, roles, profiles, users, partners, opportunities,
   *    opportunityquotes, quotes, quoteproducts]; REQUIRED
   * @param Id - Id of the object to fetch from like quote ID, opportunity Id; OPTIONAL
   * @param addonFields - Specify additional SELECT field of the object; OPTIONAL
   * @param filters - Specify the WHERE criteria for the query; OPTIONAL
   * @param sort - Specify the ORDER BY criteria for the query; OPTIONAL
   * @param limit - Specify the LIMIT criteria for the query; OPTIONAL
   * @param resolveNames - Specify true/false to resolve the names for all the reference fields; OPTIONAL
   * @param batchsize - Specify the number of records to return. The default is 50. The maximum setting is 500; OPTIONAL
   * @param quotes_addonFields - Specify the Subquery additional fields for Quote object, applicable only for "opportunityquotes" ; OPTIONAL
   * @param quoteLines_addonFields - Specify the Sbquery additional fields for QuoteLine object,
   *    applicable only for "quoteproducts" ; OPTIONAL
   * @param quoteProducts_addonFields - Specify the Sbquery additional fields for QuoteConfiguredProduct object,
   *    applicable only for "quoteproducts" ; OPTIONAL
   */

  //TO BE REMOVED CREATED getCpqObjectsCDS
  getCpqObjects<T>(
    objectType: CpqQueryObjects,
    parameters?: CpqQueryOptions): Observable<T[]> {
    let url = '';

    switch (objectType) {
      case CpqQueryObjects.Accounts:
      case CpqQueryObjects.Partners:
      case CpqQueryObjects.Roles:
      case CpqQueryObjects.Users:
      case CpqQueryObjects.Profiles:
      case CpqQueryObjects.Opportunities:
      case CpqQueryObjects.Quotes:
      case CpqQueryObjects.Favorites:
        url = this.cpqUrl('object', objectType, parameters?.Id);
        break;

      case CpqQueryObjects.OpportunityQuotes:  // FIXME
        if (!(parameters?.Id)) {
          return throwError('A valid opportunity Id is required');
        }
        url = this.cpqUrl('deepobject', 'opportunities', parameters?.Id, 'quotes');
        break;

      case CpqQueryObjects.QuoteProducts:
        if (!(parameters?.Id)) {
          return throwError('A valid Quote Id is required');
        }
        url = this.cpqUrl('deepobject', 'quotes', parameters?.Id, 'products');
        break;

      default:
        return throwError('Please verify the specified Object Type as its invalid');
    }

    let params = new HttpParams();
    if (parameters) {
      Object.keys(parameters).forEach(key => {
        if (!(key === 'Id')) {
          params = params.set(key, parameters[key]);
        }
      });
    }

    const apiResult = (!parameters?.Id) ? this.streamQuery<T>(url, params) : this.http.get<T[]>(url, { params, withCredentials: true });

    return apiResult;
  }

  /**
 * get product list
 * @param keyword - searched keyword
 * @returns as the product list
 */
  getProducts<T>(keyword?: string): Observable<T[]> {
    const url = this.cpqUrl(CpqQueryObjects.Favorites);
    const params = new HttpParams().set("keyword", keyword);

    return new Observable((observer) => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          (resp) => {
            if (resp) {
              observer.next(resp);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to get the Product details", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

/**
 * Add clone of normal quote to the special library quote list [preconfigured-quotes]
 * @param quoteId - quote id
 */
  addToPreconfiguredQuotes(quoteId: string, payload?:any) {
    if (!quoteId) {
      return throwError('A valid Quote Id is required');
    }
    const url = this.cpqUrl(CpqQueryObjects.Favorites, quoteId);
    return new Observable<string[]>(observer => {
      const subscription = this.http.post<any>(url, payload, { withCredentials: true }).subscribe({
        next: resp => {
          if (resp?.Id) {
            observer.next(resp);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        error: err => {
          console.log('Failed to add quote to Favorites', err);
          observer.error(err);
        }
      });
      return {
        unsubscribe: () => subscription.unsubscribe()
      };

    });
  }

/**
 * update pre-configured quote
 * @param payload payload
 */
  updatePreconfiguredQuotes(quoteId: string, payload?: any) {
    if (!quoteId) {
      return throwError('A valid Quote Id is required');
    }
    const url = this.cpqUrl(CpqQueryObjects.Favorites, quoteId);
    return new Observable<string[]>(observer => {
      const subscription = this.http.put<any>(url, payload, { withCredentials: true }).subscribe({
        next: resp => {
          if (resp?.Id) {
            observer.next(resp);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        error: err => {
          console.log('Failed to update quote', err);
          observer.error(err);
        }
      });
      return {
        unsubscribe: () => subscription.unsubscribe()
      };

    });
  }

  /**
   * delete pre-configured quote
   * @param payload payload
   */
  deletePreconfiguredQuotes(quoteId: string) {
    if (!quoteId) {
      return throwError('A valid Quote Id is required');
    }
    const url = this.cpqUrl(CpqQueryObjects.Favorites, quoteId);
    return new Observable<string[]>(observer => {
      const subscription = this.http.delete<any>(url,{ withCredentials: true }).subscribe({
        next: resp => {
          observer.next(resp);
          observer.complete();
        },
        error: err => {
          console.log('Failed to delete quote', err);
          observer.error(err);
        }
      });
      return {
        unsubscribe: () => subscription.unsubscribe()
      };

    });
  }

  /**
* get product categories
* @param keyword - searched keyword
* @returns as the product list
*/
getProductCategories<T>(): Observable<T[]> {
  const url = this.cpqUrl(CPQ_API.PRODUCT, CPQ_API.CATEGORIES);
  
  return this.http.get<T[]>(url, { withCredentials: true }).pipe(
    catchError((err) => {
      console.error("Failed to get the Product categories", err);
      return throwError(() => err); // Emit an error Observable
    })
  );
}

  private streamQuery<T>(cpqUrl: string, paramsObj: HttpParams): Observable<T[]> {
    const urlWithParams = cpqUrl + '?' + paramsObj.toString();

    const observable = new Observable<T[]>(observer => {
      const eventSource = new EventSource(urlWithParams, {
        withCredentials: true
      });

      eventSource.onmessage = res => {
        /**
         * Angualr has open bug to handle EventStream data
         * Using NgZone as a work around to fix the issue.
         * Link: https://github.com/angular/angular/issues/31745
         */
        this.zone.run(() => {
          observer.next(JSON.parse(res.data));
        });
      };

      eventSource.onerror = err => {
        eventSource.close();
        this.zone.run(() => {
          observer.error(err);
        });
      };

      // Below function to close event source to stop streaming
      eventSource.addEventListener('close', () => {
        eventSource.close();
        this.zone.run(() => {
          observer.complete();
        });
      });

      return {
        unsubscribe: () => {
          observer.complete();
          eventSource.close();
        }
      };
    });

    return observable;
  }

  /** @deprecated Use `addFavorites` instead */
  addFavourites(opportunityId: string, quoteId: string, favouriteIds: string[]): Observable<string[]> {
    return this.addFavorites(opportunityId, quoteId, favouriteIds);
  }

  addFavorites(opportunityId: string, quoteId: string, favoriteIds: string[]): Observable<string[]> {
    if (!opportunityId) {
      return throwError('A valid Opportunity Id is required');
    }

    if (!quoteId) {
      return throwError('A valid Quote Id is required');
    }

    if (!favoriteIds) {
      return throwError('A valid Product Id or Id`s is required');
    }

    const url = this.cpqUrl(CPQ_API.ADD_FAVORITES);
    const payload = {
      opportunityId,
      quoteId,
      productIds: favoriteIds
    };

    return new Observable<string[]>(observer => {
      const subscription = this.http.post<CpqAddFavoritesResult>(url, payload, { withCredentials: true }).subscribe({
        next: resp => {
          if (resp?.productIds) {
            observer.next(resp.productIds);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        error: err => {
          console.log('Failed to add products from Favorites', err);
          observer.error(err);
        }
      });

      return {
        unsubscribe: () => subscription.unsubscribe()
      };

    });
  }

  saveFavorite(productId: string, displayName: string, isPublic: boolean = false): Observable<string> {
    if (!productId) {
      return throwError('A valid Product Id is required');
    }

    if (!displayName) {
      return throwError('A valid Display Name is required');
    }

    const url = this.cpqUrl(CPQ_API.SAVE_FAVORITE);
    const payload = {
      productId,
      displayName,
      isPublic
    };

    return new Observable<string>(observer => {
      const subscription = this.http.post<CpqObjectUpdateResult>(url, payload, { withCredentials: true })
        .subscribe({
          next: resp => {
            if (resp?.Id) {
              observer.next(resp.Id);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          error: err => {
            console.log('Failed to make Favourite product', err);
            observer.error(err);
          }
        });

      return {
        unsubscribe: () => subscription.unsubscribe()
      };

    });
  }
  /**
   * Queries for the Account object id for a given Account Number
   * @param accNumber `string` of the Account Number
   * @returns `Observable` of account id as `string`
   */
  getAccountByAccountNumber(accNumber: string): Observable<any[]> {
    if (!accNumber) {
      return throwError('A valid Account Number is required');
    }
    const url = this.cpqUrl('object', 'accounts');  // FIXME: magic strings
    const params = new HttpParams()
      .set('filters', `AccountNumber='${accNumber}'`);

    return new Observable<any>(observer => {
      const subscription = this.http.get(url, { params, withCredentials: true }).subscribe(
        results => {
          observer.next(results);
          observer.complete();
        },
        err => {
          console.log('Failed to fetch the AccountId', err);
          observer.error('Failed to fetch the AccountId');
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  getAllCountries() {
    return new Observable<Country[]>(observer => {
      const countriesUrl = COUNTRIES_API + '?fields=' + COUNTRIES_FIELDS_TO_FETCH.join(',');
      const subscription = this.http.get(countriesUrl).subscribe({
        next: (data: Country[]) => {
          observer.next(data);
          observer.complete();
        },
        error: err => {
          console.log('Failed to fetch Countries', err);
          observer.error('Failed to fetch Countries');
        }
      });
      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Delete the existing quote configured products if any when
   *  User want to go with blank canvas
   *      OR
   *  By using Favorites
   */
  deleteExistingConfiguredProductsIfAny(revisionID): Observable<CpqApiResult> {
    return this.getCpqObjects<CpqApiResult>(
      CpqQueryObjects.QuoteProducts,
      {
        Id: revisionID,
      }).pipe(
        switchMap((res: any) => {
          if (res?.QuoteConfiguredProducts?.length > 0) {
            const productIds = [];
            res.QuoteConfiguredProducts.forEach(product => {
              productIds.push(product.Id);
            });
            return this.deleteObjects(productIds);
          } else {
            const obj = {
              success: true,
              results: []
            };
            return of(obj);
          }
        })
      );
  }

  /** CDS APIs**/

  getCpqObjectsCDS<T>(objectType: CpqObjects): Observable<T> {
    let url = "";
    switch (objectType) {
      case CpqObjects.Accounts:
      case CpqObjects.Partners:
      case CpqObjects.Roles:
      case CpqObjects.Users:
      case CpqObjects.Profiles:
      case CpqObjects.Opportunities:
      case CpqObjects.Quotes:
      case CpqObjects.Favorites:
      case CpqObjects.QuoteLine:
        url = this.cpqUrl("object", objectType);
        break;

      default:
        return throwError(
          "Please verify the specified Object Type as its invalid"
        );
    }

    const apiResult = this.http.get<T>(url, { withCredentials: true });
    return apiResult;
  }

  getCpqObjectByIdCDS<T>(objectType: CpqObjects, objectId: string, parameters?: CpqQueryOptions): Observable<T> {
    if (!objectId) {
      return throwError('A valid ID is required to get the Object');
    }

    if (!objectType) {
      return throwError('A valid Object type required to get the Object');
    }

    let params = new HttpParams();
    if (parameters) {
      Object.keys(parameters).forEach(key => {
        params = params.set(key, parameters[key]);
      });
    }
    const url = this.cpqUrl('object', objectType, objectId);

    return new Observable(observer => {
      const subscription = this.http.get<any>(url, { params, withCredentials: true }).subscribe(
        resp => {
          if (resp?.Id) {
            observer.next(resp);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to get the Object', err);
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Method to update the CPQ object
   * @param objectId a `string` of the object id like quoteid, opportunityid, userId..etc
   * @param objectFields an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the object id `string`
   */
  updateObjectById(
    objectType: CpqObjects,
    objectId: string,
    objectFields: any,
    parameters?: CpqObjectParams
  ): Observable<any> {
    if (!objectId) {
      return throwError("A valid ID is required to update the Object");
    }

    if (!objectType) {
      return throwError("A valid Object type required to update the Object");
    }
    let params = new HttpParams();
    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        params = params.set(key, parameters[key]);
      });
    }
    const url = this.cpqUrl("object", objectType, objectId);

    return new Observable((observer) => {
      const subscription = this.http
        .put<any>(url, objectFields, { params, withCredentials: true })
        .subscribe(
          (resp) => {
            if (resp?.Id) {
              observer.next(resp);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to update the Object", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
 * Exports a CPQ Opportunity data into the linked CRM Opportunity
 * @param CpqOpportunityId - ID of the CPQ opportunity to linked from CRM. Only Opportunity, primary Quote data can be exported from the linked CPQ Opportunity
 * @param cpqQuoteId - ID of the CPQ primary Quote; OPTIONAL
 * @returns the staus of export from CPQ to CRM
 */
  exportToCrm(CpqOpportunityId: string): Observable<any> {
    const url = this.cpqUrl(CpqOpportunityId, CPQ_API.CRM, CPQ_API.EXPORT);
    return this.http.post<any>(url, { withCredentials: true });
  }
  
  /**
   * Function to delete a specific object of specified object type
   * @param objectType - Type of the object
   * @param objectId  - Id of the object to delete
   * @returns
   */
  deleteObjectByObjectId(
    objectType: CpqObjects,
    objectId: string
  ): Observable<boolean> {
    const url = this.cpqUrl("object", objectType, objectId);

    return new Observable((observer) => {
      const subscription = this.http
        .delete(url, { withCredentials: true })
        .subscribe({
          next: () => {
            observer.next(true);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Function to COPY a specific object of specified object type
   * @param objectType - Type of the object
   * @param objectId  - Id of the object to delete
   * @param newCopyName - new name of the object
   * @returns
   */
  copyObjectById(
    objectType: CpqObjects,
    objectId: string,
    payload: any
  ): Observable<any> {
    const url = this.cpqUrl("object", objectType, objectId);
    return this.http.post<any>(url, payload, { withCredentials: true });
  }

  /**
 * Method to create the CPQ Partner
 * @param partnerFields - Object variable has the required fields to create Partner.
 * @return an `Observable` for the CPQ PartnerId
 */
  createPartnerId(partnerFields: any): Observable<any> {
    const url = this.cpqUrl("object", CpqObjects.Partner);
    return new Observable((observer) => {
      const subscription = this.http
        .post<any>(url, partnerFields, { withCredentials: true })
        .subscribe(
          (resp) => {
            observer.next(resp);
            observer.complete();
          },
          (err) => {
            console.log("Failed to Create CPQ Partner", err);
            observer.error(err);
          }
        );
      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
  * To get all the CPQ Roles
  */
  getCPQRoles(isPartner: boolean): Observable<any> {
    const url = this.cpqUrl("object", CpqObjects.Roles);
    const params = new HttpParams().set("partner", isPartner.toString());
    return this.http.get(url, { params, withCredentials: true });
  }

  /**
  * To get all the CPQ Profiles
  */
  getCPQProfiles(isPartner: boolean): Observable<any> {
    const url = this.cpqUrl("object", CpqObjects.Profiles);
    const params = new HttpParams().set("partner", isPartner.toString());
    return this.http.get(url, { params, withCredentials: true });
  }

  /**
  * To update account detail
  */
  updateAccount(accountId: string, AccountObj: CpqAccount): Observable<string> {
    return this.updateObjectById(CpqObjects.Account, accountId, AccountObj).pipe(
      tap({ next: () => this.accountsUpdated$.next() })
    );
  }

  /**
 * CDS
 * To get a Quote data for proposal generation
 * @param quoteId required `String` as the id of the quote object
 */
  getQuoteProposalData(
    quoteId: string,
    proposalName?: string
  ): Observable<any> {
    const url = this.cpqUrl("quote", quoteId, CPQ_API.PROPOSAL);
    // const params = new HttpParams().set("name", proposalName);

    return this.http.get<any>(url, {
      // params,
      withCredentials: true,
    });
  }

  getVWSOpportunityStatus = (opportunity: any) => {
    let opportunityStatus;
    if (opportunity.PrimaryQuoteId == null && opportunity.CrmExportStatus !== CPQ_EXPORT_STATUS.SUBMITTED) {
      opportunityStatus = JOB_STATUS.INACTIVE;

    } else if (opportunity.PrimaryQuoteId != null && opportunity.CrmExportStatus === CPQ_EXPORT_STATUS.SUBMITTED) {
      opportunityStatus = JOB_STATUS.SUBMITTED;

    } else if (opportunity.PrimaryQuoteId != null && opportunity.CrmExportStatus !== CPQ_EXPORT_STATUS.SUBMITTED) {
      const primaryQuote = opportunity.PrimaryQuote
      const currentDate = new Date();
      if (primaryQuote && new Date(primaryQuote.ExpirationTime) > currentDate) {
        opportunityStatus = JOB_STATUS.ACTIVE;
      } else {
        opportunityStatus = JOB_STATUS.INACTIVE;
      }
    }
    return opportunityStatus;
  }

  /**
 * CDS
 * To get a Config data
 */
  getConfigOptions(): Observable<any> {
    const url = this.cpqUrl(CPQ_API.CONFIG, CPQ_API.OPTIONS);
    return this.http.get<any>(url, { withCredentials: true });
  }

}
