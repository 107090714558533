import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HubspotDialogComponent, HubspotForms } from '../../../app/content/hubspot-modal/hubspot-modal.component';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { MSALService } from '../services/msal.service';

enum Tenants {
  VWSPortal = 'VWS'
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, AfterViewChecked {
  isGuest: false;
  isVWSPortal = false;

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private msalService: MSALService
  ) { }

  ngOnInit() {
    const tenantName = this.msalService.getTenantName();
    this.isVWSPortal = (tenantName === Tenants.VWSPortal);
  }

  navigateBack() {
    this.location.back();
  }

  ngAfterViewChecked() {
    const loggedInUser = this.guestService.getGuestUserDetails();
    this.isGuest = loggedInUser.isGuest;
  }
  /**
  * Opens a material modal to show Hubspot forms
  */
  openHubspotSupport() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.ContactSupport
      }
    });
  }

  /**
  * Opens a material modal to show Hubspot forms
  */
  openHubspotSales() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.ContactSales
      }
    });
  }
}
