<div class="applyMargin">
  <span *ngIf="showDescription(groupData)">
    <strong matTooltipPosition="above" matTooltip={{groupData.HoverOver}}>{{ showGroup ? groupData.desc : "" }}</strong>
  </span>
</div>
<div [ngSwitch]="true" fxLayout="column">
  <!-- <label *ngIf="groupData.desc"><b>{{groupData.desc}}</b></label> -->
  <div *ngSwitchDefault class="option-container" fxLayout.lt-md="column">
    <app-options fxFlex="30%" *ngFor="let item of groupData.options; trackBy: optionTracker" [optionData]="item"
      (valueChange)="changeDetected($event)" class="option-object"></app-options>
  </div>
  <div *ngSwitchCase="renderSelectBox(groupData)">
    <app-dropdown-option (valueChange)="changeDetected($event)" [groupName]="groupData.label"
      [optionData]="groupData.options">
    </app-dropdown-option>
  </div>
  <div *ngSwitchCase="renderRadioGroup(groupData)">
    <app-radio (valueChange)="changeDetected($event)" [groupName]="groupData.name" [optionData]="groupData.options">
    </app-radio>
  </div>
  <div *ngSwitchCase="renderRadioButton(groupData)">
    <app-radio-option (valueChange)="changeDetected($event)" [groupName]="groupData.name"
      [optionData]="groupData.options">
    </app-radio-option>
  </div>
  <div *ngSwitchCase="'DROP'">
    <app-dropdown-option [groupName]="groupData.name" [optionData]="groupData.options"></app-dropdown-option>
  </div>
  <div [ngClass]="{'logical-buttons-group' : groupData.CustomView == 'LogicalChoice'}"
    *ngSwitchCase="displayButtonGroups(groupData)">
    <app-button-group (valueChange)="changeDetected($event)" [groupName]="groupData.name"
      [optionData]="groupData.options">
    </app-button-group>
  </div>
  <div *ngSwitchCase="renderLabel(groupData)">
    <app-label [optionData]="groupData.options"></app-label>
  </div>
</div>

<div *ngIf="groupData.subGroups" [class]="{'displayFlex' : applyFlexClass(groupData) }">
  <!-- Itterate again if group has subGroups -->
  <app-group [class]="{'applyMarginRight' : applyFlexClass(groupData) }"
    *ngFor="let subGroups of groupData.subGroups; trackBy: groupTracker" [groupData]="subGroups"
    (optionChanged)="changeDetected($event)"></app-group>
</div>