import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { AppConfig } from '@cpq-app/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService } from '@cpq-app/services/cart.service';
import { forkJoin, of } from 'rxjs';
import { HeaderService } from './header.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { StatusCodes } from 'http-status-codes';
import { environment } from '@cpq-environments/environment';

export enum SessionVariables {
  PERSONA = 'persona',
  USER_TYPE = 'userType',
  OPCO_NAME = 'opcoName',
  ORGANIZATION_ID = 'organizationId',
  NAVIGATED_TO_BEFORELOGIN_URL = 'navigatedToBeforeLoginUrl'
}

const DELAY_TIME_BEFORE_AUTO_LOGOUT = 2000; // in mS
const TOASTER_TIME_OUT = 5000; 

@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent implements OnInit {

  headerData = {};
  userName;
  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private msalService: MSALService,
    private userService: UsersService,
    private headerService: HeaderService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private guestService: GuestConfigService,
    private logoutService: LogoutService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.getUserDetails(loggedInUserAdInfo.oid).subscribe(res => {
      if (res['statusCode'] !== StatusCodes.OK) {
        this.alertService.error(
          `${res['message']}`
        );
      } else {
        let userData = res['data'];
        let headerData;
        if (userData === null) {
          if ( environment.B2CConfigs.superAdminOid !== loggedInUserAdInfo.oid && loggedInUserAdInfo.OpcoName !== sessionStorage.getItem(SessionVariables.OPCO_NAME)) {
            this.toaster.error('User is not registered within application.');
            setTimeout(() => {
              this.logoutService.logout();
            }, DELAY_TIME_BEFORE_AUTO_LOGOUT);
          }
        }
        this.getHeaderDetails(loggedInUserAdInfo.oid).subscribe(res1 => {
          if ( res1['statusCode'] !== StatusCodes.OK) {
            this.alertService.error(`${res1['message']}`);
          } else {
            headerData = res1['data'];
            this.guestService.setGuestUserDetails(userData);
            let userRole = '';
            headerData['user'] = {};
            if (userData === null && environment.B2CConfigs.superAdminOid === loggedInUserAdInfo.oid) {
              userRole = 'Super Admin';
              let userName = loggedInUserAdInfo.given_name + ' ' + loggedInUserAdInfo.family_name;
              this.headerService.setUsername(userName);
              headerData['user']['userType'] = 'superadmin';
            } else {
              sessionStorage.setItem(SessionVariables.ORGANIZATION_ID, userData['organizationId']);
              userRole = userData['personaName'];
              headerData['user']['userType'] = userData['userType'];
              let userName = userData['firstName'] + ' ' + userData['lastName'];
              this.headerService.setUsername(userName);
              if (userData.isActive !== true) {
                this.toaster.error('User is inactive. Please contact admin in order to login.','',{
                  timeOut: TOASTER_TIME_OUT
                });
                setTimeout(() => {
                  this.logoutService.logout();
                }, DELAY_TIME_BEFORE_AUTO_LOGOUT);
              } else {
                if (userData['privacyTerm'] === null || userData['privacyTerm']?.isAccepted === false) {
                  this.router.navigate(['/privacy-terms']);
                }
              }
              this.updateLastLoginData(userData);
            }
            this.headerService.getUserName().subscribe((name) => {
              this.userName = sessionStorage.getItem(SessionVariables.USER_TYPE) ? name + `(${sessionStorage.getItem(SessionVariables.USER_TYPE)})` : name;
            });
            const userType = headerData['user']['userType'];
            this.setSessionStorageData(userRole, userType);
            headerData['user']['role'] = userRole;
            headerData['user']['pic'] =
              userRole === 'Super Admin'
                ? './assets/images/administrator-profile-pic.png'
                : userData?.profilePic?.blobUri;

            if(userRole === 'DistributorAdmin') {
              headerData['menuItems'].forEach(function(menuItem) {
                menuItem.subMenus = menuItem.subMenus.filter(subMenu => subMenu.identifier !== 'ORGANIZATIONS');
              });
            }
            this.headerData = headerData;
          }
        });
      }
      this.spinner.hide();
    });
  }

  updateLastLoginData(user) {
    user.lastLoggedIn = new Date().toISOString();
    this.userService.EditProfile(user).subscribe((res) => {},
    err => {
       this.toaster.error(err.message);
    });
  }

  setSessionStorageData(userRole: string, userType: string) {
    sessionStorage.setItem(SessionVariables.PERSONA, userRole);
    sessionStorage.setItem(SessionVariables.USER_TYPE, userType);
    this.userService?.setUserLoadedSub();
  }

  getUserDetails(loggedInUserOid) {
    return this.userService.getUser(loggedInUserOid);
    ;
  }

  getHeaderDetails(loggedInUserOid) {
    return this.headerService.getHeader(loggedInUserOid);
  }

}


