import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IFamilies, VwsCpqFavorite, VwsCpqQuote, VwsFavoritesFilter } from '../VWS.interfaces.service';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ProductService } from '@cpq-app/services/product.service';

export enum ProductConfiguration {
  PRODUCT = 'product',
  NAME = 'Name',
  BLANK_CARD_NAME = 'Blank',
  BLANK_CARD_IMAGE_NAME = 'blank-tunnel.jpg'
}

@Component({
  selector: 'app-revision-start-form',
  templateUrl: './revision-start-form.component.html',
  styleUrls: ['./revision-start-form.component.scss']
})
export class RevisionStartFormComponent implements OnInit {
  familiesDatasets: IFamilies[];
  cardConfigurationData: VwsCpqFavorite[] = [];
  jobID: string;
  revisionID: string;
  filteredProductCardConfigData: VwsCpqFavorite[];
  filteredProducts: VwsCpqFavorite[] = [];
  searchString: string;
  enableNoMatchFound = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private productService: ProductService
  ) {
    this.route.params.subscribe(params => {
      if (params?.jobID) {
        this.jobID = params.jobID;
      }
      if (params?.revisionID) {
        this.revisionID = params.revisionID;
      }
    });
  }

  ngOnInit(): void {
    this.fetchDataSets();
  }

  addBlankCardToFilteredProducts(datasetId: string) {
    const blankCard: VwsCpqFavorite = {
      Id: datasetId,
      Name: ProductConfiguration.BLANK_CARD_NAME,
      graphicUrl__c: ProductConfiguration.BLANK_CARD_IMAGE_NAME,
    };
    this.filteredProductCardConfigData.unshift(blankCard);
  }

  mapConfigProductCards(datasetId = '') {
    if (datasetId === '') {
      const [firstCanvasData] = this.familiesDatasets;
      datasetId = firstCanvasData.id;
    }
    this.filteredProductCardConfigData = this.cardConfigurationData.filter(card =>
      card.ConfigDatabaseName === datasetId
    );
    this.addBlankCardToFilteredProducts(datasetId);
  }

  fetchDataSets() {
    this.familiesDatasets =
      [
        {
          addToQuote: false,
          description: "OPW - Belanger",
          id: "6436190a4456b832:60cd86d8:16fe636c3e8:-7fa9|0",
          imageUrl: "https://resources.mynewsdesk.com/image/upload/t_open_graph_image/ibgxgvdbggdnxprwynpt.jpg",
          name: "Conveyorized Tunnel Systems"
        }
      ]

    this.cardConfigurationData = []
    this.mapConfigProductCards();
    this.applyFilters();
    this.spinner.hide();

    // fetchFamilies
    // getCpqObjects -> Favorites
  }

  getFavoritesFilter(): VwsFavoritesFilter {
    const filterObj: VwsFavoritesFilter = {
      filters: VwsFavoritesFilter.filterFields.join('='),
      addonFields: VwsCpqFavorite.queryFields.join(','),
      sort: ProductConfiguration.NAME,
      resolveNames: 'true'
    }
    return filterObj;
  }

  onFavoriteClick(id: string) {
    this.router.navigate([this.jobID, this.revisionID, 'choose', 'configuration'],
      { queryParams: { update: ProductConfiguration.PRODUCT, productID: '44a0000uhfus78uy' } }
    );
    // deleteExistingConfiguredProductsIfAny
    // addFavorites
  }

  onBlankCardClick(id: string): void {
    this.router.navigate([this.jobID, this.revisionID, 'choose', 'results']);
    // deleteExistingConfiguredProductsIfAny
    // openConfiguration
  }

  onCardClick(card: VwsCpqFavorite) {
    (card.Name === ProductConfiguration.BLANK_CARD_NAME) ? this.onBlankCardClick(card.Id) : this.onFavoriteClick(card.Id);
  }


  getImage(imageName: string): string {
    return this.productService.getImageUrl(imageName);
  }

  applyFilters() {
    try {
      const searchStr = this.searchString?.trim().toLowerCase() || '';
      this.filteredProducts = this.filteredProductCardConfigData?.filter(item => item?.Name.toLowerCase().indexOf(searchStr) > -1);
    } finally {
      this.enableNoMatchFound = true;
    }
  }

}
