import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-thank-you-modal',
  templateUrl: './thank-you-modal.component.html',
  styleUrls: ['./thank-you-modal.component.scss']
})
export class ThankYouModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ThankYouModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  done(): void {
    this.dialogRef.close({ sucesss: true });
  }

}
