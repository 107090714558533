<div class="table-header d-flex justify-content-between">
    <div class="search-box">
        <form>
            <!-- searchbox, Page title and buttons -->
            <div class="d-flex">
                <span class="vws-header mt-2 mr-3">{{pageHeaderInformation?.title}}</span>
                <div class="form-group form-inline mr-1">
                    <i class="fa fa-search search-icon"></i>
                    <input class="form-control ml-2 search-input" [(ngModel)]="searchTerm" placeholder="Search ..."
                        type="text" [formControl]="filter" />
                </div>
                <ng-container *ngFor="let key of pageHeaderInformation?.buttons">
                    <button type="button" class="ml-2" [ngClass]="key?.className" (click)="buttonClicked(key?.field)"
                        [disabled]="getDisableStatus(key)">
                        <i *ngIf="key?.icon" class="mr-2" [ngClass]="key?.icon"></i>
                        <span>{{key?.title}}</span>
                    </button>
                </ng-container>
            </div>

            <!-- <span class="ml-3" *ngIf="loading$ | async">Loading...</span> -->
        </form>
    </div>
    <!-- Pagination -->
    <div class="pagination-section d-flex justify-content-between">
        <div class="">
            <span class="m-2">Showing</span>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="30">30</option>
            </select>
            <span class="m-2">Per Page</span>
        </div>
        <div class="mr-4">
            <span class="d-flex justify-content-between">
                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [pageSize]="pageSize">
                </ngb-pagination>
                <span class="m-2">of {{(total$ | async)!}} records</span>
                <span *ngIf="showDataLoading" class='m-2'>
                    <i class="fa fa-spinner fa-pulse fa-fw"></i>
                </span>
            </span>
        </div>
    </div>
</div>
<table class="table  datatableComponent">
    <thead>
        <tr>
            <th [ngClass]="key.isPrimary ? 'primary-column-'+count : 'other-column-'+count" scope="col"
                *ngFor="let key of ColData; count as count; trackBy:trackByIndex" [hidden]="!key.visibility">
                <span *ngIf="key.sortable else staticcolumn" [sortable]='key.field' (sort)="onSort($event)"
                    class="sortable">{{key.header}}</span>
                <ng-template #staticcolumn> <span>{{key.header}}</span> </ng-template>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let record of objects$ | async; index as i; trackBy:trackByIndex">
            <td *ngFor="let key of ColData; trackBy:trackByIndex" [hidden]="!key.visibility">
                <span *ngIf="key.field!=='actions'; else elseActionBlock">
                    <span *ngIf="!key.hasOwnProperty('control'); else elseControlBlock">
                        <ng-container *ngIf="!key.isEditable; else editBlock">
                            <span [class]='key.className(record)'
                                [ngClass]="isOrderPlaced ? 'disable_pointer_events' :  'pointer'"
                                *ngIf="key.hasOwnProperty('handler'); else elseHandlerBlock"
                                (click)="key.handler(record)">
                                <ng-container
                                    *ngTemplateOutlet="displayColumnValue; context:{ $implicit: key, item:record }">
                                </ng-container>
                            </span>
                        </ng-container>
                        <ng-template #editBlock>
                            <span *ngIf="!record.isEdited">
                                <ngb-highlight [result]="key.value(record)" [term]="filter.value"></ngb-highlight>
                                <!-- <i class="fa fa-pencil cursor ml-2" (click)="record.isEdited = !record.isEdited"></i> -->
                            </span>
                            <div *ngIf="record.isEdited" class="d-flex align-items-center">
                                <input class="form-control mr-2" [ngModel]="key.value(record)"
                                    [value]="key.value(record)" (change)="updateValue($event)">
                                <i (click)="key.handler(record, editedName); record.isEdited = !record.isEdited; editedName = undefined"
                                    class="fa fa-check pointer mr-2" aria-hidden="true" title="update"></i>
                                <i (click)="record.isEdited = !record.isEdited" class="pointer fa fa-times"
                                    aria-hidden="true" title="Close"></i>
                            </div>
                        </ng-template>
                        <ng-template #elseHandlerBlock>
                            <ng-container
                                *ngTemplateOutlet="displayColumnValue; context:{ $implicit: key, item:record }">
                            </ng-container>
                        </ng-template>
                        <!-- Common template to display column data -->
                        <ng-template #displayColumnValue let-key let-record="item">
                            <span *ngIf="isSmallDevice">
                                <span class="small-device-header">{{key.header}} :</span>
                                <span>
                                    <ngb-highlight [result]="key.value(record)" [term]="filter.value"></ngb-highlight>
                                </span>
                            </span>
                            <span *ngIf="!isSmallDevice">
                                <!-- show colors around the text or show color indication beside the text -->
                                <ng-container
                                    *ngIf="key.hasOwnProperty('showColorIndicator') && key.showColorIndicator; else textWithBackgroundColor">
                                    <ngb-highlight [result]="key.value(record)" [term]="filter.value">
                                    </ngb-highlight>
                                    <span [title]="key.hasOwnProperty('titleText') ? key.titleText(record) : '' "
                                        [class]="key.hasOwnProperty('className') ? key.className(record) : '' "></span>
                                </ng-container>
                                <ng-template #textWithBackgroundColor>
                                    <ngb-highlight
                                        [class]="key.hasOwnProperty('className') ? key.className(record) : '' "
                                        [title]="key.hasOwnProperty('titleText') ? key.titleText(record) : '' "
                                        [result]="key.value(record)" [term]="filter.value">
                                    </ngb-highlight>
                                </ng-template>
                            </span>
                        </ng-template>
                    </span>
                    <ng-template #elseControlBlock>
                        <div *ngIf="key.control != 'toggle'">
                            <span *ngIf="isSmallDevice" class=" ">{{key.header}} :</span>
                            <input type={{key.control}} (change)="key?.handler(record, $event.target.checked)"
                                [checked]=(record[key.field])
                                [ngClass]="record[key.field] ? 'disablePointerEvents' :  'pointer'"  />
                        </div>
                        <div *ngIf="key.control === 'toggle' && record[key.field]!== null">                           
                            <span *ngIf="isSmallDevice" class=" ">{{key.header}} :</span>
                            <label class="switch">
                                <input type="checkbox" (change)="key?.handler(record, $event.target.checked)"
                                    [checked]=(record[key.field])
                                    [ngClass]="{'disablePointerEvents' : record[key.field]}">
                                <div class="slider round">
                                    <span class="on">Yes</span>
                                    <span class="off">No</span>
                                </div>
                            </label>
                        </div>
                    </ng-template>
                </span>
                <ng-template #elseActionBlock>
                    <span *ngIf="isSmallDevice" class="small-device-header">{{key.header}} :</span>
                    <div class="arrange-action-icons">
                        <div [class.col-1]=isSmallDevice class="icon"
                            *ngFor="let option of key.value(record); trackBy:trackByIndex"
                            title="{{option?.tooltip || option?.action}}">
                            <ng-container *ngIf="option?.action === singleEdit; else otherActions">
                                <i class="fa fa-pencil pointer" (click)="record.isEdited = !record.isEdited"></i>
                            </ng-container>
                            <ng-template #otherActions>
                                <span *ngIf="option?.hrefHandler == undefined" class="{{option?.action}}"
                                    [ngClass]="(key?.disableActionProperty === option?.action && key?.disable(record)) || (isOrderPlaced && option?.action !== 'download') ? 'disable_pointer_events' :  'pointer'"
                                    (click)="option?.handler()"></span>
                                <a *ngIf="option?.hrefHandler != undefined" target="_blank" class="{{option?.action}}"
                                    [ngClass]="(key?.disableActionProperty === option?.action && key?.disable(record)) || (isOrderPlaced && option?.action !== 'download')  ? 'disable_pointer_events' :  'pointer'"
                                    [href]="option?.hrefHandler()"></a>
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
            </td>
        </tr>
        <tr *ngIf="showLoading && showDataLoading">
            <td class="noRecords" colspan="8">Loading...</td>
        </tr>
        <tr *ngIf="hasNoData && !showLoading && !showDataLoading">
            <!-- Condition for search and initial values-->
            <td class="noRecords" colspan="8">No records found.</td>
        </tr>
    </tbody>
</table>