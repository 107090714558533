import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '@cpq-app/shared/services/shared.service';
import { VwsCpqAccount } from '@vws-app/VWS.interfaces.service';
import { Country } from '@cpq-app/tenants/Cpq.interfaces.service';
import { RouteNames } from '@vws-app/VWS';
import { Subscription } from 'rxjs';
import { country } from '@cpq-app/country';

export enum ACCOUNT_PAGE_TITLES {
  ADD_ACCOUNT = 'Add New Account',
  EDIT_ACCOUNT = 'Edit Account'
}

const INITIAL_COUNTRY = 'USA';
@Component({
  selector: 'app-create-accounts',
  templateUrl: './create-accounts.component.html',
  styleUrls: ['./create-accounts.component.scss']
})
export class CreateAccountsComponent implements OnInit, OnDestroy {
  accountCreationForm: UntypedFormGroup;
  submitted = false;
  jobID: string;
  revisionID: string;
  configID: string;
  accountID: string;
  data: VwsCpqAccount;
  countriesList: Country[];
  subscriptions$: Subscription[] = [];
  pageTitle = ACCOUNT_PAGE_TITLES.ADD_ACCOUNT;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cartService: CartService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
  ) {
    this.cartService.selectedHeaderInfo$.next(RouteNames.ACCOUNTS);
  }

  ngOnInit(): void {
    this.subscriptions$.push(this.route.params.subscribe(params => {
      if (params) {
        this.getAllCountries();
        this.jobID = params.jobID;
        this.revisionID = params.revisionID;
        this.configID = params.configID;
        this.accountID = params.accountId;
        this.createAccountForm();
        if (this.accountID != null) {
          this.updateAccountValues();
          this.pageTitle = ACCOUNT_PAGE_TITLES.EDIT_ACCOUNT;
        }
      }
    }));
  }

  getAllCountries() {
    this.subscriptions$.push(this.cartService.getAllCountries().subscribe({
      next: (data: Country[]) => {
        const country = data.find(country => country.alpha3Code === INITIAL_COUNTRY);
        this.countriesList = data;
        this.countriesList.unshift(country);
      },
      error: err => {
        console.log(err);
        this.countriesList = country;
        this.countriesList.forEach((country) => {
          country.alpha3Code = country.sortname;
        });
      }
    }));
  }

  createAccountForm() {
    const zipCode: RegExp = new RegExp(/^[a-z0-9]+([-_\s]{1}[a-z0-9]+)*$/i);
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.accountCreationForm = this.formBuilder.group({
      Id: [{ value: null, disabled: true }],
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(100)]],
      AccountNumber: [null, Validators.pattern('^[0-9]*$')],
      ExternalId: [null],
      Website: [null],
      Email__c: [null, [Validators.pattern(nonWhiteSpaceRegExp), Validators.email]],
      BillingCity: [null],
      BillingCountry: [null],
      BillingPostalCode: [null, [Validators.pattern(zipCode)]],
      BillingState: [null],
      BillingStreet: [null],
      PricingTier: [null],
      Fax__c: [null],
      Phone__c: [null],
      ShippingCity: [null],
      ShippingCountry: [null],
      ShippingPostalCode: [null, [Validators.pattern(zipCode)]],
      ShippingState: [null],
      ShippingStreet: [null],
      Terms_Conditions__c: [null]
    });
  }

  updateAccountValues() {
    try {
      this.subscriptions$.push(this.cartService.getCpqObjectByIdCDS<VwsCpqAccount>(CpqObjects.Account, this.accountID).subscribe({
        next: res => {
          console.log('Account response', res);
          this.data = res;
          this.accountCreationForm.patchValue({
            Id: this.data.Id,
            Name: this.data.Name,
            AccountNumber: this.data.AccountNumber,
            PricingTier: this.data?.PricingTier,
            Phone__c: this.data?.Phone__c,
            Fax__c: this.data?.Fax__c,
            Email__c: this.data?.Email__c,
            Terms_Conditions__c: this.data.Terms_Conditions__c,
            Website: this.data.Website,
            ExternalId: this.data.ExternalId,

            BillingCity: this.data.BillingAddress.City,
            BillingCountry: this.data.BillingAddress.Country,
            BillingPostalCode: this.data.BillingAddress.PostalCode,
            BillingState: this.data.BillingAddress.State,
            BillingStreet: this.data.BillingAddress.Street,
          
            ShippingCity: this.data.ShippingAddress.City,
            ShippingCountry: this.data.ShippingAddress.Country,
            ShippingPostalCode: this.data.ShippingAddress.PostalCode,
            ShippingState: this.data.ShippingAddress.State,
            ShippingStreet: this.data.ShippingAddress.Street,


          });
          this.spinner.hide();
        },
        error: err => {
          console.log(err);
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }


  onSubmitAccount() {
    try {
      this.submitted = true;
      const obj = {
        Id: this.accountCreationForm.controls.Id.value,
        Name: this.accountCreationForm.controls.Name.value,
        AccountNumber: this.accountCreationForm.controls.AccountNumber.value,
        PricingTier: this.accountCreationForm.controls.PricingTier.value,
        Phone__c: this.accountCreationForm.controls.Phone__c.value,
        Fax__c: this.accountCreationForm.controls.Fax__c.value,
        Email__c: this.accountCreationForm.controls.Email__c.value, 
        Terms_Conditions__c: this.accountCreationForm.controls.Terms_Conditions__c.value, 
        ExternalId: this.accountCreationForm.controls.ExternalId.value,
        Website: this.accountCreationForm.controls.Website.value,
        ShippingAddress: {
          Street: this.accountCreationForm.controls.ShippingStreet.value,
          City: this.accountCreationForm.controls.ShippingCity.value,
          State: this.accountCreationForm.controls.ShippingState.value,
          PostalCode: this.accountCreationForm.controls.ShippingPostalCode.value,
          Country: this.accountCreationForm.controls.ShippingCountry.value,
        },
        BillingAddress: {
          Street: this.accountCreationForm.controls.BillingStreet.value,
          City: this.accountCreationForm.controls.BillingCity.value,
          State: this.accountCreationForm.controls.BillingState.value,
          PostalCode: this.accountCreationForm.controls.BillingPostalCode.value,
          Country: this.accountCreationForm.controls.BillingCountry.value,
        }
      }
      console.log('account- payload', obj);

      if (this.accountCreationForm.valid) {
        if (this.data?.Id != null) {
          this.updateAccount(obj);
        } else {
          this.createAccount(obj);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }



  updateAccount(obj) {
    this.spinner.show();
    this.subscriptions$.push(this.cartService.updateAccount(this.data.Id, obj).subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Account updated successfully');
        this.router.navigate(['accounts']);
      }, err => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while updating', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    ));
  }

  createAccount(obj) {
    this.spinner.show();
    this.subscriptions$.push(this.cartService.createObject(CpqObjects.Account, obj).subscribe(
      (res) => {
        this.toastr.success('Account created successfully');
        this.navigateToPreviousPage();
      }, err => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while Creating New Account', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    ));
  }

  copyBillingAddress(chkEvent: Event) {
    const sameAddressCopyCheckbox = chkEvent.target as HTMLInputElement;
    if (sameAddressCopyCheckbox.checked) {
      try {
        const formData = this.accountCreationForm;
        formData.controls.ShippingCity.setValue(formData.value.BillingCity);
        formData.controls.ShippingCountry.setValue(formData.value.BillingCountry);
        formData.controls.ShippingPostalCode.setValue(formData.value.BillingPostalCode);
        formData.controls.ShippingState.setValue(formData.value.BillingState);
        formData.controls.ShippingStreet.setValue(formData.value.BillingStreet);
      } catch (error) {
        console.log(error);
      }
    }
  }

  navigateToPreviousPage() {
    if (Boolean(this.revisionID)) {
      this.router.navigate([this.jobID, this.revisionID, 'choose', 'revision']);
    } else {
      this.router.navigate(['accounts']);
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }
}
