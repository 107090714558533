<div [formGroup]="accountCreationForm">
    <div class="vws-header mb-4 mt-4"><span>{{pageTitle}}</span>
        <span class="float-right mr-3">
            <span class="mr-4">
                <button type="button" class="account-button" (click)="navigateToPreviousPage()">Back</button>
            </span>
            <span>
                <button type="submit" [disabled] = "accountCreationForm.invalid" class="account-button" (click)="onSubmitAccount()">Save</button>
            </span>
        </span>
    </div>
    <div class="account-block">
        <div class="basic-details">
            <div class="mb-4"><span class="sub-header">Basic Details</span></div>
            <div class="same-row-controls">
                <div class="form-group required child-control">
                    <label for="Name" class="control-label">Account Name</label>
                    <input type="text" class="form-control" id="Name" formControlName="Name" placeholder=""
                        [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['Name'].dirty || accountCreationForm.controls['Name'].touched || submitted) && accountCreationForm.controls['Name'].errors }">
                    <div *ngIf="accountCreationForm.controls['Name'].invalid && (accountCreationForm.controls['Name'].dirty || accountCreationForm.controls['Name'].touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="accountCreationForm.controls['Name'].errors.required">Account Name is required</div>
                        <div *ngIf="accountCreationForm.controls['Name'].errors.pattern">
                            Account Name must be a character</div>
                        <div *ngIf="accountCreationForm.controls['Name'].errors.maxlength">Account Name must contain max
                            of
                            100 characters</div>
                    </div>
                </div>
                <div class="form-group child-control">
                    <label for="AccountNumber">Account Number</label>
                    <input type="text" class="form-control" id="AccountNumber" formControlName="AccountNumber"
                        placeholder=""
                        [ngClass]="{ 'is-invalid':  accountCreationForm.controls['AccountNumber'].errors }" />
                    <div *ngIf="accountCreationForm.controls['AccountNumber'].invalid" class="invalid-feedback">
                        <div>Account Number must be a number</div>
                    </div>
                </div>
            </div>
            <div></div>
            <div class="same-row-controls">
                <div class="form-group child-control">
                    <label for="Phone">Phone</label>
                    <input type="text" class="form-control" id="Phone" formControlName="Phone__c" placeholder="">
                </div>
                <div class="form-group child-control">
                    <label for="Fax">Fax</label>
                    <input type="text" class="form-control" id="Fax" formControlName="Fax__c" placeholder="">
                </div>
                <div class="form-group child-control">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" formControlName="Email__c" placeholder=""
                        [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['Email__c'].dirty || accountCreationForm.controls['Email__c'].touched || submitted) && accountCreationForm.controls['Email__c'].errors }">
                    <div *ngIf="accountCreationForm.controls['Email__c'].invalid && (accountCreationForm.controls['Email__c'].dirty || accountCreationForm.controls['Email__c'].touched || submitted)"
                        class="invalid-feedback">
                        <div
                            *ngIf="( accountCreationForm.controls['Email__c'].errors.email) || accountCreationForm.controls['Email__c'].errors.pattern">
                            Please enter a valid email
                        </div>

                    </div>
                </div>
            </div>
            <div class="same-row-controls">
                <div class="form-group child-control">
                    <label for="PricingTier">Customer Type</label>
                    <select class="form-control" id="PricingTier" formControlName="PricingTier">
                        <option value="null" disabled>Customer Type</option>
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Bronze">Bronze</option>
                    </select>
                </div>
                <div class="form-group child-control">
                    <label for="Website">Website</label>
                    <input type="text" class="form-control" id="Website" formControlName="Website" placeholder="">
                </div>
            </div>
            <div class="form-group">
                <label for="termsandConditions">Terms and Conditions</label>
                <textarea class="form-control large-text-area" id="termsandConditions" rows="3" placeholder=""
                    formControlName="Terms_Conditions__c"></textarea>
            </div>

        </div>
        <div class="vertical-row"></div>
        <div class="shipping-details">
            <div class="mb-4 shipping-block">
                <span class="shipping-header">
                    <span class="sub-header">Shipping Details</span>
                </span>
                <span class="same-address">
                    <input type="checkbox" id="isBillingShippingSame" (change)="copyBillingAddress($event)"
                        class="mr-1">
                    <label for="isBillingShippingSame">Same as Billing Address</label>
                </span>
            </div>
            <div class="form-group">
                <div class="same-row-controls">
                    <div class="form-group child-control">
                        <label for="BillingStreet">Billing Street</label>
                        <textarea class="form-control small-text-area" id="BillingStreet" rows="3" placeholder=""
                            formControlName="BillingStreet"></textarea>
                    </div>
                    <div class="form-group child-control small-text-area">
                        <label for="ShippingStreet">Shipping Street</label>
                        <textarea class="form-control small-text-area" id="ShippingStreet" rows="3" placeholder=""
                            formControlName="ShippingStreet"></textarea>
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class="form-group child-control">
                        <label for="BillingCity">Billing City</label>
                        <input type="text" class="form-control" id="BillingCity" formControlName="BillingCity"
                            placeholder="">

                    </div>
                    <div class="form-group child-control">
                        <label for="ShippingCity">Shipping City</label>
                        <input type="text" class="form-control" id="ShippingCity" formControlName="ShippingCity"
                            placeholder="">
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class="form-group child-control">
                        <label for="BillingState">Billing State</label>
                        <input type="text" class="form-control" id="BillingState" formControlName="BillingState"
                            placeholder="">
                    </div>
                    <div class="form-group child-control">
                        <label for="ShippingState">Shipping State</label>
                        <input type="text" class="form-control" id="ShippingState" formControlName="ShippingState"
                            placeholder="">
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class="form-group child-control">
                        <label for="BillingPostalCode">Billing Postal Code</label>
                        <input type="text" class="form-control" id="BillingPostalCode"
                            formControlName="BillingPostalCode" placeholder=""
                            [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['BillingPostalCode'].dirty || accountCreationForm.controls['BillingPostalCode'].touched || submitted) && accountCreationForm.controls['BillingPostalCode'].errors }">
                        <div *ngIf="accountCreationForm.controls['BillingPostalCode'].invalid && (accountCreationForm.controls['BillingPostalCode'].dirty || accountCreationForm.controls['BillingPostalCode'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="(accountCreationForm.controls['BillingPostalCode'].errors.pattern)">
                                Billing Postal Code is invalid </div>
                        </div>
                    </div>
                    <div class="form-group child-control">
                        <label for="ShippingPostalCode">Shipping Postal Code</label>
                        <input type="text" class="form-control" id="ShippingPostalCode"
                            formControlName="ShippingPostalCode" placeholder=""
                            [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['ShippingPostalCode'].dirty || accountCreationForm.controls['ShippingPostalCode'].touched || submitted) && accountCreationForm.controls['ShippingPostalCode'].errors }">
                        <div *ngIf="accountCreationForm.controls['ShippingPostalCode'].invalid && (accountCreationForm.controls['ShippingPostalCode'].dirty || accountCreationForm.controls['ShippingPostalCode'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="(accountCreationForm.controls['ShippingPostalCode'].errors.pattern)">
                                Shipping Postal Code is invalid </div>
                        </div>
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class="form-group child-control">
                        <label for="BillingCountry">Billing Country</label>
                        <select class="form-control" id="BillingCountry" formControlName="BillingCountry">
                            <option value="null" disabled>select Country</option>
                            <option *ngFor="let country of countriesList" value={{country.alpha3Code}}>{{country.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group child-control">
                        <label for="ShippingCountry">Shipping Country</label>
                        <select class="form-control" id="ShippingCountry" formControlName="ShippingCountry">
                            <option value="null" disabled>select Country</option>
                            <option *ngFor="let country of countriesList" value={{country.alpha3Code}}>{{country.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>