<div class="card">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-sm-3 col-md-3">
                <img src="{{featureTileInfo.img_path}}">
            </div>
            <div class="col-sm-9 col-md-9 pt-2 align-middle">
                <p class="feature-text">{{featureTileInfo.title}}</p>
            </div>
        </div>
    </div>
</div>