<div class="configuration-container">
    <div class="header-container">
        <div class="breadcrumb-container">
         
            <div class="active breadcrumb-text">
                <span class="vws-header ml-3">{{breadCrumbHeader}}</span>&nbsp;&nbsp;
                <a class="parent" (click)="gotoJobsSummary()">Jobs</a>&nbsp;
                <span class="fa fa-chevron-right" aria-hidden="true"></span>&nbsp;
                <span [ngClass]="{'allow-click': inEditMode}" (click)="gotoJobDetails()">{{jobName}}</span>&nbsp;
                <span class="fa fa-chevron-right" aria-hidden="true"></span>&nbsp;
                {{configName}}
            </div>
        </div>
        <div class="stepper-container">
            <ng-container *ngFor="let item of steps; index as i; last as isLast">
                <a class="step" [ngClass]="applyStatusClassToStepper(i+1)">
                 <span class="stepper-circle" *ngIf="routeID <= i+1 else elseBlock">{{i+1}}</span>
                    <ng-template #elseBlock>
                    <span class="stepper-circle"><i class="fa fa-check" aria-hidden="true"></i></span>
                    </ng-template>
                </a>
                <span class="stepper-label">{{item.data.routeName}}</span>
                <div *ngIf="!isLast" class="line"></div>
            </ng-container>
        </div>
        <div class="buttons-container">
            <button *ngIf="showCancelButton()" class="btn btn-light" (click)="onClickCancel()">
                <span class="fa fa-times mr-2" aria-hidden="true"></span>
                Cancel
            </button>
            <button *ngIf="showPreviewButton()" class="btn btn-light vws-preview-btn" (click)="onClickPreview()">
                <span class="fa fa-file-text-o" aria-hidden="true">                   
                </span>
                Preview <i class="fa fa-spinner fa-spin" *ngIf="btnSpinner"></i>
            </button>
            <button *ngIf="showBackButton()" class="vws-navigation-btn" (click)="onClickBack()">
                <span class="fa fa-chevron-left mr-2" aria-hidden="true"></span>
                Back
            </button>
            <button *ngIf="showNextButton()" class="vws-navigation-btn" (click)="onClickNext()">
                Next
                <span class="fa fa-chevron-right ml-2" aria-hidden="true"></span>
            </button>
            <button *ngIf="showFinishButton()" class="vws-navigation-btn" (click)="onClickFinish()">
                <span class="fa fa-check mr-2" aria-hidden="true"></span>
                Complete
            </button>
        </div>
    </div>
    <div class="horizontal-line"></div>
    <router-outlet></router-outlet>
</div>