import { Injectable } from '@angular/core';
import {  TunnelOptions, VwsCpqQuote } from './VWS/VWS.interfaces.service';

@Injectable({
    providedIn: 'root',
})
export class CPQInterfacesService {

    constructor() { }
}

//opportunity
export interface CpqJob {
    //LastModifiedDate?: string;
    Description?: string;
    AccountId?: AccountId;
    ExternalId?: string;
    PrimaryQuoteId?: string;
    IsClosed?: boolean;
    CurrencyIsoCode?: string;
    Name?: string;
    CreatedById: CreatedById;
    CreatedDate?: string;
    Id?: string;
    Note__c: string;
    PrimaryQuote: VwsCpqQuote;
    ModifiedTime: string;
    Owner: Owner,
    Account: { Name: string },
    Quotes: VwsCpqQuote[]
    CrmExportStatus: CPQ_EXPORT_STATUS,
    CrmId:string
}

export enum CPQ_EXPORT_STATUS {
    SUBMITTED = 'Completed',
    NOT_EXPORTED = 'NotExported'
};


export interface Owner {
    AddressId: number;
    Email: string;
    Id: string;
    Name: string;
    PartnerId: string;
    RoleId: string;
    Title: string;
}

export interface CpqAccount {
    Id: string;
    Name: string;
    Website: string;
    ExternalId: string;
    AccountNumber: string;
    BillingAddress: Address,
    ShippingAddress: Address,
}

interface Address{
    Id: number,
    CreatedTime: string,
    ModifiedTime: string,
    CreatedBy: string,
    ModifiedBy: string,
    Street: string,
    City: string,
    State: string,
    PostalCode: string,
    Country: string
}
export interface AccountId {
    Id?: string;
    Name?: string;
}

export interface CreatedById {
    Id?: string;
    Name?: string;
}

export interface Owner {
    Id: string;
    Name: string;
    Email: string;
}

export interface CpqAccounts {
//    LastModifiedDate: string;
    ModifiedTime: string;
    Owner?: Owner;
    ExternalId?: string;
    Id: string;
    Name: string;
    AccountNumber?: string;
}

export interface Acl {
    canEdit: boolean;
    canDelete: boolean;
}

export interface Status {
    statusLabel: string;
}

export interface Wf {
    canExport: boolean;
    canAddQuote: boolean;
    canAddProduct: boolean;
    showMoreInfo: boolean;
    canEdit: boolean;
    canMakePrimary: boolean;
    canCopy: boolean;
    canDelete: boolean;
    canAddProposal: boolean;
    canPreview: boolean;
    status: Status;
}

export interface FpRules {
    acl: Acl;
    updateAvailable: boolean;
    isDependentDataOutOfSync: boolean;
    wf: Wf;
}

export interface Proposals {
    size: number;
}
export interface CpqProfiles {
    Id: string;
    Name: string;
    PermissionSsoUser?: boolean;
}
export interface CpqRoles {
    id: string;
    name: string;
}

export class CpqFavorite {
    Id: string;
    Name: string;
    CurrencyIsoCode?: string;
    LastModifiedDate?: string;
    OrderCode?: string;
    TotalList?: number;
    ConfigDatabaseName?: string;
}

export interface Country {
    name: string;
    alpha3Code?: string;
    sortname?: string;
}


export interface Format {
    LanguageLocale: string;
    FormatLocale: string;
    IsoCode: string;
}
export interface Partner {
    Name: string;
    Id: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Phone: string;
    Fax: string;
    PartnerLogo__c: string;
    TermsConditions__c?: any;
    LocaleSidKey: string;
    Image: string;
}

export interface User {
    Name: string;
    Title: string;
    Phone: string;
    Fax: string;
    Email: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    LanguageLocaleKey: string;
    LocaleSidKey: string;
    DefaultCurrencyIsoCode: string;
    isPartnerQuote: boolean;
    Partner: Partner;
}

export interface Owner {
    Name: string;
    Title: string;
    Phone: string;
    Fax: string;
    Email: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    LanguageLocaleKey: string;
    LocaleSidKey: string;
    DefaultCurrencyIsoCode: string;
}

export interface QuoteLineEntry {
    Option_ShowInProposal: boolean;
    Option_Sequence: number;
    Option_Description: string;
    Option_Quantity: number;
    Option_ListPrice: number;
}

export interface QuoteLine {
    Id: string;
    BaseListPrice: number;
    BaseSellingPrice: number;
    CreatedDate: Date;
    Description: string;
    Quantity: number;
    Name: string;
    OrderCode: string;
    ListPrice: number;
    UnitListPrice: number;
    UnitSellingPrice: number;
    OptionsSellingPrice: number;
    TotalOptionsSellingPrice: number;
    TotalBaseSellingPrice: number;
    TotalSellingPrice: number;
    Product_Sequence: number;
    Product_Name: string;
    Product_Description: string;
    Product_OrderCode: string;
    Product_Quantity: number;
    Product_BaseListPrice: number;
    Product_ExtendedTotalList: number;
    Product_TotalList: number;
    TotalNetPrice__c?: any;
    QuoteLines: QuoteLine[];
    QuoteLineEntries: QuoteLineEntry[];
    NetPrice: number;
}
export interface Standard {
    subTotal: number;
    subTotalNet: number;
    categories: Categories;
}

export interface Discount {
    part: string;
    name: string;
    value: string;
}
export interface Part {
    BaseCost: number;
    BaseListPrice: number;
    CategoryDescription__c: string;
    ClonedFromId?: any;
    CreatedById: string;
    CreatedDate: Date;
    CurrencyIsoCode: string;
    Description: string;
    ExtendedDescription: string;
    ExtendedTotalCost: number;
    ExtendedTotalList: number;
    ExternalId?: any;
    graphicUrl__c: string;
    Id: string;
    IsDependentDataOutOfSync: boolean;
    Key: string;
    LastModifiedById: string;
    LastModifiedDate: Date;
    Name: string;
    OpportunityId?: any;
    OptionsCost: number;
    OptionsListPrice: number;
    OrderCode: string;
    OwnerId: string;
    ParentProductId: string;
    ProductType: string;
    Quantity: number;
    QuoteId?: any;
    RootProductId: string;
    Sequence: number;
    ShowInProposal: boolean;
    TotalCostPrice: number;
    TotalList: number;
    DiscountedPrice__c: number;
    DiscountedPercentage__c: number;
    UpdateStatus: string;
    WorkflowStatus: string;
    configSummary: string;
    discounts: Discount[];
}

export interface Categories {
    name: string;
    parts: Part[];
}
export interface Additional {
    subTotal: number;
    subTotalNet: number;
    categories: Categories;
}

export interface QuoteLinesWithNestedPartsByCategory {
    Name: string;
    Id: string;
    TotalSellingPrice: number;
    TotalDiscountedPrice: number;
    TotalDiscountedAmount: number;
    TunnelOptions: TunnelOptions;
    Standard: Standard;
    Additional: Additional;
}

export interface Quote {
    Name: string;
    Id: string;
    FormattedId: string;
    Description?: any;
    Note?: any;
    CreatedDate: Date;
    ExpirationDate: Date;
    TotalAmount: number;
    CurrencyIsoCode: string;
    FreightAmount__c?: any;
    TaxAmount__c: number;
    InvoiceDiscountPercent__c: number;
    MaterialSurcharge__c?: any;
    CustomerView__c: boolean;
    TotalDiscountedPrice__c: number;
    TotalDiscountedAmount__c: number; 
    QuoteLine: QuoteLine[];   
    QuoteLinesWithNestedPartsByCategory: QuoteLinesWithNestedPartsByCategory[];
    DiscountAmount: number;
    TotalQuotePrice: number;
    SpecialDiscountAmount: number;
    PricingGroups?: PricingGroup[];
    PowerSupply__c?: string;
    BuildingLength__c?: number;
    TotalDiscountsAmount?: number;
}

export interface Opportunity {
    Name: string;
    Id: string;
    AccountId?: any;
    OwnerId: string;
    CreatedDate: Date;
    LastModifiedDate: Date;
    LanguageLocaleKey: string;
    LocaleSidKey: string;
    CurrencyIsoCode: string;
    Owner: Owner;
    Quote: Quote;
}
export interface Proposal {
    Name: string;
    CreatedDate: Date;
    FormatType: string;
}
export interface Organization {
    Name: string;
    Phone: string;
    Fax: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
}

export interface Format {
    LanguageLocale: string;
    FormatLocale: string;
    IsoCode: string;
    CurrencyPattern: string;
}
export interface Result {
    proposalPreview: ProposalPreview;
}
export interface ProposalPreview {
    CurrentDate: Date;
    User: User;
    Opportunity: Opportunity;
    Proposal: Proposal;
    Organization: Organization;
    Format: Format;
    result: Result;
}

export interface PricingGroup {
    category: Category;
}

export interface Category {
    Name: string;
    Groups: Group[];
    TotalSellingPrice: number;
}

export interface Group {
    Name: string;
    Items: GroupItem[];
    TotalSellingPrice: number;
}


export interface GroupItem {
    Name: string;
    OptionsSummary: string;
    TotalSellingPrice: number;
}


