import { Component, Inject, AfterContentInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';

declare var hbspt: any;
declare var $: any; //Importing Jquery form global.

export enum HubspotForms {
  RequestAccess,
  ContactSales,
  ContactSupport,
}

@Component({
  selector: 'app-hubspot-dialog',
  templateUrl: './hubspot-modal.component.html',
  styleUrls: ['./hubspot-modal.component.scss']
})
export class HubspotDialogComponent implements AfterContentInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<HubspotDialogComponent>,
    private userService: UsersService,
    private msalService: MSALService,
  ) { }

  ngAfterContentInit() {
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.userService.getUser(loggedInUserAdInfo.oid).subscribe((res) => {
      if (res['statusCode'] !== 200) {
        this.displayHubspotPopup(null);
      } else {
        this.displayHubspotPopup(res['data']);
      }
    });
  }

  displayHubspotPopup(userData){
    hbspt.forms.create({
      portalId: '5670619',
      formId: this.getFormId(this.data.formName),
      target: '#hubspotFormContent',
      onFormReady: function($form) {
        if(userData !== null || typeof userData === typeof undefined){
          $('input[name="firstname"]', $form).val(userData?.firstName).change();
          $('input[name="lastname"]', $form).val(userData?.lastName).change();
          $('input[name="email"]', $form).val(userData?.email).change();
          if(userData?.ownPhoneNumber != "" && userData?.ownPhoneNumber != null)
          $('input[name="phone"]', $form).val(userData?.ownPhoneNumber).change();
          if(userData?.organization?.organizationName != "" && userData?.organization?.organizationName != null)
          $('input[name="company"]', $form).val(userData?.organization?.organizationName).change();
        }
      }
    });
  }

  getFormId(formName: HubspotForms): string {
    switch (formName) {
      case HubspotForms.RequestAccess:
        return '4335caa2-43d7-4645-a269-3214f0db71b6';

      case HubspotForms.ContactSales:
        return 'f612df01-5fbb-4202-bd54-7f0b89bd03bb';

      case HubspotForms.ContactSupport:
      default:
        return 'dd6198b2-d0db-42cc-ae3c-9981210e3b17';
    }
  }

  closeModel() {
    this.dialogRef.close();
  }
}
