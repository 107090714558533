import { Injectable } from '@angular/core';
import { MSALService } from '../services/msal.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private msalService: MSALService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const jobID = route.params['jobID'];    
    if(jobID && jobID !== 'Logout' && jobID !== 'null') {
      sessionStorage.setItem('jobID', jobID);
    }
    if (this.msalService.isOnline()) {
      localStorage.setItem('logged_in', 'true');
      const jobID = sessionStorage.getItem('jobID');
      if(state.url === '/' && jobID && jobID !== 'undefined' && jobID !== 'null') {
        sessionStorage.removeItem('jobID');
        this.router.navigateByUrl(`job/${jobID}`);
      }
      sessionStorage.removeItem('jobID');
      return true;
    }
    this.msalService.login(window.location.pathname);
    return false;
  }
}
