import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { VwsCpqOpportunity } from '@vws-app/VWS.interfaces.service';
import { Subscription } from 'rxjs';
import { RouteNames } from '@vws-app/VWS';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewComponent } from '@cpq-app/tenants/preview/preview.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { STEPPER_CHILD_ROUTES } from '@vws-app/vws-routing.module';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { ProductService } from '@cpq-app/services/product.service';
import { ProposalPreview } from '@cpq-app/tenants/Cpq.interfaces.service';

export enum CURRENT_RECORD_STATE {
  RECORD_STATE = 'recordState',
  EDIT_VIEW = 'editView',
  CREATE_VIEW = 'createView'
}

enum BREADCRUMB_INFO {
  NEW_JOB = 'New Job',
  EDIT_QUOTE = 'Edit Quote',
  NEW = 'New'
}

@Component({
  selector: 'app-configuration-component',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  jobDetails: VwsCpqOpportunity;
  subscriptions$: Subscription[] = [];
  steps = [];
  jobID: string;
  revisionID: string;
  routeName: string;
  currentUrl: string;
  routeID: number;
  recordState: string;
  breadCrumbHeader: string = BREADCRUMB_INFO.NEW_JOB;
  inEditMode = false;
  jobName: string = BREADCRUMB_INFO.NEW;
  configName: string = BREADCRUMB_INFO.EDIT_QUOTE;
  customerView = false;
  proposalPreview: ProposalPreview;
  btnSpinner = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService: UsersService,
    private productService: ProductService,
  ) {
    this.subscriptions$.push(this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((currentRoute: RouterEvent) => {
      this.currentUrl = currentRoute.url;
      const routeData = this.route.snapshot?.firstChild?.data;
      this.routeName = routeData.routeName;
      this.routeID = routeData.childRouteId;
      // this.getChildRouteParams();
    }));
  }

  ngOnInit(): void {
    this.subscribeToCustomerView();
    this.steps = STEPPER_CHILD_ROUTES;
    this.getInitialData();
  }

  getInitialData() {
    this.subscriptions$.push(this.route.params.subscribe({
      next: params => {
        this.jobID = params.jobID;
        this.revisionID = params.revisionID;
        this.recordState = sessionStorage.getItem(CURRENT_RECORD_STATE.RECORD_STATE);
        this.getJobDetails(params?.jobID);
      }
    }));
  }

  isRecordInEditMode(): boolean {
    return this.recordState === CURRENT_RECORD_STATE.EDIT_VIEW;
  }

  getBreadcrumbHeader(): string {
    return this.isRecordInEditMode() ? BREADCRUMB_INFO.EDIT_QUOTE : BREADCRUMB_INFO.NEW_JOB;
  }

  getRecordState(): string {
    return this.isRecordInEditMode() ? this.jobDetails?.Name : BREADCRUMB_INFO.NEW;
  }

  getRecordName(): string {
    return this.isRecordInEditMode() ? BREADCRUMB_INFO.EDIT_QUOTE : this.jobDetails?.Name;
  }

  getChildRouteParams() {
    // const [route] = this.route?.children;
    // this.subscriptions$.push(route.params.subscribe({
    //   next: params => {
    //     this.configID = params.configID;
    //   }
    // }));
  }

  applyStatusClassToStepper(index: number): string {
    if (index < this.routeID) {
      return 'completed';
    } else if (index === this.routeID) {
      return 'active';
    } else {
      return '';
    }
  }

  getJobDetails(id: string) {
    if (!Boolean(id)) { return; }
    this.subscriptions$.push(this.cartService.getCpqObjectByIdCDS<any>(CpqObjects.Opportunity, id).subscribe({
      next: (job) => {
        this.spinner.hide();
        this.jobDetails = job;
        this.breadCrumbHeader = this.getBreadcrumbHeader();
        this.inEditMode = this.isRecordInEditMode();
        this.jobName = this.getRecordState();
        this.configName = this.getRecordName();
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    }));
  }

  onNavigationButtonClick(index: number): void {
    switch (index) {
      // case 1:
      //   this.router.navigate([
      //     this.jobID,
      //     this.revisionID,
      //     'choose']);
      //   break;
      case 1:
        this.router.navigate([
          this.jobID,
          this.revisionID,
          'choose',
          'results'
        ]);
        break;
      case 2:
        this.router.navigate([
          this.jobID,
          this.revisionID,
          'choose',
          'configuration'
        ]);
        break;
      case 3:
        this.router.navigate([
          this.jobID,
          this.revisionID,
          'choose',
          'revision'
        ]);
        break;
      default:
        this.router.navigate(['/']);
    }
  }

  showPreviewButton(): boolean {
    return this.routeName === RouteNames.CONFIGURATION;
  }

  showCancelButton(): boolean {
    return (this.routeName === RouteNames.RESULTS
      || this.routeName === RouteNames.CONFIGURATION);
  }

  showBackButton(): boolean {
    return (this.routeName === RouteNames.CONFIGURATION
      || this.routeName === RouteNames.REVISION_VIEW
    );
  }

  showNextButton(): boolean {
    return this.routeName === RouteNames.CONFIGURATION;
  }

  showFinishButton(): boolean {
    return this.routeName === RouteNames.REVISION_VIEW;
  }

  onClickPreview() {
    this.btnSpinner = true;
    this.subscriptions$.push(this.productService.getProposalForPreivew(this.revisionID)
      .subscribe({
        next: (record: any) => {
          if (record) {
            this.proposalPreview = record;
            this.openPreviewDialog(this.proposalPreview);
            this.btnSpinner = false;
          }
          else {
            this.toastr.info('No Preview available', 'Information', {
              disableTimeOut: true,
              closeButton: true
            });
            this.btnSpinner = false;
          }
        },
        error: (err) => {
          console.log(err);
          this.btnSpinner = false;
        }
      }));
  }

  onClickCancel() {
    this.router.navigate(['/']);
  }

  onClickBack() {
    this.onNavigationButtonClick(this.routeID - 1);
  }

  onClickNext() {
    this.onNavigationButtonClick(this.routeID + 1);
  }

  onClickFinish() {
    this.cartService.updateJobFromStepper$.next();
  }

  openPreviewDialog(record: ProposalPreview) {
    const instance = this.modalService.open(PreviewComponent, {
      size: 'xl',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.jobPreviewData = record;
  }

  gotoJobsSummary() {
    this.router.navigate(['/']);
  }

  gotoJobDetails() {
    if (this.inEditMode) {
      this.router.navigate(['/job', this.jobID]);
    }
  }

  subscribeToCustomerView() {
    this.subscriptions$.push(this.userService.getCustomerView()
      .subscribe({
        next: isActive => {
          this.customerView = isActive;
        },
        complete: () => this.subscribeToCustomerView(),
      }));
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }

}
