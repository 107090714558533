import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { ConfigData, ProductService, SaveDataRCresponse } from '@cpq-app/services/product.service';
import { environment } from '@cpq-environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ResizeObserver from 'resize-observer-polyfill';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { ModalEvents, Share3dComponent } from '../share3d/share3d.component';
import { CadData as VwsCadData, CadLocation, CadMaterial, CadPart, CadScene, QueryParams } from '../VWS.interfaces.service';
import { VisualizeService } from './visualize.service';

// CDS provides JS libraries which they expect to be loaded into global space
declare const cdslib: any;
declare const cds: any;
declare const $: any;
declare let assemblyInfo: CdsAssemblyInfo;
declare let cdsMaterials: any;

const INCHES_PER_FOOT = 12;
const DEFAULT_CONCRETE_DEPTH = 20; // Inches
const ORIGIN_LOCATION: CadLocation = { x: 0, y: 0, z: 0 };
const DEFAULT_CAMERA_TARGET: CadLocation = { x: 0, y: 42 /* Inches */, z: 0 };
const DEFAULT_CAMERA_LOCATION: CadLocation = { x: -600, y: 180, z: -400 };
const DEFAULT_ARROW_LENGTH = 100;
const DEFAULT_ARROW_COLOR = 0x0000FF;
const RETRY_RENDER_TIME = 1000; //mS

const EMPTY_SCENE_ASSEMBLY: CdsAssemblyInfo = {
  scene: { size: ['x', 'z'], length: 50 * INCHES_PER_FOOT, width: 20 * INCHES_PER_FOOT, depth: DEFAULT_CONCRETE_DEPTH },
  camera: {
    resetCamera: true,  // TODO: Only true on part changes?
    cameraPosition: DEFAULT_CAMERA_LOCATION, // TODO: Offset the elected node's location
    cameraTarget: DEFAULT_CAMERA_TARGET, // TODO: Target the selected node's location
  },
  products: {}
};

const NODEID_REGEX = /(?<nodeId>\d+)-.+/;
const DELAY_BETWEEN_CHECKING_QR_STATE = 200; // mS
const CDS_QR_CODE_HTML_ID = 'cds-cad-qr';

@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss']
})
export class VisualizeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('3dViewerTab') viewerTab: ElementRef;
  @Output() dragLeave: EventEmitter<void> = new EventEmitter<void>();

  configId: any;
  private subscriptions: Subscription[] = [];
  private cadSubscription = new Subscription();
  private configSubscription = new Subscription();
  private assemblySubscription = new Subscription();
  private assemblyInfo$ = new Subject<CdsAssemblyInfo>();

  cdsInitialized = false;
  first3dLoad = true;
  enable3d = true;
  configData: ConfigData;
  bypass3d = false;
  isExpandViewOn = false;
  revisionID: string;
  productId: string;
  resizeObserver = new ResizeObserver(() => cds.resizeRenderer());
  sharable = false;
  isQRCodeLoading = false;
  arEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private ngZone: NgZone,
    private usersService: UsersService,
    private modalService: NgbModal,
    private visualizeService: VisualizeService
  ) { }

  ngOnInit() {
    this.cdsInitialized = false;

    const routeSub = this.route.paramMap.subscribe({
      next: route => {
        this.configId = route.get('configID');    // FIXME: route param as magic string
        this.enable3d = this.cdsLibrariesAreValid();
        this.revisionID = route.get('revisionID');
      }
    });

    this.subscriptions.push(this.route.queryParamMap.subscribe({
      next: queryParamMap => {
        this.productId = queryParamMap.get(QueryParams.ProductId)?.trim();
        this.sharable = true;
      },
      error: err => {
        console.error('Failed to obtain query params', err);
      }
    }));

    this.subscriptions.push(routeSub);

    const fullScreen3d = this.productService.expand3dViewSubject.subscribe({
      next: full => this.isExpandViewOn = full
    });

    this.subscriptions.push(fullScreen3d);
    
    this.arEnabled = !this.usersService.checkLoggedInUserIsDistributor() || false;
  }

  ngAfterViewInit() {
    // The CDS elements can not be initialized until after the template loads, since
    // it will use jQuery to hook div's by their id
    if (this.enable3d && !this.bypass3d) {
      console.log('%cInit 3D Vis', 'background-color: green; color: white');
      this.initializeCds();
      this.subscribeAssemblyInfo();
      this.subscribeCad();

    } else {
      this.deactivate3DVisuals();
    }
  }

  ngOnDestroy() {
    try {
      // CDS library, via three.js, places a lot of data into GPU memory
      // and the library is typically unloaded via page navigation, so it
      // does not typically worry about memory leak cleanup.

      if (cds) {
        cds.deleteScene();
      }
    } catch (err) {
      console.warn('3D memory may be retained');
    }

    try {
      // CDS is keeping track when a page is being loaded but is not releasing it on navigation
      // cdslib.twgCAD.isLoading = false;
    } catch (err) {
      console.warn('3D viewer may remain loaded');
    }

    this.resizeObserver.disconnect();
    this.cadSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
    this.assemblySubscription.unsubscribe();
    this.assemblyInfo$.complete();
    this.subscriptions?.forEach(sub => sub.unsubscribe());
  }

  private subscribeAssemblyInfo() {
    if (this.assemblySubscription) {
      this.assemblySubscription.unsubscribe();
    }

    this.assemblySubscription = this.assemblyInfo$.subscribe({
      next: assembly => {
        console.log(`Received assembly data`, assembly);
        this.cdsRenderScene(assembly);
        this.save3dData(assembly).subscribe({
          next: () => { },
          error: err => console.error(`There was a problem saving 3D data`, err)
        });  // FIXME: Pushing 3D data should be conditional on it previously being saved
      },
    });
  }

  private subscribeCad() {
    if (this.cadSubscription) {
      this.cadSubscription.unsubscribe();
    }

    this.cadSubscription = this.productService
      .publicationForCad(this.configId)
      .subscribe({
        next: data => this.applyCadData(data as VwsCadData),
        complete: () => this.subscribeCad(),
      });
  }

  private subscribeConfig() {
    this.configSubscription = this.productService
      .publicationForConfig(this.configId)
      .subscribe({
        next: data => this.configData = data,
        complete: () => this.subscribeConfig(),
      });
  }

  /**
   * CDS libraries are plain javascript that are loaded into global space
   * and must be validated before being accessed.
   */
  private cdsLibrariesAreValid(): boolean {
    try {
      if (!cds) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  private initializeCds() {
    try {
      console.log('%cInitializing Viewer', 'color: orange');

      this.ngZone.runOutsideAngular(() => {
        cds.init({
          environment: environment?.cds?.endpoint,
        });
        this.cdsInitialized = true;
        this.resizeObserver.observe(this.viewerTab.nativeElement);
        cds.renderer.domElement.addEventListener('dragLeave', (event: CustomEvent<CdsDragDetail>) => {
          if (Boolean(event?.detail)) {
            this.onDragLeave(event.detail);
          }
        });
      });

    } catch (err) {
      console.error(err);
      this.deactivate3DVisuals();
      this.cdsInitialized = false;
    }

    return this.cdsInitialized;
  }

  private onDragLeave(dragDetail: CdsDragDetail) {
    this.instantRearrange(dragDetail);

    const nodeId = NODEID_REGEX.exec(dragDetail?.name)?.groups?.nodeId;
    this.productService.configMoveNodeAndUpdatePosition(this.configId, nodeId, dragDetail?.object?.x)
      .subscribe({
        next: () => {
          this.dragLeave.emit();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  private cdsRenderScene(sceneAssembly: CdsAssemblyInfo) {
    assemblyInfo = sceneAssembly; // CDS relies on a shared global for render data; this is the only location that updates that value
    this.stopAnimation();
    if (this.cdsInitialized) {
      this.ngZone.runOutsideAngular(() => {
        cds.renderScene();
        if (sceneAssembly?.camera?.resetCamera) {
          this.focusOnTarget(DEFAULT_CAMERA_TARGET);
        }
      });
    } else {
      console.log('%cCAwaiting 3D Initialization', 'background-color: orange; color: black');
      setTimeout(() => this.cdsRenderScene(sceneAssembly), RETRY_RENDER_TIME); // try again in 1 sec
    }
  }

  private focusOnTarget(target: CadLocation) {
    if (this.cdsInitialized && cds.camera && cds.controls) {
      const offset = this.isometricOffset(target);

      cds.controls.target.set(target.x, target.y, target.z);
      cds.camera.position.set(offset.x, offset.y, offset.z);
    }
  }

  private isometricOffset(target: CadLocation): CadLocation {
    return {
      x: target.x + DEFAULT_CAMERA_LOCATION.x,
      y: target.y + DEFAULT_CAMERA_LOCATION.y,
      z: target.z + DEFAULT_CAMERA_LOCATION.z,
    };
  }

  private applyCadData(cadData: VwsCadData) {
    if (!cadData) {
      console.warn('No CAD data', cadData);
      this.deactivate3DVisuals();
      return;
    }

    // const url = data.url; // No longer used by CDS
    const domain = (cadData.domain || '').toLowerCase();

    // Check to see if the CDS library appears initialized
    if (!this.cdsInitialized) {
      try {
        this.initializeCds();
      } catch (err) {
        // CDS encountered a problem. Switching to fallback display
        console.error('Error during CDS Init', err);
        this.deactivate3DVisuals();
        return;
      }
    }

    console.log('%cRetrieved new CAD attributes', 'color: blue');
    console.dir(cadData);

    try {
      this.updateMaterialDefinitions(cadData?.materials);
      const assemblyData = this.convertToAssemblyInfo(cadData);
      this.assemblyInfo$.next(assemblyData);

    } catch (err) {
      // CDS must have encountered a problem. Switching to fallback
      console.error('Error during CDS render', err);
      this.deactivate3DVisuals();
      return;
    }
  }

  private deactivate3DVisuals() {
    console.log('%cSwitching Off 3D Visuals', 'background-color: orange; color: black');
    this.enable3d = false;
    this.first3dLoad = true;
    this.cadSubscription?.unsubscribe();
    this.usersService.set3DViewStatus(false);
  }

  driveThroughTunnel() {
    this.ngZone.runOutsideAngular(() => {
      cds.animateCamera();
    });
  }

  stopAnimation() {
    this.ngZone.runOutsideAngular(() => {
      cds.stopAnimation();
    });
  }

  resetView() {
    this.stopAnimation();
    this.focusOnTarget(DEFAULT_CAMERA_TARGET);
  }

  showQRCode() {
    if (this.cdsInitialized) {
      let qrCodeLoading;
      try {
        if (!this.isQRCodeLoading) {
          this.isQRCodeLoading = true;
          cds.loadQR();
          
          /* CDS directly manipulates the DOM however the QR code generation is not instantaneous.
           * To help the user, a spinner is displayed. CDS provides no notice of when the DOM is updated
           * so the code must watch for the element to be injected.
           */
          qrCodeLoading = setInterval(() => {
            const element = document.getElementById(CDS_QR_CODE_HTML_ID);
            if (element?.hasChildNodes()) {
              this.isQRCodeLoading = false;
              clearInterval(qrCodeLoading);
            }
          }, DELAY_BETWEEN_CHECKING_QR_STATE);
        }
      } catch (err) {
        console.error('Error while loading QR code', err);
        this.isQRCodeLoading = false;
        clearInterval(qrCodeLoading);
      }
    }
  }

  hideQRCode() {
    /* CDS directly manipulates the DOM for the QR code, however their library does not
     * provided a method to hide the QR code, so this code performs the manipulation.
     */
    const element = document.getElementById(CDS_QR_CODE_HTML_ID);
    while (element?.hasChildNodes()) {
      element.removeChild(element.lastChild);
    }
  }

  private calculateCameraAnimation(scene: CadScene): CdsCameraAnimation {
    const ANIMATION_LEAD_IN = 200;
    const ANIMATION_STOP_SHORT = 100;
    const ANIMATION_VELOCITY = 50; // Inches per second

    const From = Object.assign({}, DEFAULT_CAMERA_TARGET);
    const To = Object.assign({}, DEFAULT_CAMERA_TARGET);

    const animation: CdsCameraAnimation = {
      From,
      To,
      Time: scene.concreteLength / ANIMATION_VELOCITY,
    };

    animation.From.x = -((scene.concreteLength / 2) + ANIMATION_LEAD_IN);
    animation.To.x = (scene.concreteLength / 2) - ANIMATION_STOP_SHORT;

    return animation;
  }

  /**
   * Updates the global CDS Materials library with any values passed with the cad data.
   * @param materials Map of `CadMaterial` objects
   */
  private updateMaterialDefinitions(materials: { [key: string]: CadMaterial } = {}): void {
    try {
      Object.entries(materials).forEach(([key, mat]) => {
        cdsMaterials.library[key] = mat;
      });
    } catch (err) {
      console.warn('%cMaterial Update Failed', 'background-color: orange; color: white', err);
    }
  }

  /**
   * Translates the PDM data into CDS's expected format.
   * @param data as the PDM data
   * @returns a CDS-compatible object
   */
  private convertToAssemblyInfo(data: VwsCadData): CdsAssemblyInfo {
    const cdsInfo: CdsAssemblyInfo = {
      scene: {
        size: ['x', 'z'],
        length: data.scene?.concreteLength,
        width: data.scene?.concreteWidth,
        depth: data.scene?.concreteDepth,
        sideWalls: {
          length: data.scene?.wall?.length,
          width: data.scene?.wall?.width,
          height: data.scene?.wall?.height,
          distFromStart: data.scene?.wall?.offset,
        },
        trench: data.scene?.trench,
      },
      camera: {
        resetCamera: this.first3dLoad,  // TODO: Only true on part changes?
        cameraPosition: DEFAULT_CAMERA_LOCATION, // TODO: Offset the elected node's location
        cameraTarget: DEFAULT_CAMERA_TARGET, // TODO: Target the selected node's location
      },
      products: {},
    };

    const midwayPoint = ((data.scene?.concreteLength || 0) - DEFAULT_ARROW_LENGTH) / 2;
    const arrowOffset = -100; // Negative numbers represent left side, zero is at concrete corner

    cdsInfo.scene.arrow = {
      startPos: {
        x: midwayPoint,
        z: arrowOffset,
        y: 0
      },
      length: DEFAULT_ARROW_LENGTH,
      dir: {
        x: 1, y: 0, z: 0,
      },
      color: DEFAULT_ARROW_COLOR,
    };

    this.first3dLoad = false;

    try {
      cdsInfo.cameraAnimation = this.calculateCameraAnimation(data.scene);
    } catch (err) {
      console.warn('%cAnimation setup failed', 'background-color: orange; color: white', err);
    }

    try {
      data.parts?.forEach(part => {
        const key = `${part.nodeId}-${part.product}`;

        const cdsPart: CdsAssemblyProduct = {
          rootProduct: part.product,
          name: part.nodeId.toString(),   // CDS does not use the name, however we need the node id with drag events
          boundaries_min: part.boundariesMin,
          boundaries_max: part.boundariesMax,
          isInteractive: part.isInteractive,
          position: part.position,
          materials: {},
          addons: {}
        };

        part.partOptions?.forEach(option => cdsPart.materials[option.key] = option.value);
        part.selectedOptions?.forEach(option => cdsPart.addons[option.key] = option.value);

        cdsInfo.products[key] = cdsPart;
      });
    } catch (err) {
      console.warn('%cConversion Failed, parts size:%s', 'background-color: orange; color: white', data?.parts?.length);
      console.error(err);
    }

    return cdsInfo;
  }

  onExpandButtonClick() {
    this.isExpandViewOn = !this.isExpandViewOn;
    this.productService.expand3dViewSubject.next(this.isExpandViewOn);
  }

  save3dData(assemblyData: CdsAssemblyInfo): Observable<SaveDataRCresponse> {
    if (this.productId) {
      return this.visualizeService.saveCadData(assemblyData, this.productId);
    } else {
      return throwError('No Quote ID');
    }
  }

  share3dLink() {
    this.save3dData(assemblyInfo).subscribe({
      next: (saveResponse) => {
        this.openShare3DModel(saveResponse);
      },
      error: err => {
        console.log(err);
      }
    });
  }

  openShare3DModel(data: SaveDataRCresponse) {
    const instance = this.modalService.open(Share3dComponent, {
      size: 'lg'
    });
    instance.componentInstance.data = data;
    instance.result.then(
      (outcome) => {
        if (outcome.success === ModalEvents.On3DShareLinkActivate) {
          this.share3dLink();
        }
      },
      (dismiss) => {
      }
    );
  }

  instantRearrange(dragDetail: CdsDragDetail) {
    this.productService.publicationForCad(this.configId)
      .pipe(first())
      .subscribe({
        next: cadData => {
          // Update the location of moved part
          const nodeId = Number(NODEID_REGEX.exec(dragDetail?.name)?.groups?.nodeId);
          const positionX = dragDetail?.object?.x;
          let layer: string;

          const updatedParts = (cadData as VwsCadData)?.parts?.map(part => {
            if (part?.nodeId === nodeId) {
              part.position.x = positionX;
              layer = part.layer;
            }
            return part;
          });

          // Rearrange the parts on the impacted layer
          (cadData as VwsCadData).parts = this.rearrangeParts(updatedParts, layer, (cadData as VwsCadData)?.scene?.concreteLength);

          // Publish the results
          this.applyCadData(cadData as VwsCadData);
        }
      });
  }

  rearrangeParts(cadParts: CadPart[], layer: string, tunnelLength: number): CadPart[] {
    const arrangedLayerParts = cadParts
      .filter(part => part.layer === layer)
      .sort((pA, pB) => pA.position?.x - pB.position?.x)
      .reduce(this.moveForward(tunnelLength), [])
      .reverse()
      .reduce(this.moveBackward(), []);

    return cadParts
      .filter(part => part.layer !== layer)
      .concat(arrangedLayerParts);
  }

  moveForward(limit: number): (newParts: CadPart[], part: CadPart) => CadPart[] {

    return (newParts: CadPart[], part: CadPart): CadPart[] => {
      const end = newParts?.length;

      if (end < 1) {
        return [part];
      }

      const prevPart = newParts[end - 1];
      let nextAvailableX = prevPart.position.x + prevPart.partSize;

      if (nextAvailableX > limit) {
        nextAvailableX = limit;
      }

      if (part.position.x < nextAvailableX) {
        part.position.x = nextAvailableX;
      }

      newParts.push(part);
      return newParts;
    };
  }

  moveBackward(limit = 0): (newParts: CadPart[], part: CadPart) => CadPart[] {

    return (newParts: CadPart[], part: CadPart): CadPart[] => {
      const end = newParts?.length;

      if (end < 1) {
        return [part];
      }

      const prevPart = newParts[end - 1];
      let prevAvailableX = prevPart.position.x - part.partSize;

      if (prevAvailableX < limit) {
        prevAvailableX = limit;
      }

      if (part.position.x > prevAvailableX) {
        part.position.x = prevAvailableX;
      }

      newParts.push(part);
      return newParts;
    };
  }

}

interface CdsAssemblyProduct {
  rootProduct: string;
  name: string;
  position: CadLocation;
  isInteractive: boolean;
  boundaries_min: CadLocation;
  boundaries_max: CadLocation;
  materials?: {
    powder_coat?: string;
    powder_coat_secondard?: string;
  };
  addons?: any; // Product-driven key-value pair schema
}

export interface CdsAssemblyInfo {
  scene: {
    size?: string[];
    width?: number;
    depth?: number;
    length: number;
    sideWalls?: CdsSideWall;
    trench?: CdsTrench;
    arrow?: CdsArrow;
  };
  camera?: CdsCamera;
  cameraAnimation?: CdsCameraAnimation;
  products: any;
}

interface CdsTrench {
  length: number;
  width: number;
  depth: number;
  distFromStart: number;
  distFromLeft: number;
}

interface CdsArrow {
  startPos: CadLocation;
  dir: CadLocation;
  length: number;
  color: number;  // CDS is expecting a hex sequence, e.g. 0xFF0000
}

interface CdsSideWall {
  length: number;
  width: number;
  height: number;
  distFromStart: number;
}

interface CdsCamera {
  resetCamera: boolean;
  cameraPosition: CadLocation;
  cameraTarget: CadLocation;
}

interface CdsCameraAnimation {
  From: CadLocation;
  To: CadLocation;
  Time: number;
}

interface CdsDragDetail {
  id: string;
  name: string;
  object: CadLocation;
}
