<div class="search-box mt-4">
    <i class="fa fa-search"></i>
    <input type="text" (keyup)="applyFilters()" [pattern]="'^[a-zA-Z \-\']$'" [(ngModel)]="searchString"
        placeholder="Search Product">
</div>

<div class="flex-container">
    <div class="card center-row vws-anchor" *ngFor="let product of filteredProducts"
        (click)="onClickConfigure(product)">
        <div>
            <i class="fa fa-plus align-add-icon"></i>
            <div class="hrLine"></div>
            <img class="card-img-top" [src]="product.imageUrl" [alt]="product.Name">
        </div>
        <div class="card-body">
            <h3 class="card-text">{{product.Name}}</h3>
        </div>
    </div>
</div>

<div *ngIf="this.filteredProducts.length < 1 && enableNoMatchFound" class="mt-3 no-match-min-h">
    <span class="sorry-no-matches">Sorry, no matches found for <b>{{searchString}}</b>. Please try again.</span>
</div>