<h2 class="cpq-page-sub-header textcapitalize margin-top_30">Cart <span
        *ngIf="quote?.quoteLines?.length">({{quote?.quoteLines?.length || 0}}
        products)</span></h2>
<!-- Has Configurations-->
<div fxLayout="column" class="container-color padding_15" *ngIf="quote?.quoteLines?.length">
    <div fxLayout="row" class="innercontainer_color landing-page_CartHeight">
        <div fxLayout="row wrap" fxFlex="grow" class="cart-product">
            <div *ngFor="let quoteLine of quote.quoteLines trackBy:trackByQuoteLineId" fxLayout="row" class="padding_5" fxFlex="grow">
                <mat-card class="mat-elevation-z1 card-no-collapse" layout="row">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxFlex="30" fxLayoutGap="10px">
                            <a (click)="onModelCodeClick(quoteLine.ProductId)">
                                <img [src]="getProductImage(quoteLine.ProductId)" class="product-img" />
                            </a>
                            <div fxFlex fxLayout="column" fxLayoutAlign="center start" class="product-details">
                                <div class="product-name">{{quoteLine.Name}}</div>
                                <div class="model-code-header">Model Code:</div>
                                <a href="javascript: void(0);" (click)="onModelCodeClick(quoteLine.ProductId)">
                                    <div class="model-code-text">
                                        {{getProductModelCode(quoteLine.ProductId)}}</div>
                                </a>
                            </div>
                        </div>
                        <div fxFlex="15" class="cart-text">
                            <span class="note"
                                *ngIf="getProductProposalNotes(quoteLine.ProductId)!=''">Notes:</span><span>{{getProductProposalNotes(quoteLine.ProductId)}}</span>
                        </div>
                        <div fxLayout="row" fxLayout.xs="row wrap" class="quantity-price" fxFlex="55"
                            fxLayoutAlign="space-between center">
                            <div class="cart-text" fxFlex.xs="30" fxFlex="20" fxFlexOrder.xs="4">
                                <div class="quantity-test cost-text"> Quantity</div>
                                <div class="cost"><input type="number" class="landing-page_Quantity"
                                        (focusout)="updateQuantity(quoteLine)" [(ngModel)]="quoteLine.Quantity"
                                        [ngModelOptions]="{ standalone: true }" name="productQuantity"
                                        #productQuantity="ngModel" min="1" required />
                                    <span class="text-danger" *ngIf="productQuantity.errors?.required">Required
                                        field</span>
                                    <span class="text-danger" *ngIf="isQuantityOutOfRange(quoteLine.Quantity)">Maximum
                                        Value is
                                        99'999
                                    </span>
                                </div>
                            </div>
                            <div class="cart-text" fxFlex.xs="30" fxFlex="25" fxFlexOrder.xs="1">
                                <div class="cost-text">List Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.TotalSellingPrice != undefined; else elseBlock">
                                        {{ getUnitPrice(quoteLine.ProductId) | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="cart-text" fxFlex.xs="30" fxFlex="25" fxFlexOrder.xs="2">
                                <div class="cost-text">Net Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.TotalSellingPrice != undefined; else elseBlock">
                                        {{ getListPrice(quoteLine.ProductId) | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="cart-text" fxFlex.xs="40" fxFlex="30" fxFlexOrder.xs="3">
                                <div class="cost-text">Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.TotalSellingPrice != undefined; else elseBlock">
                                        {{quoteLine.TotalSellingPrice | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="product-tooltip" fxFlexOrder.xs="5">
                                <a href="javascript: void(0);" (click)="deleteProduct(quoteLine.Id)"><img
                                        class="action-icon" src="assets/images/delete-1.svg"></a>
                                <span class="product_tooltiptext">Delete Product</span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center" class="container-color cart-action">
        <div fxLayout="row" fxFlexOrder.xs="1" fxLayoutAlign="center center">
            <button *ngIf="isGuest" mat-stroked-button color="primary"
                class="textcapitalize cpq-button-text btn-request-access" (click)="openHubspotRequest()">REQUEST
                ACCESS</button>
            <button *ngIf="!isGuest" mat-flat-button color="accent"
                class="textcapitalize cpq-button-text btn-submit-quote" [disabled]="isSubmitDisabled()"
                (click)="openSubmitQuoteDialog()">SUBMIT QUOTE</button>
        </div>
        <div fxFlex="grow" fxFlexOrder.xs="3" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <div *ngIf="!isGuest" class="ancillary-text">
                Expires on {{quote.ExpirationDate|date: 'MMM dd, yyyy'}}
            </div>
            <div *ngIf="isGuest" class="ancillary-text">
                Please request access to view and submit quotes.
            </div>
        </div>
        <div fxLayout="row" fxFlexOrder.xs="2" fxLayoutAlign="start center">
            <div class="container-w">
                <div fxLayoutAlign="end end" class="total-cost-text">Total Price</div>
                <div fxLayoutAlign="space-around end" class="total-cost">
                    <span *ngIf="quote.Selling_Price__c != undefined; else elseBlock">
                        {{quote.Selling_Price__c | currency}}
                    </span>
                    <ng-template #elseBlock>$&nbsp;<span class="hidden-price">{{HIDDEN_PRICE}}</span></ng-template>
                </div>
            </div>
            <div [class]="{'hidden-icon': isGuest || !quote.proposal}">
                <a [href]="quoteProposalUrl(quote.proposal)" target="_blank"><img src="assets/images/download-1.svg"
                        class="proposaldownload action-icon"></a>
            </div>
        </div>
    </div>
</div>

<!-- No saved Configuration-->
<div fxLayout="column" class="container-color margin-top_5 container_minheight outer_cart" fxLayoutAlign="center center"
    *ngIf="!quote?.quoteLines?.length">
    <div class="inner_cart">
        <div>
            <h1 class="no-saved-config">No saved configurations</h1>
        </div>
        <div fxLayoutAlign="center center">
            <span><button mat-flat-button class="landing_page_button-color textcapitalize"
                    (click)="navigateToPreSelects()">CONFIGURE</button> </span>
        </div>
    </div>
</div>