<div class="modal-header d-flex justify-content-between">
    <h2 class="modal-title col-md-6" id="newAccount"><strong>Place Order</strong></h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <form class="p-3" [formGroup]="submitJobForm" (ngSubmit)="onSubmitJob()">
        <ngx-spinner [fullScreen]="false" type="ball-scale-ripple" size="medium" [name]="SUBMIT_SPINNER">
            <p class="loading">Please wait...</p>
        </ngx-spinner>
        <div class="same-row-controls">
            <div class="form-group required child-control">
                <label for="accountSelection" class="control-label">Account name</label>
                <select class="form-control" id="accountSelection" formControlName="accountSelection"
                    [ngClass]="{ 'is-invalid': submitted && submitJobForm.controls['accountSelection'].errors }">
                    <option [ngValue]="undefined" disabled>Select Account Name</option>
                    <option *ngFor="let account of availableAccounts" [ngValue]="account.Id">
                        {{account?.Name}}
                    </option>
                </select>
                <div *ngIf="submitted && submitJobForm.controls['accountSelection'].errors" class="invalid-feedback">
                    <div *ngIf="submitJobForm.controls['accountSelection'].errors.required">Account name is required
                    </div>
                </div>
            </div>
           
            <div class="form-group required child-control">
                <label for="ShippingDate" class="control-label">Expected Ship Date</label>
                <div class="input-group input-icons">
                    <input (click)="picker.toggle()" class="form-control" formControlName="ShippingDate"
                        placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #picker="ngbDatepicker" [minDate]="minPredictedDate"
                        [ngClass]="{ 'is-invalid':  (submitJobForm.controls['ShippingDate'].dirty|| submitted) &&submitJobForm.controls['ShippingDate'].errors }">
                      <i class="fa fa-calendar calendar m-1 icon" (click)="picker.toggle()"></i>
            
                    <div *ngIf="submitJobForm.controls['ShippingDate'].invalid && (submitJobForm.controls['ShippingDate'].dirty || submitted)"
                                            class="invalid-feedback">
                        <div *ngIf="!submitJobForm.controls['ShippingDate'].errors.required">{{validationMessage}}
                        </div>
                        <div *ngIf="submitJobForm.controls['ShippingDate'].errors.required">Expected Ship Date is
                            required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="same-row-controls">
            <div class="form-group child-control">
                <label for="jobName" class="control-label">Job Name</label>
                <input type="text" class="form-control" id="jobName" formControlName="jobName"
                    [ngClass]="{ 'is-invalid': submitted && submitJobForm.controls['jobName'].errors }" readonly>
                <div *ngIf="submitted && submitJobForm.controls['jobName'].errors" class="invalid-feedback">
                    <div *ngIf="submitJobForm.controls['jobName'].errors.required">
                        Job Name is required</div>
                    <div *ngIf="submitJobForm.controls['jobName'].errors.pattern">
                        Job Name must be a character</div>
                    <div *ngIf="submitJobForm.controls['jobName'].errors.maxlength">Job Name must contain max of 120
                        characters</div>
                </div>
            </div>
            <div class="form-group child-control">
                <label for="revisionName" class="control-label">Quote Name</label>
                <input type="text" class="form-control" id="revisionName" formControlName="revisionName"
                    [ngClass]="{ 'is-invalid': submitted && submitJobForm.controls['revisionName'].errors }" readonly>
                <div *ngIf="submitted && submitJobForm.controls['revisionName'].errors" class="invalid-feedback">
                    <div *ngIf="submitJobForm.controls['revisionName'].errors.required">
                        Quote Name is required</div>
                    <div *ngIf="submitJobForm.controls['revisionName'].errors.pattern">
                        Quote Name must be a character</div>
                </div>
            </div>
        </div>
        <div class="same-row-controls">
            <div class="form-group child-control" *hideFromCustomer>
                <label for="totalAmount" class="control-label">Total Amount ($)</label>
                <div class="input-group">                    
                    <input type="text" class="form-control" id="totalAmount" formControlName="totalAmount" readonly>
                </div>
            </div>
            <div class="form-group child-control">
                <label for="poNumber" class="control-label">PO Number</label>
                <input type="text" class="form-control" id="poNumber" formControlName="poNumber"
                    [ngClass]="{'is-invalid':  (submitJobForm.controls['poNumber'].dirty || submitJobForm.controls['poNumber'].touched || submitted) && submitJobForm.controls['poNumber'].errors }">
                <div *ngIf="submitJobForm.controls['poNumber'].errors" class="invalid-feedback">
                    <div *ngIf="submitJobForm.controls['poNumber'].errors.maxlength">PO Number must contain max of 255
                        characters
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group" *hideFromCustomer>
            <label for="revisionNote">Note</label>
            <textarea class="form-control notes" id="note" rows="5" formControlName="revisionNote"
                [ngClass]="{'is-invalid':  (submitJobForm.controls['revisionNote'].dirty || submitJobForm.controls['revisionNote'].touched || submitted) && submitJobForm.controls['revisionNote'].errors }"></textarea>
            <div *ngIf="submitJobForm.controls['revisionNote'].errors" class="invalid-feedback">
                <div *ngIf="submitJobForm.controls['revisionNote'].errors.pattern">Note must contain characters
                </div>
                <div *ngIf="submitJobForm.controls['revisionNote'].errors.maxlength">Note must contain max of
                    1000 characters
                </div>
            </div>
        </div>
        <div class="form-group pl-4">
            <input class="form-check-input" type="checkbox" value="" id="acceptTermsToSubmitJob"
                formControlName="acceptTermsToSubmitJob" />
            <label class="form-check-label" for="acceptTermsToSubmitJob">
                By clicking submit, you are accepting the <a
                    href="assets/documents/GENERAL TERMS AND CONDITIONS OF SALE REVISED 6.3.20.pdf"
                    target="_blank">Terms and Conditions</a> of VWS.
            </label>
        </div>
        <div class="modal-footer d-flex">
            <button type="button" class="cancel-button" (click)="activeModal.close()">Cancel</button>
            <button type="submit" class="save-button"
                [disabled]="submitJobForm.invalid || canSubmit">Submit</button>
        </div>
    </form>
</div>