import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export abstract class RowActions {
    static readonly SINGLE_FIELD_EDIT = 'single_field_edit';
    static readonly EDIT = 'edit';
    static readonly COPY = 'copy';
    static readonly DELETE = 'delete';
    static readonly DOWNLOAD = 'download';
    static readonly Fav = 'fav';
    static readonly SHARE = 'share';
}

export abstract class CursorCssClass {
    static readonly DISABLE_POINTER = 'disable_pointer_events';
    static readonly ALLOW_CLICK = 'allow_click';
    static readonly ALLOW_EDIT = 'editable'
}

export abstract class StatusCssClass {
    static readonly ACTIVE = 'status-active';
    static readonly CLOSE = 'status-close';
    static readonly INACTIVE = 'status-inactive';
}

export abstract class ExpiryCssClass {
    static readonly EXPIRED = 'expire-expired';
    static readonly DANGER = 'expire-danger';
    static readonly WARNING = 'expire-warning';
    static readonly MODERATE = 'expire-moderate';
    static readonly NORMAL_SINGLE_DIGIT = 'expire-normal-single-digit';
    static readonly NORMAL = 'expire-normal';
    static readonly NORMAL_TRIPLE_DIGIT = 'expire-normal-triple-digit';
}

export interface DatatableColumn<T> {
    field?: string;
    header?: string;
    visibility?: boolean;
    control?: string;
    initialSort?: boolean;
    sortable?: boolean;
    filterable?: boolean;
    isPrimary?: boolean;
    disableActionProperty?: string;
    showColorIndicator?: boolean;
    disable?: (record: T) => void;
    handler?: (record: T, name?) => void;
    value?: (record: T) => any;
    compare?: (a: T, b: T) => number;
    className?: (record: T) => string;
    titleText?: (record: T) => string;
    isEditable?: boolean;
}
export interface DataTableEvent {
    action?: string;
    tooltip?: string;
    handler?: () => void;
    hrefHandler?: () => string;
}

export interface PageHeaderInformation{
    title: PageName;
    buttons?: Buttons[]
}

export interface Buttons{
    title: string;
    field: string;
    tooltip?: string;
    icon?: Icons;
    className?: ButtonClass;
    disable?: () => void;
}

export enum Icons{
    plus = 'fa fa-plus'
}

export enum ButtonTitle {
    quote = 'New Quote',
    order = 'Place Order',
    job = 'New Job',
    account = 'New Account'
}

export enum ButtonClass{
    dark = 'dark-btn',
    white = 'white-btn',
    grey = 'grey-btn'
}

export enum PageName {
    accounts = 'Accounts',
    job = 'Jobs',
    preconfigured = 'Manage Pre-configured Products',
    quotes = 'Quotes'
}