import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-option',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  @Input() optionData = [];
  @Input() groupName: string;
  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSelectionChange(item) {
    item.value = 1;
    this.valueChange.emit(item);
  }
}
