import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartService, CpqGrantToObjects, CpqQueryObjects, CpqUser } from '@cpq-app/services/cart.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { VwsCpqOpportunity } from '../VWS.interfaces.service';

enum DropdownSettings {
  IdField = 'Id',
  TextField = 'Name',
  SelectAllText = 'Select All',
  UnSelectAllText = 'UnSelect All'
}
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})


export class ShareComponent implements OnInit {
  @Input() jobID: string;

  userNames: CpqUser[];
  shareForm: UntypedFormGroup;
  selectedUsers: CpqUser[] = [];
  dropdownSettings: IDropdownSettings = {};
  canShare = false;

  constructor(
    private cartService: CartService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {

  }

  ngOnInit(): void {
    this.getUserDetails();
    this.initialFormData();
    this.setSelectUsersDropdownSettings();
    this.cartService.getCpqObjectVisibilty<VwsCpqOpportunity>(this.jobID).subscribe({
      next: (jobVisibility) => { this.canShare = jobVisibility?.canShare || false; }
    });

  }

  initialFormData() {
    this.shareForm = this.formBuilder.group({
      UserId: [this.selectedUsers, Validators.required],
      /*  Read: [{ value: true, disabled: true }],
       Edit: [false],
       Delete: [false], */
    });
  }

  getUserDetails() {
    this.userNames = [];
    this.cartService.getCpqObjects<CpqUser>(CpqQueryObjects.Users)
      .subscribe({
        next: (allUsers) => {
          const currentUserId = sessionStorage.getItem('userId');
          const filteredUsers = allUsers.filter(user => user.Id !== currentUserId);
          this.userNames = [...this.userNames, ...filteredUsers];
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching user drop down', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      });
  }

  onShareClick() {
    const selectedUsersIds = this.selectedUsers?.map(user => user.Id) || [];

    if (selectedUsersIds.length) {
      this.cartService.grantVisibility(this.jobID, CpqGrantToObjects.User, selectedUsersIds,
        { canRead: true, canUpdate: true, canDelete: true })
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.toastr.success('Job shared successfully');
          },
          error: err => {
            this.toastr.error(
              'There is fatal error while sharing job details', 'Error', {
              disableTimeOut: true,
              closeButton: true
            }
            );
          }
        });
    }

  }

  private setSelectUsersDropdownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: DropdownSettings.IdField,
      textField: DropdownSettings.TextField,
      selectAllText: DropdownSettings.SelectAllText,
      unSelectAllText: DropdownSettings.UnSelectAllText,
      itemsShowLimit: 100,
      allowSearchFilter: true
    };
  }
}
