<footer id="sticky-footer" class="py-4 bg-dark text-white-50">
    <div class="container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-xs-2">
                    <address class="f-font">
                        <a href="https://www.dovertwg.com/" class="link-color" target="_blank">Tulsa Winch, Inc.</a><br>
                        11135 S. James Avenue<br>
                        Jenks, OK 74037 USA<br>
                        Tel: (918) 298-8300<br>
                    </address>
                </div>

                <div class="col-lg-2 col-xs-2 center">
                    <span class="f-font"><a href="https://www.dovertwg.com/contact-us/" class="link-color"
                            target="_blank">CONTACT US</a></span>

                </div>

                <div class="col-lg-2 col-xs-2 center">
                    <span class="f-font"><a href="https://www.dovertwg.com/preference-center/" class="link-color"
                            target="_blank">PREFERENCE CENTER</a></span>
                </div>


                <div class="col-lg-4 col-xs-4 center">
                    <span class="f-font">© 2020 TWG, Inc, a Dover company All Rights Reserved</span>
                </div>

                <div class="col-lg-2 col-xs-2">
                    <address class="f-font">
                        <a href="https://www.dovertwg.com/" class="link-color" target="_blank">TWG Canada
                            Consolidated,Inc.</a><br>
                        19350 22nd Avenue<br>
                        Surrey, BC V3Z 3S6 Canada<br>
                    </address>
                </div>
            </div>
        </div>
    </div>
</footer>