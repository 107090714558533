import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MaterialModule } from '../../../shared/material-module';
import { TutorialsComponent } from '../help-support/tutorials/tutorials.component';
import { FAQsComponent } from '../help-support/faqs/faqs.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    declarations: [
        FAQsComponent,
        TutorialsComponent
    ]
})

export class HelpModule { }