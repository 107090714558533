import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { add, format } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { delay, first, switchMap, timeout } from 'rxjs/operators';

const DELAY_TIME = 500;
const MAX_WAIT_TIME = 8000; // max wait time is 8 sec
const DATE_FMT = 'yyyy-MM-dd';

export interface RenewQuoteModalObject {
  message: string;
  recordID: string;
  isRecordExpired: boolean;
}

@Component({
  selector: 'app-renew-quote',
  templateUrl: './renew-quote.component.html',
  styleUrls: ['./renew-quote.component.scss']
})
export class RenewQuoteComponent implements OnInit {
  @Input() data: RenewQuoteModalObject;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
  }

  closeActiveModel() {
    if (!this.data.isRecordExpired) {
      this.activeModal.close();
    } else {
      this.renewQuote();
    }
  }

  getDateString(dateFormat: string, date = new Date()): string {
    return format(date, dateFormat);
  }

  addDaysToDate(numberOfDays: number, date = new Date()): Date {
    if (isNaN(numberOfDays)) {
      return;
    }
    return add(date, { days: numberOfDays });
  }

  /**
  * Update expiry time of primary quote for the submitted,inactive or active quote of the opportunity
  */
  renewQuote() {
    this.spinner.show();
    const quoteExpirationDate = this.getDateString(DATE_FMT, this.addDaysToDate(30));
    const obj = { ExpirationTime: quoteExpirationDate }
    this.cartService.updateObjectById(CpqObjects.Quote, this.data.recordID, obj).subscribe({
      next: (resp) => {
        this.activeModal.close(resp.Id);
        this.spinner.hide();
      },
      error: (err) => {
        this.activeModal.close();
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while renewing the quote. Please contact CPQ administrator.', 'Error!', {
          disableTimeOut: true,
          closeButton: true
        })
      }
    });
    // this.cartService.updateProducts([this.data.recordID])
    //   .pipe(
    //     delay(DELAY_TIME),
    //     switchMap(x => this.cartService.observeCPQProgress(this.data.recordID)),
    //     first(progress => progress.status === CpqProductUpdateStatus.COMPLETE),
    //     timeout(MAX_WAIT_TIME)
    //   ).subscribe({
    //     next: res => {
    //       if (this.data.isRecordExpired) {
    //         this.activeModal.close(this.data.recordID);
    //       } else {
    //         this.activeModal.close();
    //       }
    //       this.spinner.hide();
    //     },
    //     error: err => {
    //       this.spinner.hide();
    //       this.activeModal.close();
    //       this.toastr.error(
    //         'Unknown error occurred while renewing the quote. Please contact CPQ administrator.', 'Error!', {
    //         disableTimeOut: true,
    //         closeButton: true
    //       }
    //       );
    //       console.log(err);
    //     }
    //   });
  }

}
