export const environment = {
  production: false,
  version: "0.0.0.0",
  B2CConfigs: {
    BackendURL: 'https://vws-uat.cdsvisual.net/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'http://cpqportal-vwsdev.dovertech.co.in/api',
    b2cScopes: 'https://cxportaluat.onmicrosoft.com/f52646c5-4976-49d9-b25c-61f4036f7aff/user.read',
    clientId: 'f52646c5-4976-49d9-b25c-61f4036f7aff',
    isPolicy: true,
    redirectUrl: 'https://vws-uat.cdsvisual.net',
    superAdminOid: 'bb087e07-2aa5-4838-9042-aadfd0511ce9',
    tenant: 'cxportaluat.onmicrosoft.com',
    authURL: "https://cxportaluat.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'B2C_1A_signup_signin-CPQ-TWG',
      VWS: 'b2c_1a_cxportal_uat_signin_policy_vws',
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordReset-CPQ-TWG',
      VWS: 'B2C_1A_PasswordResetVWS'
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_ProfileEditPasswordChange-CPQ-TWG',
      VWS: 'B2C_1A_PasswordChangeVWS'
    },
    b2cLoginURL: 'https://cxportaluat.b2clogin.com',
    passwordChangeSuccessUrl: 'https://vws-uat.cdsvisual.net/changePasswordSuccess'
  },
  cxPortal: {
    baseUrl: 'https://cxportal-uat-vws.dovertech.co.in',
  },
  fpx: {},
  cds: {
    endpoint: 'qa',
  },
};
