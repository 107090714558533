import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { CartService, CpqQuote, CpqProposal } from '@cpq-app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HubspotDialogComponent, HubspotForms } from '../../../content/hubspot-modal/hubspot-modal.component';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() quoteId: string;
  quotes = new MatTableDataSource<any>();
  isQuotesLoading = true;
  quoteRange = '1';
  noQuotesItems = false;
  quoteSubscription: Subscription;
  isGuest: boolean;
  @Output() startNewConfiguration = new EventEmitter();

  readonly HIDDEN_PRICE = '••••••••••';
  private paginator: MatPaginator;
  private sort: MatSort;

  constructor(
    private cartService: CartService,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private spinner: NgxSpinnerService
  ) { }
  displayedColumnsForQuote: string[] = [
    'LastModifiedDate',
    'FormattedId',
    'Name',
    'Note',
    'Selling_Price__c',
    'ExpirationDate'
  ];

  @ViewChild(MatSort) set matSort(mSort: MatSort) {
    this.sort = mSort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mPaginator: MatPaginator) {
    this.paginator = mPaginator;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.quotes.paginator = this.paginator;
    this.quotes.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.quotes.filter = filterValue;
  }

  ngAfterViewInit() {
    this.setDataSourceAttributes();
  }

  ngOnInit() {
    this.isGuest = this.guestService.getGuestUserDetails().isGuest || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quoteId) {
      console.log('Refetching quotes');
      this.fetchQuotesWithinRange();
    }
  }

  fetchQuotesWithinRange() {
    this.isQuotesLoading = true;
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe();
    }
    this.quotes.data = [];
    this.spinner.show();
    this.quoteSubscription = this.cartService
      .fetchQuotes(Number(this.quoteRange))
      .pipe(
        map<CpqQuote[], CpqQuote[]>(qs => qs.filter(q => CpqQuote.checkIsClosed(q) && q.Selling_Price__c))
      )
      .subscribe(
        quotes => {
          this.spinner.hide();
          this.quotes.data = this.quotes.data.concat(quotes);
          this.noQuotesItems = this.quotes.data.length === 0;
          console.log(`Adding ${quotes.length} quotes to the table`, quotes);
        },
        err => {
          this.spinner.hide();
          this.isQuotesLoading = false;
          this.ngAfterViewInit();
        },
        () => {
          this.isQuotesLoading = false;
          this.ngAfterViewInit();
        }
      );
  }

  onQuoteRangeChange() {
    this.fetchQuotesWithinRange();
  }

  quoteProposalUrl(proposal: CpqProposal) {
    return this.cartService.getProposalUrl(proposal?.Id);
  }

  /**
   * Opens a material modal to show Hubspot forms
   */
  openHubspotRequest() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.RequestAccess
      }
    });
  }

  navigateToPreSelects() {
    this.startNewConfiguration.emit();
  }
}
