import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MSALService } from './shared/services/msal.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from './shared/services/logoutService';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { LoginService } from './services/login.service';
import { Subscription } from 'rxjs';


const SET_IDLE = 1200; // in sec
const SET_TIME_OUT = 120; // in sec
const TOASTER_TIME_OUT = 2 * 60 * 1000; // in ms

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private idleState = 'Not started.';
  title = 'app';
  isIdle: boolean;
  timedOut: boolean;
  toastRef: any;
  subscription$: Subscription[] = [];

  constructor(
    private router: Router,
    public msalService: MSALService,
    private spinner: NgxSpinnerService,
    private idle: Idle,
    private toastr: ToastrService,
    private logOutService: LogoutService,
    private loginService: LoginService,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.isIdle = false;
    this.idle.setIdle(SET_IDLE);
    this.idle.setTimeout(SET_TIME_OUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.toastr.clear(this.toastRef.ToastId);
      this.idleState = 'No longer idle.';
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logOutService.logout();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You have gone idle!';
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `Your session is going to timeout in ${countdown} seconds. If you are still working in your session, please click to continue session.`;
      let toasterObject = this.toastr.toasts[0];
      if(toasterObject) {
        toasterObject.toastRef.componentInstance.message =  this.idleState;
      } else {
        this.toastRef = this.toastr.warning(this.idleState, 'Alert!', { timeOut: TOASTER_TIME_OUT });
      }
    });
    this.reset();
    const title = this.titleService.getTitle();
    if (title === 'VWS Portal') {
      this._document.getElementById('appFavicon').setAttribute('href', '');
    } else {
      this._document.getElementById('appFavicon').setAttribute('href', './assets/images/logo-icon.png');
    }   
    const loggedIn$ = this.loginService.loginUser();
    this.subscription$.push(loggedIn$.subscribe({
      next: () => {}}));
  }

  ngOnDestroy() {
    this.idle.stop();
    this.subscription$?.forEach(sub => sub?.unsubscribe());
  }

  storageChange(event) {
    if (event.key === 'logged_in' && event.newValue === 'false') {
      sessionStorage.clear();
      window.location.href = '/';
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
