import { Routes } from '@angular/router';
import { CreateJobComponent } from '@vws-app/jobs/create-job/create-job.component';

/*======================================== CPQ Related =================================*/
import { TunnelConfigurationComponent } from './tunnel-configuration/tunnel-configuration.component';
import { RevisionStartFormComponent } from './revision-start-form/revision-start-form.component';
import { JobDetailsComponent } from './jobs/job-details/job-details.component';
import { CreateRevisionComponent } from './revision/create-revision/create-revision.component';
import { ProductResultsComponent } from './product-results/product-results.component';
import { JobsComponent } from './jobs/jobs.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { RouteNames } from './VWS';
import { CreateAccountsComponent } from './Accounts/create-accounts/create-accounts.component';
import { ViewAccountsComponent } from './Accounts/view-accounts/view-accounts.component';
import { AuthGuard } from '@cpq-app/shared/services/authguard.service';
import { ManageLibraryJobComponent } from './library/manage-library-job/manage-library-job.component';

export const STEPPER_CHILD_ROUTES = [
  // {
  //   path: 'family',
  //   component: RevisionStartFormComponent,
  //   data: { routeName: RouteNames.FAMILY, childRouteId: 1 }
  // },
  {
    path: 'results',
    component: ProductResultsComponent,
    data: { routeName: RouteNames.RESULTS, childRouteId: 1 }
  }, {
    path: 'configuration',
    component: TunnelConfigurationComponent,
    data: { routeName: RouteNames.CONFIGURATION, childRouteId: 2 }
  }, {
    path: 'revision',
    component: JobDetailsComponent,
    data: { routeName: RouteNames.REVISION_VIEW, childRouteId: 3 }
  }
];

export let VWSRoutes: Routes = [
  {
    path: '',
    component: JobsComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'job',
    component: CreateJobComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manageLibraryQuotes',
    component: ManageLibraryJobComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':jobID/:revisionID/:aProduct/choose',
    component: ConfigurationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'results',
        pathMatch: 'full'
      },
      ...STEPPER_CHILD_ROUTES
    ]
  },
  {
    path: ':jobID/choose',
    component: ConfigurationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'results',
        pathMatch: 'full'
      },
      ...STEPPER_CHILD_ROUTES
    ]
  },
  {
    path: ':jobID/:revisionID/choose',
    component: ConfigurationComponent,
    canActivate: [AuthGuard],
    children: [
      ...STEPPER_CHILD_ROUTES
    ]
  },

  {
    path: 'accounts',
    component: ViewAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts/new',
    component: CreateAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts/:accountId',
    component: CreateAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':jobID/:revisionID/accounts/new',
    component: CreateAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'job/:jobID',
    component: JobDetailsComponent,
    canActivate: [AuthGuard]
  }
];


