import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-tile',
  templateUrl: './action-tile.component.html',
  styleUrls: ['./action-tile.component.scss']
})
export class ActionTileComponent implements OnInit {

  @Input() actionTileInfo;
  @Output() actionTileButtonClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(value: string) {
    this.actionTileButtonClick.emit(value);
  }

}
