import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ButtonClass, ButtonTitle, CursorCssClass, DatatableColumn, Icons, PageHeaderInformation, PageName, RowActions as TableRowActions } from '@cpq-app/shared/datatable/datatable';
import { CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { DatePipe } from '@angular/common';
import { RouteNames } from '@vws-app/VWS';
import { Subscription } from 'rxjs';

const DATE_FMT = 'yyyy-MM-dd';

@Component({
  selector: 'app-view-accounts',
  templateUrl: './view-accounts.component.html',
  styleUrls: ['./view-accounts.component.scss']
})
export class ViewAccountsComponent implements OnInit, OnDestroy {

  accounts: CpqAccounts[] = [];
  dataLoading = false;
  subscription$: Subscription[] = [];
  pageHeaderInformation: PageHeaderInformation = {
    title: PageName.accounts,
    buttons: [{
      field: 'account',
      title: ButtonTitle.account,
      icon: Icons.plus,
      className: ButtonClass.dark
    }]
  }


  colData: DatatableColumn<CpqAccounts>[] = [
    {
      field: 'Name', header: 'Account Name', isPrimary: true,
      visibility: true, sortable: true, filterable: true,
      value: (record: CpqAccounts) => record?.Name, handler: (record: CpqAccounts) => this.onClickEdit(record),
      className: () => CursorCssClass.ALLOW_CLICK,
      compare: (a: CpqAccounts, b: CpqAccounts) => {
        const account_1 = a?.Name ? a.Name : '';
        const account_2 = b?.Name ? b.Name : '';
        return account_1.localeCompare(account_2)
      },
    },
    {
      field: 'AccountNumber', header: 'Account Number',
      visibility: true, sortable: true,
      value: record => record.AccountNumber,
      compare: (a: CpqAccounts, b: CpqAccounts) => { return (Number(a.AccountNumber) - Number(b.AccountNumber)) },
    },
    {
      field: 'ModifiedTime', header: 'Last Modified',
      initialSort: true, sortable: true, visibility: true,
      value: (record: CpqAccounts) => this.datePipe.transform(record.ModifiedTime, DATE_FMT),
      compare: (a: CpqAccounts, b: CpqAccounts) => {
        const aD = new Date(a.ModifiedTime);
        const bD = new Date(b.ModifiedTime);
        return Number(aD > bD) - Number(aD < bD);
      }
    },
    {
      field: 'OwnerName', header: 'Owner',
      visibility: true, sortable: true, filterable: true,
      value: (record: CpqAccounts) => record.Owner?.Name,
      compare: (a: CpqAccounts, b: CpqAccounts) => {
        const owner_1 = a.Owner?.Name !== null ? a.Owner.Name : '';
        const owner_2 = b.Owner?.Name !== null ? b.Owner.Name : '';
        return owner_1.localeCompare(owner_2)
      },
    },
    {
      field: 'actions', header: 'Actions',
      visibility: true,
      value: (record) => {
        return [{ action: TableRowActions.EDIT, handler: () => this.onClickEdit(record) }];
      }
    }
  ];

  constructor(
    private loginService: LoginService,
    private cartService: CartService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.cartService.selectedHeaderInfo$.next(RouteNames.ACCOUNTS);
  }

  ngOnInit() {
    this.spinner.show();
    this.fetchAccounts()
  }

  fetchAccounts() {
    this.accounts = [];
    this.dataLoading = true;
    this.spinner.show();
    this.subscription$.push(this.cartService.getCpqObjectsCDS<CpqAccounts[]>(CpqObjects.Accounts)
      .subscribe({
        next: results => {
          this.accounts = [...this.accounts, ...results];
          this.accounts = this.accounts.sort((a, b) => {
            const aD = new Date(a.ModifiedTime);
            const bD = new Date(b.ModifiedTime);
            return Number(aD < bD) - Number(aD > bD);
          });
          this.spinner.hide();
        },
        complete: () => {
          this.dataLoading = false;
        },
        error: err => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching Accounts', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  onClickNewAccount() {
    this.router.navigate(['accounts', 'new']);
  }

  onClickEdit(record: CpqAccounts) {
    this.spinner.show();
    this.router.navigate(['accounts', record.Id]);
  }

  buttonClick(event) {
    this.onClickNewAccount()
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
