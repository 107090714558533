import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@cpq-app/shared/material-module';
import { RouterModule, Routes } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DataTablesModule } from 'angular-datatables';
import { BreadcrumbsModule } from 'src/app/shared/breadcrumbs/breadcrumbs.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CreateJobComponent } from '@vws-app/jobs/create-job/create-job.component';
import { ViewAccountsComponent } from '@vws-app/Accounts/view-accounts/view-accounts.component';
import { CreateAccountsComponent } from '@vws-app/Accounts/create-accounts/create-accounts.component';
import { TunnelConfigurationComponent } from './tunnel-configuration/tunnel-configuration.component';
import { AddProductsComponent } from './add-products/add-products.component';
import { RevisionStartFormComponent } from './revision-start-form/revision-start-form.component';
import { JobsComponent } from '@vws-app/jobs/jobs.component';
import { ActionTileComponent } from '@cpq-app/content/action-tile/action-tile.component';
import { FeatureTileComponent } from '@cpq-app/content/feature-tile/feature-tile.component';
import { JobDetailsComponent } from '@vws-app/jobs/job-details/job-details.component';
import { RevisionComponent } from '@vws-app/revision/revision.component';
import { DatatableComponent } from '@cpq-app/shared/datatable/datatable.component';
import { HelpComponent } from './help/help.component';
import { CreateRevisionComponent } from '@vws-app/revision/create-revision/create-revision.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { ProductResultsComponent } from './product-results/product-results.component';
import { TunnelProductDetailsComponent } from './tunnel-product-details/tunnel-product-details.component';
import { SharedModule } from '@cpq-app/shared/shared-module';
import { RenewQuoteComponent } from '@vws-app/revision/renew-quote/renew-quote.component';
import { ShareComponent } from './share/share.component';
import { SubmitJobComponent } from './submit-job/submit-job.component';
import { SortableHeaderDirective } from '@cpq-app/shared/datatable/sortable.directive';
import { HideFromCustomerDirective } from '@cpq-app/shared/directives/hide-from-customer.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TruncatePipe } from '@cpq-app/shared/pipes/truncate.pipe';
import { ConfigurationComponent } from './configuration/configuration.component';
import { HelpModule } from './help-support/help.module';
import { VisualizeService } from './visualize/visualize.service';
import { CpqOperations } from './cpqOperations.service';
import { PreviewComponent } from '@cpq-app/tenants/preview/preview.component';
import { DeleteComponent } from './delete/delete.component';
import { ManageLibraryJobComponent } from './library/manage-library-job/manage-library-job.component';
import { EditLibraryComponent } from './library/edit-library/edit-library.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        BreadcrumbsModule,
        AccordionModule.forRoot(),
        DataTablesModule.forRoot(),
        NgbModule,
        SharedModule,
        NgxSpinnerModule,
        NgMultiSelectDropDownModule.forRoot(),
        HelpModule
    ],
    declarations: [
        ViewAccountsComponent,
        CreateJobComponent,
        CreateAccountsComponent,
        TunnelConfigurationComponent,
        AddProductsComponent,
        RevisionStartFormComponent,
        JobsComponent,
        ActionTileComponent,
        FeatureTileComponent,
        JobDetailsComponent,
        RevisionComponent,
        HelpComponent,
        CreateRevisionComponent,
        DatatableComponent,
        HelpComponent,
        VisualizeComponent,
        ProductResultsComponent,
        TunnelProductDetailsComponent,
        ShareComponent,
        SubmitJobComponent,
        RenewQuoteComponent,
        ShareComponent,
        SortableHeaderDirective,
        HideFromCustomerDirective,
        TruncatePipe,
        ConfigurationComponent,
        PreviewComponent,
        DeleteComponent,
        ManageLibraryJobComponent,
        EditLibraryComponent
    ],
    providers: [NgbActiveModal, VisualizeService, CpqOperations]
})
export class VWSModule { }
