import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Quote } from '../Cpq.interfaces.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal
  ) { }
  @Input() jobPreviewData: any;
  quoteData: Quote;

  ngOnInit() {
    this.quoteData = this.jobPreviewData?.Opportunity?.Quote;
  }
}
