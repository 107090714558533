import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService } from '@cpq-app/services/cart.service';
import { environment } from '@cpq-environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FAQsComponent } from '@vws-app/help-support/faqs/faqs.component';
import { TutorialsComponent } from '@vws-app/help-support/tutorials/tutorials.component';
import { RouteNames } from '@vws-app/VWS';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss']
})
export class HeaderBottomComponent implements OnInit, OnDestroy {
  cxBaseUrl = environment.cxPortal.baseUrl;
  isCustomerView = false;
  is3DViewOn = true;
  customerViewSubscription: Subscription;
  visualizeSubscription: Subscription;
  routeName: string;
  constructor(
    private userService: UsersService,
    private modalService: NgbModal,
    private cartService: CartService,
    private router: Router
  ) {
    this.cartService.selectedHeaderInfo$.subscribe(routeName => {
      this.routeName = routeName;
    });
  }

  ngOnInit() {
    this.subscribeToCustomerView();
    this.subscribeTo3DView();
  }

  toggleCustomerViewMode(chkEvent: Event) {
    const checkbox = chkEvent.target as HTMLInputElement;
    this.userService.setCustomerViewStatus(checkbox.checked || false);
  }

  toggle3DViewMode(chkEvent: Event) {
    const checkbox = chkEvent.target as HTMLInputElement;
    this.userService.set3DViewStatus(checkbox.checked || false);
  }

  subscribeToCustomerView() {
    this.customerViewSubscription = this.userService.getCustomerView()
      .subscribe({
        next: isActive => this.isCustomerView = isActive,
        complete: () => this.subscribeToCustomerView(),
      });
  }

  subscribeTo3DView() {
    this.visualizeSubscription = this.userService.get3DView()
      .subscribe({
        next: isActive => this.is3DViewOn = isActive,
        complete: () => this.subscribeTo3DView(),
      });
  }

  configIsActive(): boolean {
    return !this.accountsIsActive();
  }

  accountsIsActive(): boolean {
    return this.routeName === RouteNames.ACCOUNTS;
  }

  openTutorial() {
    const instance = this.modalService.open(TutorialsComponent, {
      size: 'xl'
    });
    instance.result.then((outcome) => { }, (dismiss) => {
      // gets triggers when modal is dismissed.
    });
  }

  openFAQ() {
    const instance = this.modalService.open(FAQsComponent, {
      size: 'xl'
    });
    instance.result.then((outcome) => { }, (dismiss) => {
      // gets triggers when modal is dismissed.
    });
  }

  navigateToAccountsPage() {
    this.router.navigate(['accounts']);
  }

  navigateToConfigPage() {
    this.router.navigate(['/']);
  }
  /**
   * Will change this url once Dashboard implemented
   */
  navigateToDashboardPage() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.customerViewSubscription?.unsubscribe();
    this.visualizeSubscription?.unsubscribe();
  }

}
