<ul class="nav nav-pills nav-tabs" id="pills-tab" role="tablist">
    <li class="nav-item active" *ngFor="let family of familiesDatasets; let index = index;">
        <a class="nav-link" id="family" data-toggle="pill" (click)="mapConfigProductCards(family.id)" role="tab"
            aria-controls="family" aria-selected="true"
            [ngClass]="{'active': index === 0}"><strong>{{family.name}}</strong></a>
    </li>
</ul>
<div class="search-box mt-4">
    <i class="fa fa-search"></i>
    <input type="text" (keyup)="applyFilters()" [pattern]="'^[a-zA-Z \-\']$'" [(ngModel)]="searchString"
        placeholder="Search Model">
</div>
<div *ngIf="this.filteredProducts.length < 1 && enableNoMatchFound" class="mt-3 no-match-min-h">
    <span class="sorry-no-matches">Sorry, no matches found for <b>{{searchString}}</b>. Please try again.</span>
</div>
<div class="tab-content vws-anchor" id="pills-tabContent">
    <div class="tab-pane fade show active" id="family" role="tabpanel" aria-labelledby="family">
        <div class="flex-container">
            <div class="card center-row" *ngFor="let card of filteredProducts" (click)="onCardClick(card)">
                <div>
                    <i class="fa fa-plus align-add-icon"></i>
                    <div class="hrLine"></div>
                    <img class="card-img-top" [src]="getImage(card.graphicUrl__c)" alt="">
                </div>
                <div class="card-body">
                    <h3 class="card-text">{{card.Name}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
