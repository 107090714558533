import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartService, CpqAccount, CpqObjects, JOB_STATUS } from '@cpq-app/services/cart.service';
import { SalesforceProxyService, SfdcLeadSource, VwsSfdcOpportunityStage } from '@cpq-app/services/salesforce.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { concatMap, delay, map, switchMap, take } from 'rxjs/operators';
import { CPQ_EXPORT_STATUS, CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { VwsCpqQuote, VwsJobRevision } from '../VWS.interfaces.service';
import { DatePipe, getCurrencySymbol } from '@angular/common';
import { CurrencyPipe, Location } from '@angular/common';
import { ProductService } from '@cpq-app/services/product.service';
import { endOfToday } from 'date-fns';
import { Router } from '@angular/router';
import { environment } from '@cpq-environments/environment';
const FPX_CREATION_DELAY = 2000;
const MAX_SFDC_NAME_LENGTH = 120;
const PREDICTED_DATE_MIN_VALUE = 1;

@Component({
  selector: 'app-submit-job',
  templateUrl: './submit-job.component.html',
  styleUrls: ['./submit-job.component.scss'],
  providers: [CurrencyPipe]
})
export class SubmitJobComponent implements OnInit, OnDestroy {
  submitJobForm: UntypedFormGroup;
  @Input() jobID: string;
  opportunityDetails;
  updateSubscription: Subscription;
  availableAccounts = [];
  needsSfdcOpp: boolean;
  selectedAccount: CpqAccount;
  submitted = false;
  revisionID: string;
  SUBMIT_SPINNER = 'submitSpinner';
  projectedShipDate: string;
  canSubmit: boolean;
  primaryRevision: VwsCpqQuote;
  salesForceOpportunityId;
  readonly DEFAULT_CURRENCY_CODE = 'USD'; // FIXME replace default currency code symbol with injected value
  currencySymbol = getCurrencySymbol(this.DEFAULT_CURRENCY_CODE, 'narrow');
  minPredictedDate: {
    year: number,
    month: number,
    day: number,
  };
  validationMessage: string;
  subscription$: Subscription[] = [];
  productType;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    private salesforce: SalesforceProxyService,
    private datePipe: DatePipe,
    private productService: ProductService,
    private location: Location,
    private router: Router,
  ) {
   }

  ngOnInit(): void {
    const minDate = new Date();
    minDate.setDate((minDate.getDate()) + PREDICTED_DATE_MIN_VALUE);
    this.minPredictedDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
    this.spinner.show();
    this.initialFormData();
    this.getAccountNames();
    this.updateFormData();
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.submitJobForm = this.formBuilder.group({
      accountSelection: [null, Validators.required],
      jobName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      revisionName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp)]],
      totalAmount: [null],
      poNumber: [null, Validators.maxLength(255)],
      revisionNote: [null, [Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(1000)]],
      acceptTermsToSubmitJob: [false, Validators.requiredTrue],
      ShippingDate: [null, Validators.required]
    });
    this.datePickerValidation();
  }

  warnIfPredictedDateTooSoon(shippingDate: string): void {
    let shipDate = new Date(shippingDate);

    if (shipDate.getTime() < endOfToday().getTime()) {
      this.toastr.warning('Expected Ship Date must be after today\'\s date', 'Warning');
    }
  }

  datePickerValidation() {
    this.submitJobForm.controls.ShippingDate.valueChanges.subscribe(date => {
      if (date.year && date.month && date.day) {
        const dateSelected = `${date.year}/${date.month}/${date.day}`;
        const selectedDate = new Date(dateSelected);
        const minDate = `${this.minPredictedDate.year}/${this.minPredictedDate.month}/${this.minPredictedDate.day}`;
        const minDateValidation = new Date(minDate);
        this.validationMessage = (date?.year !== undefined && (selectedDate.getTime() < minDateValidation.getTime())) ? `Expected Ship Date must be after today's date` : 'Enter a valid Date';
      }
    });
  }

  dateFormatter(date) {
    const dateSelected = `${date.year}/${date.month}/${date.day}`;
    const d = new Date(dateSelected);
    // removing the timezone offset.
    d.setHours(0, -d.getTimezoneOffset(), 0, 0);
    return d.toISOString();
  }
  /**
   * fetch opportunities detail to place order
   */
  updateFormData() {
    this.cartService.getCpqObjectByIdCDS<VwsJobRevision>(CpqObjects.Opportunities, this.jobID).pipe(
    map(
      (data: any) => {
        this.revisionID = data?.PrimaryQuote.Id;
        this.processFormData(data)
        this.getProposalWithData(false);
      }
    ))
      .subscribe({
        next: (data: any) => {
          this.productService.totalSellingPrice.subscribe(res => {
            if(res) {
              const totalSellingPrice = Math.round(res).toFixed(2);
              this.submitJobForm.controls.totalAmount.patchValue(totalSellingPrice);
            }
          })
          this.spinner.hide();
        },
        error: err => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching ', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      });
  }

  processFormData(data) {
    this.opportunityDetails = data;
    this.revisionID = data?.PrimaryQuote.Id;
    this.needsSfdcOpp = !data?.CrmId;
    this.projectedShipDate = data?.ShippingDate;
    this.salesForceOpportunityId = data.CrmId;
    this.productService.salesForceOpportunityId = data.CrmId;
    this.primaryRevision = data.Quotes?.find((quote: VwsCpqQuote) => quote.IsPrimary === 1 || quote.IsPrimary === true);
    const status = this.cartService.getVWSOpportunityStatus(data);
    if (status === JOB_STATUS.SUBMITTED || status === JOB_STATUS.INACTIVE || this.primaryRevision.Lines.length <= 0) {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
    try {
      this.submitJobForm.patchValue({
        accountSelection: data?.AccountId,
        jobName: data?.Name.substring(0, MAX_SFDC_NAME_LENGTH),
        revisionName: data?.PrimaryQuote?.Name,
        poNumber: this.primaryRevision?.PO_Number__c,
        revisionNote: this.primaryRevision?.Note || '',
        ShippingDate: this.updateDate(data?.ShippingDate)
      });
      this.warnIfPredictedDateTooSoon(data?.ShippingDate);
    } catch (error) {
      console.log(error);
    }
  }

  updateDate(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day.split(' ')[0].trim(), 10)
      };
      return obj;
    }
  }

  getAccountNames() {
    this.availableAccounts = [];
    // this.subscription$.push(this.cartService.getCpqObjectsCDS<CpqAccounts[]>(CpqObjects.Accounts)
    this.subscription$.push(this.cartService.getCpqObjectsCDS<CpqAccounts[]>(CpqObjects.Accounts)
      .subscribe(
        (results) => {
          this.availableAccounts = [...this.availableAccounts, ...results];
        },
        err => {
          this.toastr.error(
            'There is fatal error while fetching Account Names', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      ));
  }

  conditionallyCreateSfdcOpp(quoteResponse?) {
    // TODO: check product type for favorites
    const productType = this.productType;
    let accountId = this.submitJobForm.value.accountSelection ? this.submitJobForm.value.accountSelection : '';
    const account = this.availableAccounts.find(x => x.Id === accountId);
    const externalId = this.opportunityDetails.CrmId;
    const isDistributor = this.opportunityDetails?.Owner?.PartnerId ? true : false;
    let payload: any = {
      Name: this.opportunityDetails.Name,
      StageName: VwsSfdcOpportunityStage.CLOSED,
      CloseDate: new Date().toISOString(),
      LeadSource: SfdcLeadSource.CPQ,
      Projected_Ship_Date__c: this.dateFormatter(this.submitJobForm.value.ShippingDate),
      CPQ_Opportunity_Owner_Email__c: this.opportunityDetails.Owner?.Email,
      Outside__c: isDistributor,
      Product__c: productType,
      Amount: this.submitJobForm.value.totalAmount,
      // Account: accountId // 001i00000257o7VAAQ
    }
    if (this.needsSfdcOpp) {
      return this.salesforce.createOpportunity(payload).pipe(
        switchMap(result => {
          //Below logic is to Link the CPQ Opportunity with Salesforce Opportunity
          this.salesForceOpportunityId = result.id;
          this.productService.salesForceOpportunityId = result.id;
          return this.cartService.updateObjectById(CpqObjects.Opportunity, this.jobID, {
            CrmId: result.id,
            AccountId: this.submitJobForm.value.accountSelection,
            ShippingDate: this.dateFormatter(this.submitJobForm.value.ShippingDate)
          });
        }),
      );
    } else if (externalId && externalId !== null) {
      return this.salesforce.updateOpportunity(externalId, payload).pipe(
        switchMap(result => {
          this.salesForceOpportunityId = result.id;
          this.productService.salesForceOpportunityId = result.id;
          return this.cartService.updateObjectById(CpqObjects.Opportunity, this.jobID, {
            AccountId: this.submitJobForm.value.accountSelection,
            ShippingDate: this.dateFormatter(this.submitJobForm.value.ShippingDate),
          });
        }),
      );
    }
    return of(false);
  }

  onSubmitJob() {

    if (this.submitJobForm.valid) {
      this.spinner.show(this.SUBMIT_SPINNER);
      const exportTask = this.cartService.exportToCrm(this.jobID);
      this.submitted = true;
          this.cartService.updateObjectById(CpqObjects.Quote, this.revisionID, {
          Note: this.submitJobForm.value.revisionNote,
          PO_Number__c: this.submitJobForm.value.poNumber
          }).pipe(
            map(ele => {
              this.productType = ele.Product__c;
              this.getProposalWithData(true)
            }),
          ).pipe(
          switchMap(x => exportTask),
          concatMap(ele => {
            return this.conditionallyCreateSfdcOpp()
          })
          ).subscribe(() => {
            // If successful, we need the landing page to know so that it can reload the opp and quote
            this.close(true);
            this.spinner.hide(this.SUBMIT_SPINNER);
            // order placed
            this.toastr.success(
              'Job Submitted Successfully'
            );
            this.productService.isCadGenCompleted.next(false)
            this.cartService.orderPlaced$.next(true);
          }, err => {
            this.spinner.hide(this.SUBMIT_SPINNER);
            this.toastr.error(`We're Sorry; something went wrong while submitting your quote.`, `Error`, {
              disableTimeOut: true,
              closeButton: true
            });
            this.activeModal.close();
            // TODO: Add a call to external logging service
          });

    } else {
      this.spinner.hide(this.SUBMIT_SPINNER);
      this.toastr.warning('Please check what you entered and correct any mistakes');
    }
  }

  getProposalWithData(isAttach): Observable<any> {
    this.productService.quoteProposalForEmail(this.revisionID, this.jobID, isAttach);
    return of(false);
  }


  close(success = false) {
    this.spinner.hide(this.SUBMIT_SPINNER);
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
    this.subscription$.forEach(sub => sub.unsubscribe());
  }

}
