import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Grouping } from "@cpq-app/services/product.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { ActiveNode, Products } from "../VWS.interfaces.service";
import { CartService, CpqObjects } from "@cpq-app/services/cart.service";

const FIRST_PANEL_PARTS_CONFIG = "ngb-panel-0";

enum SelectionTypes {
  NumericInput = "NumericInput",
  StringInput = "StringInput",
  Radio = "Radio",
  CheckBox = "CheckBox",
  Quantity = "Quantity",
}
@Component({
  selector: "app-tunnel-product-details",
  templateUrl: "./tunnel-product-details.component.html",
  styleUrls: ["./tunnel-product-details.component.scss"],
})
export class TunnelProductDetailsComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() selectedOptionData: Products;
  @Input() currentCategoryTabIndex: number;
  @Input() quoteLineId: string;
  @Input() quotePrice: number;
  @Input() configurationChange: boolean;
  @Input() configuratorData: {
    selectedProductData: Products;
    configurators: any;
  };
  @Input() description: string;
  currentSelectedOptionData: Products;
  subscription$: Subscription[] = [];
  jobID: string;
  revisionID: string;
  configID: string;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private cartService: CartService
  ) {
    this.route.params.subscribe((params) => {
      if (params?.jobID) {
        this.jobID = params.jobID;
      }
      if (params?.revisionID) {
        this.revisionID = params.revisionID;
      }
      if (params?.configID) {
        this.configID = params.configID;
      }
    });
  }

  @Output() goBackToTunnelConfig = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    this.currentSelectedOptionData = this.selectedOptionData;
    if (
      changes.currentCategoryTabIndex !== undefined &&
      changes.currentCategoryTabIndex.previousValue !== undefined
    ) {
      if (
        changes.currentCategoryTabIndex.currentValue !==
        changes.currentCategoryTabIndex.previousValue
      ) {
        this.goBack();
      }
    }
  }

  ngOnInit(): void { }

  paramForQuoteLineEntries() {   
    let Entries = {};
    //filter the getValue by looping this.configuratorData.configurators[].manager.getValue().aComponent.id === this.configuratorData.selectedProductData.AttributeMap.aComponent
    let config = this.configuratorData.configurators.filter(x=>  x.manager.getValue()?.aComponent[0]?.id === this.configuratorData.selectedProductData.AttributeMap.aComponent && x.quoteLineId === this.quoteLineId)[0];
    const selectedOptions = config.manager.getValue();
    for (let item in selectedOptions) {
      Entries[item] = this.getValueAttributes(config.attributes[item])
    }
    return Entries;
  }

  getValueAttributes(attribute) {
    if (typeof attribute !== 'undefined' && attribute !== null) {
      const value = attribute.getValue();
      if (Array.isArray(value) && value.length > 0) {
        return value[0].id;
      } else {
        return typeof(value) === 'string' ? decodeURIComponent(value): value;
      }
    }
  }

  removeDiscountIfZero(jsonObject: any) {
    if (jsonObject.hasOwnProperty('aDiscountAmount') && jsonObject.aDiscountAmount === 0) {
      delete jsonObject.aDiscountAmount;
      delete jsonObject?.aDiscountEntry;
      delete jsonObject?.aDiscountName;
    }
    if (jsonObject.hasOwnProperty('aDiscountPercentage') && jsonObject.aDiscountPercentage === 0) {
      delete jsonObject.aDiscountPercentage;
      delete jsonObject?.aDiscountEntry;
      delete jsonObject?.aDiscountName;
    }
    return jsonObject
  }

  /**
   * update quote-line on click of save configuration
   */
  updateQuoteLine() {
    let entries = this.paramForQuoteLineEntries();
    let productEntries = this.removeDiscountIfZero(entries)
    let data: any = {
      Entries: productEntries,
    };
    this.spinner.show();
    this.subscription$.push(this.cartService
      .updateObjectById(CpqObjects.QuoteLine, this.quoteLineId, data)
      .subscribe({
        next: () => {
          this.goBackToTunnelConfig.emit();
        },
        complete: () => {
          // this.spinner.hide();
        },
        error: (err) => {
          this.goBackToTunnelConfig.emit();
          this.spinner.hide();
        },
      }));
  }

  goBack() {
    this.updateQuoteLine();
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
