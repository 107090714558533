<div>
    <div class="modal-header">
        <h1 class="modal-title">FAQs</h1>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2>Who do I contact for help with a VWS quote inside of CPQ?</h2>
        <p>Contact your local Business Development Manager (BDM) for assistance.</p>
        <h2>What if the product or option I am looking for is not in CPQ?</h2>
        <p>If the product or option that you are looking for is not in CPQ, please click here to contact the sales team
            about a custom quote.</p>
        <h2>How can add my own own products listing to the CPQ tool?</h2>
        <p>Click on the following video to learn how to add non-VWS products to customize your CPQ experience.</p>
        <h2>How do I report a system problem?</h2>
        <p>Click on the “Contact Sales” button, report the issue and submit or you can contact your local BDM.</p>
        <h2>If I submit a question or report a problem, how soon will I be contacted?</h2>
        <p>Within 1 business day for most requests.</p>
        <h2>How long are my previous quotes visible?</h2>
        <p>For at least 3 months for an existing customer.</p>
        <h2>What types of equipment are available in CPQ?</h2>
        <p>OPW-VWS products that can be configured, priced and quoted are:</p>
        <ul>
            <li>Belanger Classic Tunnels</li>
            <li>Belanger SpinLite Tunnels</li>
            <li>Belanger SpinRite Tunnel</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>