import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { root } from 'rxjs/internal/util/root';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  private backendUrl = environment.B2CConfigs.BackendURL;

  getTypes() {
    return this.http.get(`${this.backendUrl}/organizations/types`);
  }

  getOrganizationByType(typeId) {
    return this.http.get(`${this.backendUrl}/organizations/type/${typeId}`);
  }

  getData = () => {
    return this.http.get(`${this.backendUrl}/organizations/all`);
  };

  getAllActiveOrganizations = () => {
    return this.http.get(`${this.backendUrl}/organizations/active`);
  };

  createOrganizationData = (formdata) => {
    return this.http.post(
      `${this.backendUrl}/organizations/createOrganization`,
      formdata
    );
  };

  getOrganizationById = (id: any) => {
    return this.http.get(
      `${this.backendUrl}/organizations/getOrganizationById/${id}`
    );
  };

  deleteOrganization = (id: any) => {
    return this.http.delete(
      `${this.backendUrl}/organizations/deleteOrganizationById/${id}`
    );
  };

  updateOrganization = (orgId, formdata) => {
    return this.http.put(
      `${this.backendUrl}/organizations/updateOrganization/${orgId}`,
      formdata
    );
  };

  getLocationDropdownData() {
    return this.http.get(`${this.backendUrl}/organizations/locationfilter`);
  }

  getFilteredOrganizations(filterBody) {
    return this.http.post(
      `${this.backendUrl}/organizations/filter`,
      filterBody
    );
  }

  getAssociatedParents(orgTypeId) {
    return this.http.get(
      `${this.backendUrl}/organizations/getAssociatedParents/${orgTypeId}`
    );
  }

  getAssociatedParentsInEditMode(orgId, orgTypeId) {
    return this.http.get(
      `${this.backendUrl}/organizations/getAssociatedParentsInEditMode/${orgId}/${orgTypeId}`
    );
  }
}
